import { useMemo } from 'react';
import { matchBarcode } from 'common/functions/barcodes/matchBarcode.util';
import { getItemsFromLocation } from 'common/functions/items/itemsFunctions';
import { useLocationData } from './useLocationData';
import { ItemData } from '../../../models/ItemView.model';

export const useItemData = (systemId: string) => {
  const { locationData, isLoadingLocations } = useLocationData(systemId);

  const itemData = useMemo(() => {
    let items: {
      [item: string]: ItemData;
    } = {};

    locationData.forEach((l) => {
      items = { ...items, ...getItemsFromLocation(l) };
    });

    Object.keys(items).forEach((key) => {
      const item = items[key];

      item.barcodeStatus = matchBarcode(
        item.expected_at ? item.barcode : '',
        item.found_at ? item.barcode : '',
      );
    });

    return items;
  }, [locationData]);

  return useMemo(
    () => ({ itemData, isLoading: isLoadingLocations }),
    [itemData, isLoadingLocations],
  );
};
