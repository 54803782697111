import { useState, useEffect, useCallback } from 'react';

type InfoBannersType = {
  countingIntegration: {
    warehouseStatusLegacySeen?: boolean;
    warehouseStatusSeen?: boolean;
    analyticsSeen?: boolean;
  };
};

export const useInfoBannersStorage = () => {
  const key = 'infoBanners';

  const [infoBanners, setInfoBannersState] = useState<InfoBannersType | null>(() => {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  });

  const clearInfoBanners = useCallback(() => {
    sessionStorage.removeItem(key);
    setInfoBannersState(null);
  }, [key]);

  const updateCountingInfoBannerStatus = useCallback(
    (field: keyof InfoBannersType['countingIntegration'], value: boolean) => {
      const updatedInfoBanners = {
        ...infoBanners,
        countingIntegration: {
          ...infoBanners?.countingIntegration,
          [field]: value,
        },
      };
      sessionStorage.setItem(key, JSON.stringify(updatedInfoBanners));
      setInfoBannersState(updatedInfoBanners);
    },
    [infoBanners, key],
  );

  useEffect(() => {
    const handleStorageChange = () => {
      const item = sessionStorage.getItem(key);
      setInfoBannersState(item ? JSON.parse(item) : null);
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [key]);

  return {
    infoBanners,
    clearInfoBanners,
    updateCountingInfoBannerStatus,
  };
};
