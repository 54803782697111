export const isGridStateSavedInLocalStorage = ({
  gridName,
  activeTab,
}: {
  gridName: 'report' | 'warehouse-status';
  activeTab: number;
}) => {
  const savedStateJson = localStorage.getItem(`savedGridState-${gridName}`);
  if (!savedStateJson) {
    return false;
  }
  const savedState = JSON.parse(savedStateJson);

  return Object.keys(savedState).includes(activeTab.toString());
};
