import Timeline from '@mui/lab/Timeline';
import { Button } from '@mui/material';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { Box } from 'components/common/Box';
import { CustomTimeLineItem } from './CustomTimeLineItem';
import { useLocationHistoryStyles } from './LocationHistory.style';
import { useLocationHistory } from './hooks/useLocationHistory';

export const LocationHistory = ({
  systemId,
  slotLabel,
  timePoint,
}: {
  slotLabel: string;
  systemId: string;
  timePoint?: string;
}) => {
  const { classes } = useLocationHistoryStyles();

  const { isLoading, historyData, handleClick } = useLocationHistory(systemId, slotLabel);

  return (
    <BaseCard
      cardFor="location_history"
      showHeader={true}
      showContent={true}
      showActionButtons={false}
      isLoading={isLoading}
      title="History"
      subtitle="The timeline of the location is based on WMS updates and drone reports."
      // counterbalance BaseCard bottomMargin
      // sfarkas - 2025.01.15
      sx={{ marginBottom: '0 !important' }}
      scrollable
    >
      <Box className={classes.timelineDiv}>
        <Timeline className={classes.timeline}>
          {historyData.items
            .filter((item) => (timePoint ? item.timestamp <= timePoint : true))
            .map((item, i) => (
              <CustomTimeLineItem
                key={slotLabel + item.timestamp}
                item={item}
                systemId={systemId}
                slotLabel={slotLabel}
                lastItem={i === historyData.items.length - 1 && historyData.next_timepoint === null}
              />
            ))}
        </Timeline>
        {historyData.next_timepoint && (
          <div className={classes.loadMoreDiv}>
            <Button color="primary" variant="outlined" size="small" onClick={handleClick}>
              Load More
            </Button>
          </div>
        )}
      </Box>
    </BaseCard>
  );
};
