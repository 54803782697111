import { Button } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useCallback } from 'react';
import { isEmpty } from 'lodash';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { downloadBase64FromJSON } from 'common/functions/fileFunctions';
import { useSettingsStore } from 'store/Settings/settingsStore';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { DownloadButton } from 'udb/inventory/features/download-button/DownloadButton';
import { useStyles } from './settingsPageHeader.styles';

export const SettingsPageHeader = ({
  systemId,
  isFacilitySettingsLoaded,
  onSaveButtonClick,
}: {
  systemId: string;
  isFacilitySettingsLoaded: boolean;
  onSaveButtonClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
  const { cx, classes } = useStyles();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { FACILITY_SETTINGS_BASE64_FILE_EXTENSION } = useSettingsStore();

  const downloadSettingsFile = useCallback(() => {
    const fileName = !isEmpty(stateFacilityLevel.facilityData.name)
      ? stateFacilityLevel.facilityData.name
      : stateFacilityLevel.facilityData.store_id;
    const facilitySettingsTemp = stateFacilityLevel.facilitySettings;
    downloadBase64FromJSON(facilitySettingsTemp, fileName, FACILITY_SETTINGS_BASE64_FILE_EXTENSION);
  }, [
    FACILITY_SETTINGS_BASE64_FILE_EXTENSION,
    stateFacilityLevel.facilityData.name,
    stateFacilityLevel.facilityData.store_id,
    stateFacilityLevel.facilitySettings,
  ]);

  return (
    <PageHeader
      systemId={systemId}
      title="Administration"
      actionArea={
        isFacilitySettingsLoaded ? (
          <div className={cx(classes.buttonWrapper)}>
            <Button
              data-testid="c-save-settings-changes-btn"
              endIcon={<SaveIcon />}
              variant="contained"
              color="primary"
              onClick={onSaveButtonClick}
            >
              Save changes
            </Button>

            <DownloadButton
              onClick={downloadSettingsFile}
              aria-label="Download Facility settings .vrt file"
              isBusy={false}
            />
          </div>
        ) : null
      }
    />
  );
};
