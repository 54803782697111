import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { Box, Typography } from '@mui/material';
import { IVeritySlotStatusStateST, IWMSSlotStatusSTStateEnum } from 'codegen/location_information';
import { TooltipedIcon } from 'components/common/TooltipedIcon';

export const BarcodesCount = ({
  wmsState,
  verityState,
  barcodes,
  colors,
}: {
  wmsState?: IWMSSlotStatusSTStateEnum;
  verityState?: IVeritySlotStatusStateST;
  barcodes: { missing: number; unexpected: number; match: number };
  colors: { missing: string; unexpected: string; match: string };
}) => (
  <Box component="div" display="flex" flexDirection="row" gap={4} alignItems="center">
    {wmsState === 'BARCODE' && (
      <TooltipedIcon
        noMargin
        tooltip="Barcodes expected according to WMS information but not found by Verity"
        icon={
          <Box component="div" display="flex" flexDirection="row" alignItems="center" gap={1}>
            <FiberManualRecordIcon sx={{ color: colors.missing }} />
            <Typography variant="body2" data-testid="c-loc-mod-missing-label">
              Missing:
            </Typography>
            <Typography
              variant="body2"
              data-testid="c-loc-mod-missing-count"
              style={{ fontWeight: 700 }}
            >
              {barcodes.missing}
            </Typography>
          </Box>
        }
      />
    )}

    {verityState === 'BARCODE' && (
      <TooltipedIcon
        noMargin
        tooltip="Barcodes found by Verity but not expected according to WMS information"
        icon={
          <Box component="div" display="flex" flexDirection="row" alignItems="center" gap={1}>
            <FiberManualRecordIcon sx={{ color: colors.unexpected }} />
            <Typography variant="body2" data-testid="c-loc-mod-unexpected-label">
              Unexpected:
            </Typography>
            <Typography
              variant="body2"
              data-testid="c-loc-mod-unexpected-count"
              style={{ fontWeight: 700 }}
            >
              {barcodes.unexpected}
            </Typography>
          </Box>
        }
      />
    )}
    {verityState === 'BARCODE' && wmsState === 'BARCODE' && (
      <TooltipedIcon
        noMargin
        tooltip="Barcodes which were expected and have been found"
        icon={
          <Box component="div" display="flex" flexDirection="row" alignItems="center" gap={1}>
            <FiberManualRecordIcon sx={{ color: colors.match }} />
            <Typography variant="body2" data-testid="c-loc-mod-match-label">
              Match:
            </Typography>
            <Typography
              variant="body2"
              data-testid="c-loc-mod-match-count"
              style={{ fontWeight: 700 }}
            >
              {barcodes.match}
            </Typography>
          </Box>
        }
      />
    )}
  </Box>
);
