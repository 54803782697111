import { memo } from 'react';
import { makeStyles } from 'tss-react/mui';
import {
  GridToolbarColumnsButton,
  GridToolbarExport,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import AddAlertIcon from '@mui/icons-material/AddAlert';
import { Theme } from '@mui/material';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';

const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    backgroundColor: '#F9F9F9',
    filter: 'grayscale(1)',
  },
  primaryActionsWrapper: {
    gap: theme.spacing(1),
    flexWrap: 'wrap',
    flex: '1 1 auto',
    display: 'flex',
    alignItems: 'center',
  },
  secondaryActionsWrapper: {
    flex: '0 0 auto',
  },
}));

export const WarehouseStatusToolbar = memo(
  ({
    allowPrint = false,
    checkboxSelection,
    onSnooze,
    onUnSnooze,
    onSelectChange,
  }: {
    allowPrint?: boolean;
    checkboxSelection: boolean;
    onSnooze: () => void;
    onUnSnooze: () => void;
    onSelectChange: (value: boolean) => void;
  }) => {
    const { classes } = useStyles();

    return (
      <div className={classes.wrapper}>
        <div className={classes.primaryActionsWrapper}>
          <GridToolbarColumnsButton />

          <GridToolbarFilterButton />

          <GridPremiumToolbarButton
            name="SELECT"
            tooltip="Select Rows"
            onClick={() => onSelectChange(!checkboxSelection)}
            icon={<SelectAllIcon />}
          />

          {checkboxSelection && (
            <GridPremiumToolbarButton
              name="SNOOZE SELECTED"
              tooltip="Snooze select Rows"
              onClick={onSnooze}
              icon={<NotificationsPausedIcon />}
            />
          )}

          {checkboxSelection && (
            <GridPremiumToolbarButton
              name="UNSNOOZE SELECTED"
              tooltip="Unsnooze select Rows"
              onClick={onUnSnooze}
              icon={<AddAlertIcon />}
            />
          )}
        </div>

        <div className={classes.secondaryActionsWrapper}>
          <GridToolbarExport
            slotProps={{
              tooltip: {
                title:
                  'The export will contain only the visible fields including filters, sorting, etc. If you want to export the full content of the warehouse, use the download button on top of the page.',
                placement: 'top',
              },
            }}
            printOptions={{ disableToolbarButton: !allowPrint }}
          />
        </div>
      </div>
    );
  },
);
