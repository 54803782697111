import MuiSkeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useClientLevelStore } from '.../../store/ClientLevelStore/clientLevelStore';
import { PageHeaderSectionTitle } from '../../Page/section-title/PageHeaderSectionTitle';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { PageHeaderBreadcrumbs } from '../../Page/PageHeaderBreadcrumbs';
import { PageHeaderProps } from './pageHeader.model';
import { useStyles } from './pageHeader.styles';

export const PageHeader = ({ systemId, title, subtitle, actionArea, isBusy }: PageHeaderProps) => {
  const { classes } = useStyles();

  const { stateClientLevel } = useClientLevelStore();
  const { stateFacilityLevel } = useFacilityLevelStore();

  const currentFacility = stateClientLevel.facilityList.find(
    (facility) => facility.id === systemId,
  );

  const currentFacilityName = currentFacility?.name ?? '';
  const hasMultipleFlightDomains = stateFacilityLevel.flightDomains.length > 1;
  const hasMultipleFacilities = stateClientLevel.isMultiFacility ?? false;

  return (
    <header aria-busy={isBusy} className={classes.header}>
      {isBusy ? (
        <div className={classes.details}>
          <MuiSkeleton variant="rounded" width="15ch" height="1rem" />

          <div className={classes.detailsSection}>
            <MuiSkeleton variant="rounded" width="42ch" height="2.125rem" />

            <MuiSkeleton variant="rounded" width="60ch" height="1.25rem" />
          </div>
        </div>
      ) : (
        <>
          <div className={classes.details}>
            <PageHeaderBreadcrumbs
              title={title}
              facilityName={currentFacilityName}
              systemId={systemId ?? ''}
              multipleFacilitiesAccess={hasMultipleFacilities}
              multipleFlightDomains={hasMultipleFlightDomains}
            />

            <div className={classes.detailsSection}>
              <PageHeaderSectionTitle
                title={title}
                currentFacilityName={currentFacilityName}
                isMultiFacility={hasMultipleFacilities}
              />

              {subtitle && (
                <Typography
                  data-testid="c-overview-subtitle"
                  className={classes.subtitle}
                  variant="h2"
                >
                  {subtitle}
                </Typography>
              )}
            </div>
          </div>

          <div className={classes.actionArea}>{actionArea}</div>
        </>
      )}
    </header>
  );
};
