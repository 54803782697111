import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { EnhancedTable } from 'components/EnhancedTable';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { LocalStore } from 'common/functions/storageFunctions';
import { useRequestController } from '../../../hooks';
import { systemDetailsColumnsDefaults } from './defaults/systemDetails.default';
import { ServiceVersionItem } from './model/serviceVersionItem.model';
import { getLatestBeVersion } from './api/getLatestBeVersion.api';

const logPrefix = getLogPrefixForType('COMPONENT', 'SystemDetails', 'Home');

export const SystemDetailsPage = () => {
  const { systemId = '' } = useParams();

  const [isLoading, setIsLoading] = useState(false);
  const [serviceVersion, setServiceVersion] = useState<ServiceVersionItem[]>([]);

  const { requestController } = useRequestController('System Details');

  const timezone = LocalStore.getTimezone();

  useEffect(() => {
    console.debug(logPrefix, 'fetching services version data');
    getLatestBeVersion(
      requestController,
      systemId as string,
      setServiceVersion,
      setIsLoading,
      timezone,
    );
  }, [requestController, systemId, timezone]);

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageHeader systemId={systemId} title="Administration" />
      </PageLayout.Header>

      <PageLayout.Section>
        <EnhancedTable
          tableTitle="Services Version"
          tableSubtitle="Services Version Overview"
          tableFor="Services Version"
          headCells={systemDetailsColumnsDefaults}
          rows={serviceVersion}
          showHeader={true}
          currentActiveSpinner={{
            'Loading Version': false,
          }}
          isLoading={isLoading}
        />
      </PageLayout.Section>
    </PageLayout>
  );
};
