import { makeStyles } from 'tss-react/mui';
import { timelineItemClasses } from '@mui/lab/TimelineItem';

export const useLocationHistoryStyles = makeStyles()(() => ({
  timelineDiv: {
    height: '424px',
    overflowY: 'auto',
    padding: 0,
    paddingTop: 10,
    '&::-webkit-scrollbar': {
      height: 9,
      width: 5,
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#BDBDBD',
      borderRadius: 200,
    },
  },

  loadMoreDiv: {
    paddingLeft: '70px',
    paddingTop: '5px',
  },

  timeline: {
    [`& .${timelineItemClasses.root}:before`]: {
      flex: '0 1 auto',
      padding: 0,
    },
  },
}));
