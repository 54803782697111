// libraries
import React, { useState, useEffect, useCallback } from 'react';
import moment from 'moment-timezone';

// material-ui core
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// store

// functions
import { singleRequestHandler } from 'common/requestHelpers';
import { RELATIVE_DATETIME_FORMAT } from 'common/datetimeFormats';
import {
  convertRruleToUIComponents,
  generateRruleFromUIComponents,
  IGenerateRruleFromUIComponentsParam,
} from 'common/functionsReportSpec';
import { Spinner } from 'components/common/Spinner';
import { Box } from 'components/common/Box';

// components
import { IInventoryRequestST } from 'codegen/inventory_request';
import { ModalBase } from './ModalBase';
import { requestStore } from '../../store/RequestStore/RequestStore';
import { FacilityModalsActionTypes } from '../../store/Modals/types';
import { useRequestController } from '../../hooks';
import { useFacilityModalsStore } from '../../store/Modals';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';

interface IDeleteModalProps {
  opened: boolean;
  /**
   * Inventory request to be deleted
   */
  requestItem: IInventoryRequestST;
}

export const DeleteInventoryRequestModal = (props: IDeleteModalProps) => {
  // props
  const { opened, requestItem } = props;
  const [loading, setLoading] = useState(false);

  const { facilityModalsState, dispatchFacilityModals } = useFacilityModalsStore();
  const { requestController } = useRequestController('DeleteInventoryRequestModal');

  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  // Get inventory request item
  const asyncGetRequest = (requestId: string) =>
    requestController.doRequest({
      request: requestStore.getRequest,
      requestParams: [systemId, requestId],
      messageErrorFallback: 'Failed to fetch the request',
    });

  // Get the next occurring date of the inventory request
  const asyncGetNextOccurrence = (rrule: string, next_scheduling_time_utc: string) =>
    requestController.doRequest({
      request: requestStore.getNextOccurrence,
      requestParams: [systemId, rrule, next_scheduling_time_utc, false],
      messageErrorFallback: 'Failed to fetch the next occurrence',
    });

  // Delete inventory request occurrences and series
  const handleDeleteClick = async (requestId: string, action: string) => {
    setLoading(true);
    switch (action) {
      case 'delete-instance': {
        const {
          data: { item },
        } = await asyncGetRequest(requestId);

        // Convert rrule string to obj
        const ruleData = convertRruleToUIComponents(item.rrule);

        // Get next occurrence
        const {
          data: { next_occurrence },
        } = await asyncGetNextOccurrence(item.rrule, item.next_scheduling_time_utc);

        // Generate new rrule string based on new occurrence date
        const { rString } = generateRruleFromUIComponents({
          dateFrom: moment(next_occurrence), // next occurring date
          dateUntil: moment(ruleData.until),
          isRecurring: ruleData.isRecurring,
          timezone: ruleData.tzid,
          interval: ruleData.freq as IGenerateRruleFromUIComponentsParam['interval'],
          occurrenceInterval: ruleData.interval,
          days: ruleData.days,
          setDays: 123 as unknown as string,
          reportSpecTriggering: undefined,
        });

        // Payload item
        const updatedItem = {
          report_spec_id: item.report_spec.id,
          report_spec_version: item.report_spec.version,
          report_name: item.report_name,
          params: item.params,
          rrule: rString,
        };

        // Update request
        requestController.doRequest({
          request: requestStore.updateRequest,
          requestParams: [systemId, item.request_id, updatedItem],
          callbackSuccess: () => {
            dispatchFacilityModals({ type: FacilityModalsActionTypes.DELETE_REQUEST, payload: {} });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          },
          messageSuccess: 'Report instance successfully deleted.',
          messageErrorFallback: 'Failed to delete the Report instance.',
          callbackFinally: () => setLoading(false),
        });

        break;
      }
      case 'delete-full':
        requestController.doRequest({
          request: requestStore.deleteRequest,
          requestParams: [systemId, requestId],
          callbackSuccess: () => {
            dispatchFacilityModals({ type: FacilityModalsActionTypes.DELETE_REQUEST, payload: {} });
            Object.keys(facilityModalsState.refreshData).forEach((func) =>
              facilityModalsState.refreshData[func](),
            );
          },
          messageSuccess: 'Successfully deleted!',
          messageErrorFallback: 'Failed to delete the Request.',
          callbackFinally: () => setLoading(false),
        });
        break;
      default:
        break;
    }
  };

  const closeModal = () =>
    dispatchFacilityModals({ type: FacilityModalsActionTypes.DELETE_REQUEST, payload: {} });

  const [nextRecurrence, setNextRecurrence] = useState('');

  const {
    report_name: reportName,
    requesting_user_email: requestingUserEmail,
    request_id: requestId,
    next_scheduling_time_utc,
    rrule,
  } = requestItem;

  const { isRecurring } = convertRruleToUIComponents(rrule);

  const getNextOccurrence = useCallback(() => {
    singleRequestHandler({
      request: requestStore.getNextOccurrence,
      requestParams: [systemId, rrule, next_scheduling_time_utc, false],
      callbackSuccess: (r) => {
        if (r.data.next_occurrence) {
          setNextRecurrence(r.data.next_occurrence);
        }
      },
    });
  }, [next_scheduling_time_utc, rrule, systemId]);

  useEffect(() => {
    getNextOccurrence();
  }, [getNextOccurrence]);

  return (
    <ModalBase
      testId="c-delete-modal"
      opened={opened}
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography style={{ fontWeight: 'bold' }} color="secondary" variant="h5">
              Delete inventory request?
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              {reportName} by {requestingUserEmail}
            </Typography>
          </Box>
        </Box>
      }
      handleClose={closeModal}
      actionButtons={
        <>
          <Button onClick={closeModal} variant="outlined" fullWidth color="primary">
            Cancel
          </Button>
          {isRecurring && (
            <Button
              onClick={() => handleDeleteClick(requestId, 'delete-instance')}
              variant="contained"
              fullWidth
              color="primary"
              disabled={!nextRecurrence}
            >
              Delete occurrence
            </Button>
          )}
          <Button
            onClick={() => handleDeleteClick(requestId, 'delete-full')}
            variant="contained"
            fullWidth
            color="primary"
          >
            {isRecurring ? 'Delete series' : 'Delete'}
          </Button>
        </>
      }
    >
      {nextRecurrence && isRecurring ? (
        <Box mt={2}>
          <Typography align="left">
            You are about to delete a recurring inventory request. Do you want to delete the next
            occurrence or the entire series?
          </Typography>
          {nextRecurrence && (
            <Typography mt={2} align="left">
              Next occurrence: {moment(nextRecurrence).calendar(null, RELATIVE_DATETIME_FORMAT)}
            </Typography>
          )}
        </Box>
      ) : (
        <Typography align="left">
          You are about to delete an inventory request. However, this inventory request only has one
          more occurrence, so if you want to proceed please delete the entire recurrence series.
        </Typography>
      )}
      {loading && <Spinner />}
    </ModalBase>
  );
};
