import { HEADER_HEIGHT } from 'components/Header/defaults/Header.defaults';
import { makeStyles } from 'tss-react/mui';

const TABLE_UI_OFFSET = '80px';

export const useWarehouseStatusStyles = makeStyles()(() => ({
  cardBody: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${TABLE_UI_OFFSET})`,
    minHeight: '500px',
  },
}));
