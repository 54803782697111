import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  details: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: '6px',
    lineHeight: 1,
    wordBreak: 'break-word',
    flex: '1 1 clamp(500px, 60%, 100%)',
  },
  detailsSection: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(1),
  },
  subtitle: {
    fontWeight: 400,
    fontSize: '1.25rem',
    lineHeight: 'inherit',
    color: theme.palette.text.secondary,
  },
  actionArea: {
    flex: '0 0 auto',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
}));
