import { useMutation } from '@tanstack/react-query';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { ModalConfirm } from 'components/ModalsAndPopups/ModalConfirm';
import { PERMISSION } from 'features/permissions/permissions.model';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUserLevelStore } from 'store/UserLevelStore/userLevelStore';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { AlertComponent } from 'components/common/Alert';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { useInfoBannersStorage } from 'hooks/useInfoBannersStorage';
import { MutationNames } from 'ts-types/MutationNames';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { WarehouseStatusOverview } from './features/warehouse-status-overview/WarehouseStatusOverview';
import { useStyles } from './styles/WarehouseStatus.styles';
import { sendWarehouseExportEmail as sendWarehouseExportEmailAPI } from './api/sendWarehouseExportEmail.api';
import { WarehouseStatusTable } from './features/table/WarehouseStatusTable';
import { DownloadButton } from '../download-button/DownloadButton';

export const WarehouseStatus = () => {
  const { cx, classes } = useStyles();

  const { systemId = '' } = useParams();

  const [gridState, setGridState] = useState<GridInitialStatePremium | undefined>(undefined);

  const [isDownloadModalOpen, setIsDownloadModalOpen] = useState(false);

  const isDownloadVisible = userHasPermission(PERMISSION.EXPORT_WAREHOUSE_STATUS_VIA_EMAIL);

  const { stateUserLevel } = useUserLevelStore();

  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const { mutate: sendWarehouseExportEmail, isPending: isDownloading } = useMutation({
    mutationFn: () => sendWarehouseExportEmailAPI(systemId),
    mutationKey: [MutationNames.SEND_WAREHOUSE_EXPORT_MAIL],
    onSuccess: () => {
      toast('The warehouse status export shall be in your inbox in a few minutes.', {
        type: 'success',
      });
      setIsDownloadModalOpen(false);
    },
    onError: () => {
      toast('The Warehouse Status could not be sent by email.', {
        type: 'error',
      });
    },
  });

  const { infoBanners, updateCountingInfoBannerStatus } = useInfoBannersStorage();

  return (
    <>
      <PageLayout>
        <PageLayout.Header>
          <PageHeader
            systemId={systemId}
            title="Warehouse status"
            actionArea={
              isDownloadVisible && (
                <DownloadButton
                  aria-label="Download warehouse status report"
                  isBusy={isDownloading}
                  onClick={() => setIsDownloadModalOpen(true)}
                />
              )
            }
          />
        </PageLayout.Header>

        <PageLayout.Section>
          <div className={cx(classes.bannerWrapper)}>
            <AlertComponent
              isActive={
                !infoBanners?.countingIntegration.warehouseStatusSeen &&
                !!facilitySettings.enable_counting
              }
              iconMapping={{
                info: <InfoOutlinedIcon fontSize="inherit" />,
              }}
              status="info"
              message={
                <>
                  Please note that <strong>counting</strong> report data is not available in the{' '}
                  <strong>warehouse status</strong> view.
                </>
              }
              preventClose={false}
              handleClose={() => updateCountingInfoBannerStatus('warehouseStatusSeen', true)}
            />

            <div className={cx(classes.sectionColumns)}>
              <WarehouseStatusOverview systemId={systemId} onGridStateSelect={setGridState} />
            </div>
          </div>
        </PageLayout.Section>

        <PageLayout.Section fullWidth>
          <WarehouseStatusTable gridState={gridState} />
        </PageLayout.Section>
      </PageLayout>

      {isDownloadModalOpen && (
        <ModalConfirm
          handleClose={() => setIsDownloadModalOpen(false)}
          opened={isDownloadModalOpen}
          title="Send warehouse status export via e-mail?"
          message={`The warehouse status export will be sent to ${stateUserLevel.username} via email. The process may take a few minutes.`}
          onConfirm={() => {
            setIsDownloadModalOpen(false);
            sendWarehouseExportEmail();
          }}
        />
      )}
    </>
  );
};
