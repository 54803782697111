import { useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import { useNavigate, useParams } from 'react-router-dom';
import { DownloadLink } from 'pages/Admin/uploads/features/download-link/DownloadLink';
import { LocalStore } from 'common/functions/storageFunctions';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { asyncGetUploadedFiles } from 'common/functions/otherFunctions';
import * as tables from 'common/Tables';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { uploadStore } from '../../../store/UploadStore';
import { useAlert, useRequestController } from '../../../hooks';
import { Uploads } from './Uploads';
import '../admin.css';

const { wmsSnapshotFilesTab, stockCheckFilesTab, movedLocationsFilesTab } =
  tables.warehouseUploadsMappings;

const logPrefix = getLogPrefixForType('PAGE', 'Admin');

export const UploadsPage = () => {
  const { dispatchFacilityLevel } = useFacilityLevelStore();

  const { systemId } = useParams();

  const [progressWms, setProgressWms] = useState(0);
  const [progressStockCheck, setProgressStockCheck] = useState(0);
  const [progressMovedLocations, setProgressMovedLocations] = useState(0);
  const { alertState, showAlert } = useAlert();
  const [loader, setLoader] = useState({
    WMSSnapshot: false,
    StockCheck: false,
    MovedLocations: false,
  });
  const timezone = LocalStore.getTimezone();

  const navigate = useNavigate();
  const { requestController } = useRequestController(logPrefix);

  const [fileDetails, setFileDetails] = useState({});

  const [latestWMSSnapshotFile, setLatestWMSSnapshotFile] = useState({});
  const [latestStockCheckFile, setLatestStockCheckFile] = useState({});
  const [latestMovedLocationsFile, setLatestMovedLocationsFile] = useState({});

  const getFiles = useCallback(
    async (fileType, setFiles) => {
      const lp = getLogPrefixForType('FUNCTION', 'getFiles', logPrefix);
      console.debug(lp, 'function invoked', fileType, setFiles);
      console.debug(lp, 'get last processed file');
      const filesRequestReservation = requestController.reserveSlotForRequest();
      const { lastProcessedFile } = await asyncGetUploadedFiles({
        systemId,
        fileType,
        navigate,
        params: { latest: true },
        timezone,
        requestController,
        requestId: filesRequestReservation.requestId,
        signal: filesRequestReservation.signal,
      });
      console.debug(lp, 'set Files');
      setFiles(lastProcessedFile);
    },
    [navigate, requestController, systemId, timezone],
  );

  const handleUpload = (type) => {
    const lp = getLogPrefixForType('FUNCTION', 'handleUpload', logPrefix);
    console.debug(lp, 'invoked with type: ', type);
    const { file, setProgress, fileType } = fileDetails[type];
    const dispatcher = dispatchFacilityLevel;

    showAlert.info({
      message: 'Uploading...',
      preventClose: true,
      label: fileType,
    });

    requestController.doRequest({
      request: uploadStore.uploadFile,
      requestParams: [systemId, { file, setProgress, fileType, dispatcher }],
      callbackSuccess: () => {
        console.debug(lp, 'uploadStore.uploadFile success callback');
        setProgress(0);

        // Activate loader for current uploaded "fileType"
        setLoader((l) => ({ ...l, [fileType]: true }));

        // Clear file details
        setFileDetails((v) => ({ ...v, [type]: {} }));

        setTimeout(async () => {
          console.debug(lp, 'uploadStore.uploadFile success callback', 'get latest processed file');
          const filesRequestReservation = requestController.reserveSlotForRequest();
          const { lastProcessedFile } = await asyncGetUploadedFiles({
            systemId,
            fileType,
            params: { latest: true },
            timezone,
            requestController,
            requestId: filesRequestReservation.requestId,
            signal: filesRequestReservation.signal,
          });

          if (!requestController.isRequestCancelled(filesRequestReservation.requestId)) {
            console.debug(
              lp,
              'uploadStore.uploadFile success callback',
              'set latest processed file',
            );
            if (fileType === 'WMSSnapshot') {
              setLatestWMSSnapshotFile(lastProcessedFile);
            } else if (fileType === 'StockCheck') {
              setLatestStockCheckFile(lastProcessedFile);
            } else if (fileType === 'MovedLocations') {
              setLatestMovedLocationsFile(lastProcessedFile);
            }
            setLoader((l) => ({ ...l, [fileType]: false }));
          }
        }, 2000);
        showAlert.success({
          message:
            'The file was uploaded correctly and will be processed. This can take several minutes.',
          preventClose: false,
          label: fileType,
        });
      },
      callbackError: (e) => {
        console.debug(lp, 'uploadStore.uploadFile error callback');
        console.error('Error => ', e);
        showAlert.error({
          message: 'Failed to upload!',
          preventClose: false,
          label: fileType,
        });
        setProgress(0);
      },
    });
  };

  const latestWarehouseData = {
    title: 'WMS snapshot',
    valid: latestWMSSnapshotFile.valid,
    subtitle: (
      <DownloadLink
        label={latestWMSSnapshotFile.clientFileName}
        url={latestWMSSnapshotFile.fileURL}
      />
    ),
    actionButtons: [
      {
        testId: 'c-upload-wms-snapshot-btn',
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
        className: 'marginBottom',
        disabled: isEmpty(fileDetails.WMSSnapshot),
        label: 'Upload WMS snapshot',
        clickHandler: () => handleUpload('WMSSnapshot'),
      },
      {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        label: 'SEE UPLOADED FILES',
        clickHandler: () => {
          navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS}`, {
            state: { activeTab: wmsSnapshotFilesTab },
          });
        },
      },
    ],
    sections: !isEmpty(latestWMSSnapshotFile) && [
      {
        sectionItems: [
          {
            label: 'File uploaded at:',
            value: latestWMSSnapshotFile.uploadedAt,
          },
          {
            label: 'Last entry updated:',
            value: latestWMSSnapshotFile.lastEntryUpdate,
          },
          {
            label: 'Total number of locations:',
            value: latestWMSSnapshotFile.totalLocations,
          },
          {
            label: 'Updated locations:',
            value: latestWMSSnapshotFile.updatedLocations,
          },
        ],
      },
    ],
  };
  // Stock check card data

  const stockCheckData = {
    title: 'Stock check',
    subtitle: (
      <DownloadLink
        label={latestStockCheckFile.clientFileName}
        url={latestStockCheckFile.fileURL}
      />
    ),
    actionButtons: [
      {
        testId: 'c-upload-stock-check-btn',
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
        className: 'marginBottom',
        disabled: isEmpty(fileDetails.StockCheck),
        label: 'Upload stock check',
        clickHandler: () => handleUpload('StockCheck'),
      },
      {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        label: 'SEE UPLOADED FILES',
        clickHandler: () => {
          navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS}`, {
            state: { activeTab: stockCheckFilesTab },
          });
        },
      },
    ],
    sections: !isEmpty(latestStockCheckFile) && [
      {
        sectionItems: [
          {
            label: 'File uploaded at:',
            value: latestStockCheckFile.uploadedAt,
          },
          {
            label: 'Last entry updated:',
            value: latestStockCheckFile.lastEntryUpdate,
          },
          {
            label: 'Total number of locations:',
            value: latestStockCheckFile.totalLocations,
          },
          {
            label: 'Updated locations:',
            value: latestStockCheckFile.updatedLocations,
          },
        ],
      },
    ],
  };

  const movedLocationsData = {
    title: 'Moved locations',
    subtitle: (
      <DownloadLink
        label={latestMovedLocationsFile.clientFileName}
        url={latestMovedLocationsFile.fileURL}
      />
    ),
    actionButtons: [
      {
        testId: 'c-upload-moved-locations-btn',
        variant: 'contained',
        color: 'primary',
        fullWidth: true,
        className: 'marginBottom',
        disabled: isEmpty(fileDetails.MovedLocations),
        label: 'Upload moved locations',
        clickHandler: () => handleUpload('MovedLocations'),
      },
      {
        variant: 'outlined',
        color: 'primary',
        fullWidth: true,
        label: 'SEE UPLOADED FILES',
        clickHandler: () => {
          navigate(`/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS}`, {
            state: { activeTab: movedLocationsFilesTab },
          });
        },
      },
    ],
    sections: !isEmpty(latestMovedLocationsFile) && [
      {
        sectionItems: [
          {
            label: 'File uploaded at:',
            value: latestMovedLocationsFile.uploadedAt,
          },
          {
            label: 'Last entry updated:',
            value: latestMovedLocationsFile.lastEntryUpdate,
          },
          {
            label: 'Total number of locations:',
            value: latestMovedLocationsFile.totalLocations,
          },
          {
            label: 'Updated locations:',
            value: latestMovedLocationsFile.updatedLocations,
          },
        ],
      },
    ],
  };

  const getAllUploadedFilesData = useCallback(() => {
    console.debug(logPrefix, 'getAllUploadedFilesData invoked');
    Promise.all([
      getFiles('WMSSnapshot', setLatestWMSSnapshotFile),
      getFiles('StockCheck', setLatestStockCheckFile),
      getFiles('MovedLocations', setLatestMovedLocationsFile),
    ]);
  }, [getFiles]);

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageHeader systemId={systemId} title="Administration" />
      </PageLayout.Header>

      <PageLayout.Section>
        <Uploads
          showAlert={showAlert}
          getFiles={getAllUploadedFilesData}
          wmsSnapshot={{
            alertState: alertState.WMSSnapshot,
            data: latestWarehouseData,
            progress: progressWms,
            fileDetails: fileDetails.WMSSnapshot,
            isLoading: loader.WMSSnapshot,
            upload: (e) =>
              setFileDetails((v) => ({
                ...v,
                WMSSnapshot: {
                  file: e[0],
                  setProgress: setProgressWms,
                  fileType: 'WMSSnapshot',
                },
              })),
          }}
          stockCheck={{
            alertState: alertState.StockCheck,
            data: stockCheckData,
            progress: progressStockCheck,
            fileDetails: fileDetails.StockCheck,
            isLoading: loader.StockCheck,
            upload: (e) =>
              setFileDetails((v) => ({
                ...v,
                StockCheck: {
                  file: e[0],
                  setProgress: setProgressStockCheck,
                  fileType: 'StockCheck',
                },
              })),
          }}
          movedLocations={{
            alertState: alertState.MovedLocations,
            data: movedLocationsData,
            progress: progressMovedLocations,
            fileDetails: fileDetails.MovedLocations,
            isLoading: loader.MovedLocations,
            upload: (e) =>
              setFileDetails((v) => ({
                ...v,
                MovedLocations: {
                  file: e[0],
                  setProgress: setProgressMovedLocations,
                  fileType: 'MovedLocations',
                },
              })),
          }}
        />
      </PageLayout.Section>
    </PageLayout>
  );
};
