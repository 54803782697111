import { Typography, TextField } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { IWmsSimulatorPostRequestParamsInnerST } from 'codegen/dev_tools';
import { PICKER_DATETIME_FORMAT } from 'common/datetimeFormats';
import moment from 'moment';
import { Dispatch, SetStateAction } from 'react';

export const WmsSimulatorDateSelector = ({
  item,
  index,
  wmsData,
  setWmsData,
}: {
  item: IWmsSimulatorPostRequestParamsInnerST;
  index: number;
  wmsData: IWmsSimulatorPostRequestParamsInnerST[];
  setWmsData: Dispatch<SetStateAction<IWmsSimulatorPostRequestParamsInnerST[]>>;
}) => {
  const handleWmsDateChange = (index: number, value: string | null) => {
    const updatedData = [...wmsData];
    if (value && !Number.isNaN(new Date(value).getTime())) {
      updatedData[index].wms_date = new Date(value).toISOString();
    } else {
      updatedData[index].wms_date = undefined;
    }
    setWmsData(updatedData);
  };

  return (
    <>
      <Typography variant="subtitle2">WMS Date</Typography>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <DateTimePicker
          ampm={false}
          inputFormat={PICKER_DATETIME_FORMAT}
          renderInput={(params) => <TextField size="small" {...params} />}
          value={item.wms_date ?? null}
          onChange={(value) =>
            handleWmsDateChange(index, value ? value.format('YYYY-MM-DD HH:mm') : null)
          }
          maxDateTime={moment()}
        />
      </LocalizationProvider>
    </>
  );
};
