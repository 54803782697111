import { MouseEventHandler, useState } from 'react';
import { Tooltip } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import isEmpty from 'lodash/isEmpty';
import MenuItem from '@mui/material/MenuItem';
import { ActionMenuOptions } from '../../../../common/ActionsMenu';
import { IconButtonSquare } from '../square-icon-button/IconButtonSquare';

export const LocationModalActionsMenu = ({ options }: { options: ActionMenuOptions[] }) => {
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLButtonElement) | null>(null);

  const open = Boolean(anchorEl);

  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (externalEvent: () => void) => {
    setAnchorEl(null);
    externalEvent();
  };

  return (
    <>
      <IconButtonSquare
        aria-label="More options"
        data-testid="c-actions-button"
        onClick={handleClick}
      >
        <Tooltip title="More options">
          <MoreVertIcon />
        </Tooltip>
      </IconButtonSquare>

      <Menu
        data-testid="c-actions-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
      >
        {!isEmpty(options) ? (
          options.map((option: ActionMenuOptions, i: number) => (
            <MenuItem
              data-testid={`c-action-${i}`}
              key={option.id}
              disabled={option.disabled}
              onClick={() => handleMenuItemClick(option.clickHandler)}
            >
              {option.label}
            </MenuItem>
          ))
        ) : (
          <MenuItem data-testid="c-action-no-items" key="option.label-no-items" disabled={true}>
            no items to display
          </MenuItem>
        )}
      </Menu>
    </>
  );
};
