import MicrosoftIcon from '@mui/icons-material/Microsoft';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { useState } from 'react';
import { EnhancedTable } from 'components/EnhancedTable';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { IGroupGetAllResponseST, IUserResponseSTStatusEnum } from 'codegen/user_admin';
import { QueryNames } from 'ts-types/QueryNames';
import { useClientLevelStore } from 'store/ClientLevelStore/clientLevelStore';
import { AxiosResponse } from 'axios';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../../store/UserLevelStore/userLevelStore';
import { transformUsersFromDTO } from './model/transformUsersFromDTO';
import { getUsersWithGroups } from './api/getUsers.api';
import { getFacilities } from './api/getFacilities.api';
import { transformFacilitiesFromDTO } from './model/transformFacilitiesFromDTO';
import { usersColumnStructure } from './defaults/usersColumnStructure.defaults';
import { User as UserType, Users as UsersType, UserTableRow } from './model/user.model';
import { Facility } from './model/facility.model';
import { EditUserModal } from './features/EditUserModal/EditUserModal';
import { DeleteUserModal } from './features/DeleteUserModal/DeleteUserModal';
import { emailFromUsers } from './utils/emailsFromUsers.util';
import { AddUserModal } from './features/AddUserModal/AddUserModal';
import { filterFacilitiesBasedOnPermission } from './model/filterFacilitiesBasedOnPermission';
import { getUserGroups } from './features/EditUserModal/api/getUserGroups.api';

enum UsersState {
  list,
  add,
  edit,
  delete,
}

export const Users = () => {
  const { systemId = '' } = useParams();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const {
    stateClientLevel: { facilityList },
  } = useClientLevelStore();
  const [usersState, setUsersState] = useState<UsersState>(UsersState.list);

  const [editUserId, setEditUserId] = useState<undefined | string>(undefined);
  const [deleteUser, setDeleteUser] = useState<undefined | UserType>(undefined);

  const {
    isLoading: isLoadingFacilities,
    isRefetching: isRefetchingFacilities,
    data: facilities = [],
    refetch: refetchFacilities,
  } = useQuery({
    queryKey: [QueryNames.FETCH_FACILITIES, systemId],
    queryFn: getFacilities,
    select: (response) =>
      filterFacilitiesBasedOnPermission(transformFacilitiesFromDTO(response), facilityList),
  });

  const {
    isLoading: isLoadingUsers,
    isRefetching: isRefetchingUsers,
    data: users,
    refetch: refetchUsers,
  } = useQuery({
    queryKey: [QueryNames.FETCH_USERS, systemId],
    queryFn: getUsersWithGroups(systemId as string),
    select: transformUsersFromDTO,
  });

  const refetchData = async () => {
    await refetchUsers();
    await refetchFacilities();
  };

  const { data: userGroups, isLoading: isLoadingUserGroups } = useQuery({
    queryKey: [QueryNames.FETCH_USER_GROUPS, systemId],
    queryFn: () => getUserGroups(systemId),
    select: (response: AxiosResponse<IGroupGetAllResponseST>) =>
      Object.fromEntries(response.data.groups.map((group) => [group.id, group.display_name])),
  });

  const handleEditDeleteUser = (userRow: UserType) => {
    setEditUserId(userRow.id);
    setUsersState(UsersState.edit);
  };

  const handleDeleteUser = (user: UserType) => {
    setDeleteUser(user);
    setUsersState(UsersState.delete);
  };

  const userTableItem: (users: UsersType) => Array<UserTableRow> = (users) =>
    users.map((user) => ({
      ...user,
      email: user.email ? user.email : 'NOT PROVIDED',
      facilities: user.systemIds.map((systemId: number) => {
        const facility = facilities.find((f: Facility) => f.id === systemId);
        return facility ? facility.name : `${systemId}`;
      }),
      groupDisplayNames: user.userGroups.map((group) => (userGroups ? userGroups[group] : group)),
      statusIcon:
        user.status === IUserResponseSTStatusEnum.ExternalProvider ? <MicrosoftIcon /> : '',
      actions: !user.isReadOnly
        ? {
            actions: [
              {
                label: 'Edit User',
                disabled: false,
                onClick: () => handleEditDeleteUser(user),
              },
              {
                label: 'Delete User',
                disabled: stateUserLevel.usernameHashed === user.usernameHashed,
                onClick: () => handleDeleteUser(user),
              },
            ],
          }
        : undefined,
    }));

  const isLoading = () =>
    isLoadingFacilities ||
    isRefetchingFacilities ||
    isLoadingUsers ||
    isRefetchingUsers ||
    isLoadingUserGroups;

  const onCloseEditModal = () => {
    setUsersState(UsersState.list);
    setEditUserId(undefined);
  };

  const onCloseDeleteModal = () => {
    setUsersState(UsersState.list);
    setDeleteUser(undefined);
  };

  return (
    <>
      <PageLayout>
        <PageLayout.Header>
          <PageHeader systemId={systemId} title="Administration" />
        </PageLayout.Header>

        <PageLayout.Section>
          <Grid direction="column" container className="c-page-content">
            <EnhancedTable
              tableFor="Users"
              tableTitle="Users"
              tableSubtitle={`Here is a list of all the users associated with ${
                stateFacilityLevel.facilityData?.client ?? ''
              }`}
              headCells={usersColumnStructure}
              rows={users && facilities ? userTableItem(users) : []}
              isLoading={isLoading()}
              refreshData={{ refreshData: () => refetchData }}
              currentActiveSpinner={{ Users: isLoading() }}
              headerButton={
                <Button
                  data-testid="AddUserButton"
                  variant="contained"
                  color="primary"
                  onClick={() => setUsersState(UsersState.add)}
                >
                  Add user
                </Button>
              }
            />
          </Grid>
        </PageLayout.Section>
      </PageLayout>

      {usersState === UsersState.edit && (
        <EditUserModal
          facilities={facilities}
          allUserEmails={users ? emailFromUsers(users) : []}
          userId={editUserId}
          onClose={onCloseEditModal}
          onEdited={refetchData}
        />
      )}

      {usersState === UsersState.add && facilities && (
        <AddUserModal
          allUserEmails={users ? emailFromUsers(users) : []}
          onClose={() => {
            setUsersState(UsersState.list);
          }}
          onAdded={refetchData}
          facilities={facilities}
        />
      )}

      {usersState === UsersState.delete && deleteUser && (
        <DeleteUserModal user={deleteUser} onClose={onCloseDeleteModal} onDeleted={refetchData} />
      )}
    </>
  );
};
