import {
  ISlotSettingsSTExclusionStatusEnum,
  IVeritySlotStatusStateST,
  IWMSSlotStatusSTStateEnum,
} from 'codegen/location_information';

export enum LocationHistoryEventTypes {
  WMS_UPDATE = 'WMS_UPDATE',
  DRONE_REPORT = 'DRONE_REPORT',
  EXCLUSION_STATUS_UPDATE = 'EXCLUSION_STATUS_UPDATE',
}

export type LocationHistoryItem = {
  type: LocationHistoryEventTypes;
  timestamp: string;
  updatedAt: string;
  exclusion_status: ISlotSettingsSTExclusionStatusEnum | null;
  state: IVeritySlotStatusStateST | IWMSSlotStatusSTStateEnum | null;
  barcodes: Array<string>;
  version?: number;
  snapshotId?: string;
};

export type TransformedHistoryData = {
  items: LocationHistoryItem[];
  next_timepoint: string | null;
};

export const EMPTY_HISTORY_DATA: TransformedHistoryData = {
  items: [],
  next_timepoint: null,
};
