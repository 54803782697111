import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
  },
  wrapper: {
    '& iframe': {
      width: '100%',
      height: 'calc(100vh - 328px);',
      border: 'none',
    },
  },
  loader: {
    position: 'relative',
    minHeight: '40vh',
  },
}));
