import { Dashboard } from 'pages/Analytics/Dashboard';
import { ISupersetSettings } from 'pages/Analytics/functions/GetSupersetSettings';
import { MultiDashboardAnalytics } from 'pages/Analytics/MultiDashboardAnalytics';

export const AnalyticsDashboard = ({
  supersetSettings,
  isMultipleFacility,
  onGuestTokenFetch,
}: {
  supersetSettings: ISupersetSettings;
  isMultipleFacility: boolean;
  onGuestTokenFetch: () => Promise<string>;
}) =>
  isMultipleFacility ? (
    <MultiDashboardAnalytics
      supersetSettings={supersetSettings}
      fetchSystemSpecificSuperSetGuestToken={onGuestTokenFetch}
    />
  ) : (
    <Dashboard
      dashboardId={supersetSettings.facility_dashboard_id}
      supersetDomain={supersetSettings.base_url}
      fetchGuestToken={onGuestTokenFetch}
    />
  );
