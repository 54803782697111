import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

type TableTabsProps = {
  tabs: {
    label: string;
    show: boolean;
  }[];
  activeTabIndex: number;
  handleTabChange: (event: React.ChangeEvent<HTMLInputElement>, value: number) => void;
  tabCounts: {
    [key: string]: number;
  };
};

export const ReportTabs = ({
  tabs,
  activeTabIndex,
  handleTabChange,
  tabCounts,
}: TableTabsProps) => (
  <Tabs
    aria-label="tabs"
    value={activeTabIndex}
    scrollButtons
    allowScrollButtonsMobile
    variant="scrollable"
    onChange={handleTabChange}
  >
    {tabs
      .filter((tab) => tab.show)
      .map((tab) => {
        const tabCount =
          typeof tabCounts[tab.label] === 'number' ? ` (${tabCounts[tab.label]})` : '';
        return <Tab data-testid="c-table-tab" key={tab.label} label={`${tab.label}${tabCount}`} />;
      })}
  </Tabs>
);
