import { useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material';
import List from '@mui/material/List';
import Collapse from '@mui/material/Collapse';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import type { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { Box } from '../Box';
import type { MenuLink, MenuSection } from './facilityMenu.model';
import { FacilityMenuLink } from './features/facility-menu-link/FacilityMenuLink';

const ListItemTooltip = styled(({ className, children, title, ...props }: TooltipProps) => (
  <Tooltip title={title} {...props} placement="right" classes={{ popper: className }}>
    {children}
  </Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    opacity: 1,
    padding: 8,
    borderRadius: 0,
    backgroundColor: theme.palette.grey[100],
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 400,
  },
}));

export const FacilityMenuItem = ({
  items,
  divider,
  opened,
  id,
  label,
  isDrawerOpened,
  systemId,
  handleAccordions,
}: {
  items: MenuSection[] | MenuLink[];
  divider: boolean;
  opened: boolean;
  id: string;
  label: string;
  isDrawerOpened: boolean;
  systemId: string;
  handleAccordions: (key: string) => void;
}) => {
  const navigate = useNavigate();
  const theme = useTheme();

  const accordionHeaderStyle = {
    height: '50px',
    pl: isDrawerOpened ? '20px' : 2,
    pr: isDrawerOpened ? 1.5 : 3.5,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  const nestedAccordionHeaderStyle = {
    height: '50px',
    pl: isDrawerOpened ? 2 : 2,
    pr: isDrawerOpened ? 0.5 : 3,
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  };

  return (
    <div key={id}>
      <ListItemTooltip title={!isDrawerOpened ? label : ''}>
        <ListItemButton
          data-testid={`c-nav-accordion-item-${id}`}
          sx={accordionHeaderStyle}
          selected={opened}
          onClick={() => handleAccordions(id)}
        >
          <ListItemText
            sx={{
              opacity: isDrawerOpened ? 1 : 0,
              transition: theme.transitions.create(['opacity'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen,
              }),
            }}
            primary={<Typography variant="h6">{label}</Typography>}
          />

          {items &&
            (opened ? (
              <ExpandLess sx={{ color: theme.palette.grey[600] }} />
            ) : (
              <ExpandMore sx={{ color: theme.palette.grey[600] }} />
            ))}
        </ListItemButton>
      </ListItemTooltip>

      {items && (
        <Collapse
          sx={{ borderBottom: divider ? `1px solid ${theme.palette.grey[300]}` : 'none' }}
          in={opened}
          timeout="auto"
          unmountOnExit
        >
          <List data-testid="c-accordion-content" component="div" disablePadding>
            {items
              .filter((el) => el.visible)
              .map((item) => {
                if ('items' in item) {
                  return (
                    <div key={item.testId}>
                      <ListItemTooltip title={!isDrawerOpened ? item?.label : ''}>
                        <ListItemButton
                          data-testid={`c-nav-accordion-item-${item.id}`}
                          sx={nestedAccordionHeaderStyle}
                          selected={item?.opened}
                          onClick={() => {
                            if (!!item.path && !item?.opened) {
                              navigate(item.path);
                            }

                            handleAccordions(item.id);
                          }}
                        >
                          {item?.icon && isDrawerOpened && (
                            <ListItemIcon
                              sx={{
                                minWidth: 35,
                                color: 'currentColor',
                              }}
                            >
                              {item.icon}
                            </ListItemIcon>
                          )}

                          <ListItemText
                            sx={{
                              opacity: isDrawerOpened ? 1 : 0,
                              transition: theme.transitions.create(['opacity'], {
                                easing: theme.transitions.easing.sharp,
                                duration: theme.transitions.duration.leavingScreen,
                              }),
                            }}
                            primary={
                              <Typography
                                sx={{
                                  width: '200px',
                                  textOverflow: 'ellipsis',
                                  overflowX: 'hidden',
                                }}
                              >
                                {item.label}
                              </Typography>
                            }
                          />
                          <Box pr={1}>
                            {items &&
                              (item?.opened ? (
                                <ExpandLess sx={{ color: theme.palette.grey[600] }} />
                              ) : (
                                <ExpandMore sx={{ color: theme.palette.grey[600] }} />
                              ))}
                          </Box>
                        </ListItemButton>
                      </ListItemTooltip>

                      {/* Nested link items */}
                      <Collapse in={item.opened} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {item.items
                            .filter((el) => el.visible)
                            .map((nestedLink) => (
                              <ListItemTooltip
                                key={nestedLink.testId}
                                title={!isDrawerOpened ? nestedLink?.label : ''}
                              >
                                <FacilityMenuLink
                                  isCollapsed={!isDrawerOpened}
                                  data-testid={`${nestedLink.testId}`}
                                  to={`/${systemId}${nestedLink.path}`}
                                  nested
                                  variant={
                                    'variant' in nestedLink ? nestedLink.variant : 'standard'
                                  }
                                  end={'end' in nestedLink ? nestedLink.end : false}
                                >
                                  {nestedLink?.icon && (
                                    <ListItemIcon
                                      sx={{
                                        minWidth: 35,
                                        color: 'currentColor',
                                      }}
                                    >
                                      {nestedLink.icon}
                                    </ListItemIcon>
                                  )}

                                  <ListItemText
                                    sx={{
                                      opacity: isDrawerOpened ? 1 : 0,
                                      transition: theme.transitions.create(['opacity'], {
                                        easing: theme.transitions.easing.sharp,
                                        duration: theme.transitions.duration.leavingScreen,
                                      }),
                                    }}
                                    primary={nestedLink?.label}
                                  />
                                </FacilityMenuLink>
                              </ListItemTooltip>
                            ))}
                        </List>
                      </Collapse>
                    </div>
                  );
                }

                //  If menu item doesn't have nested links then
                //  we render it as default link item
                return (
                  <ListItemTooltip key={item.testId} title={!isDrawerOpened ? item?.label : ''}>
                    <FacilityMenuLink
                      isCollapsed={!isDrawerOpened}
                      data-testid={item.testId}
                      to={`/${systemId}${item.path}`}
                      end={item.end}
                    >
                      {item?.icon && (
                        <ListItemIcon
                          sx={{
                            minWidth: 35,
                            color: 'currentColor',
                          }}
                        >
                          {item.icon}
                        </ListItemIcon>
                      )}

                      <ListItemText
                        sx={{
                          opacity: isDrawerOpened ? 1 : 0,
                          transition: theme.transitions.create(['opacity'], {
                            easing: theme.transitions.easing.sharp,
                            duration: theme.transitions.duration.leavingScreen,
                          }),
                        }}
                        primary={item?.label}
                      />

                      {item?.statusLabel && item.statusLabel}
                    </FacilityMenuLink>
                  </ListItemTooltip>
                );
              })}
          </List>
        </Collapse>
      )}
    </div>
  );
};
