import { useStyles } from './pageLayout.styles';
import { PageSectionProps, PageSectionSectionProps } from './pageLayout.model';

const PageLayoutBase = ({ children, ...props }: PageSectionProps) => {
  const { cx, classes } = useStyles();

  return (
    <div {...props} className={cx(classes.wrapper)}>
      {children}
    </div>
  );
};

PageLayoutBase.displayName = 'PageLayout';

const PageLayoutHeader = ({ children, fullWidth, ...props }: PageSectionSectionProps) => {
  const { cx, classes } = useStyles();

  return (
    <div
      {...props}
      className={cx(classes.section, classes.sectionHeader, {
        [classes.sectionFullWidth]: fullWidth,
      })}
    >
      {children}
    </div>
  );
};

PageLayoutHeader.displayName = 'PageLayout.Header';

const PageLayoutSection = ({ children, fullWidth, ...props }: PageSectionSectionProps) => {
  const { cx, classes } = useStyles();

  return (
    <div {...props} className={cx(classes.section, { [classes.sectionFullWidth]: fullWidth })}>
      {children}
    </div>
  );
};

PageLayoutSection.displayName = 'PageLayout.Section';

export const PageLayout = Object.assign(PageLayoutBase, {
  Header: PageLayoutHeader,
  Section: PageLayoutSection,
});
