import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { ILabelTypeST } from 'codegen/location_action';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { locationActionApi } from '../api/locationLabel.api';

const LABELS_DATA_QUERY_KEY = 'labels';
const LABELS_ADD_MUTATION_KEY = 'labels-add';
const LABELS_ARCHIVE_MUTATION_KEY = 'labels-archive';

export const useLocationLabel = ({ locationId }: { locationId: string }) => {
  const queryClient = useQueryClient();
  const { currentSystemId: systemId } = useFacilityLevelStore().stateFacilityLevel;

  const { isLoading, data, error } = useQuery({
    queryKey: [LABELS_DATA_QUERY_KEY, locationId],
    queryFn: () => locationActionApi().getLocationLabel(systemId as string, locationId),
    select: ({ data }) => ({
      ...data,
    }),
    retry: false,
  });

  const { mutate: mutateAddLabel } = useMutation({
    mutationKey: [LABELS_ADD_MUTATION_KEY, locationId],
    mutationFn: (labelType: ILabelTypeST) =>
      locationActionApi().postLocationLabel(systemId as string, locationId, {
        label_type: labelType,
      }),
    onSuccess: (data) => {
      queryClient.setQueryData([LABELS_DATA_QUERY_KEY, locationId], () => ({
        ...data,
      }));
    },
  });

  const { mutate: mutateArchiveLabel } = useMutation({
    mutationKey: [LABELS_ARCHIVE_MUTATION_KEY, locationId],
    mutationFn: () =>
      locationActionApi().putLocationLabel(systemId as string, locationId, {
        label_status: 'ARCHIVED',
      }),
    onSuccess: (data) => {
      queryClient.setQueryData([LABELS_DATA_QUERY_KEY, locationId], () => ({
        ...data,
      }));
    },
  });

  return { isLoading, data, error, mutateAddLabel, mutateArchiveLabel };
};
