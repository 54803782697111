import { useState, useEffect, memo } from 'react';
import { Link, useParams } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Avatar from '@mui/material/Avatar';
import { CLIENT_PAGES_URLS } from 'common/pages';
import { isProductionEnvironment } from 'common/functions/environments';
import { DeveloperDrawer } from 'components/DeveloperDrawer/DeveloperDrawer';
import { UserMenu } from './UserMenu';
import { HideOnScroll } from './HideOnScroll';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useSettingsStore } from '../../store/Settings/settingsStore';
import { headerStyles } from './Header.styles';
import { HEADER_HEIGHT } from './defaults/Header.defaults';
import { getHomeRouteBasedOnPermissions } from '../../udb/select-facility/utils/getHomeRouteBasedOnPermissions';

export const Header = memo(() => {
  const { classes } = headerStyles();

  const { HEADER_SETTINGS } = useSettingsStore();

  const { stateFacilityLevel, isDataReady: isFacilityDataReady } = useFacilityLevelStore();
  const { stateClientLevel } = useClientLevelStore();
  const { stateUserLevel } = useUserLevelStore();
  const multipleFacilitiesAccess = stateClientLevel.isMultiFacility;

  const [open, setOpen] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientLogoUrl, setClientLogoUrl] = useState('');
  const [homeRoute, setHomeRoute] = useState('');
  const [showDevDrawer, setShowDevDrawer] = useState(false);

  const { systemId } = useParams();

  const avatarInitials: string = stateUserLevel?.username?.[0]?.toUpperCase();
  const shouldHeaderRender = !stateFacilityLevel.ui?.notFound;

  const toggleSidebar = () => {
    setOpen(!open);
  };

  useEffect(() => {
    const fetchPermissionHomeRoute = async () => {
      if (systemId) {
        const route = await getHomeRouteBasedOnPermissions(systemId);

        setHomeRoute(route);
      }
    };

    if (multipleFacilitiesAccess) {
      setHomeRoute(CLIENT_PAGES_URLS.SELECT_FACILITY);

      return;
    }

    fetchPermissionHomeRoute();
  }, [multipleFacilitiesAccess, systemId]);

  useEffect(() => {
    if (systemId && !isFacilityDataReady(systemId)) {
      return;
    }

    setClientName(stateFacilityLevel.facilityData?.client);

    setClientLogoUrl(stateFacilityLevel.facilityData?.logo_url);
  }, [stateFacilityLevel.facilityData, isFacilityDataReady, systemId]);

  return shouldHeaderRender ? (
    <>
      <HideOnScroll threshold={HEADER_HEIGHT}>
        <header className={classes.wrapper}>
          <div className={classes.sectionClient}>
            {HEADER_SETTINGS?.SHOW_CLIENT_LOGO && clientLogoUrl && (
              <img
                className={classes.clientLogo}
                src={clientLogoUrl}
                alt={clientName ? `The logo of ${clientName}` : 'The company logo'}
              />
            )}

            <Typography className={classes.title}>
              {HEADER_SETTINGS?.SHOW_CLIENT_NAME && clientName}
            </Typography>
          </div>

          <nav className={classes.sectionLinks}>
            <Link data-testid="c-header-menu-home" to={homeRoute} className={classes.menuOption}>
              Home
            </Link>

            {!isProductionEnvironment() && (
              <button
                data-testid="c-header-menu-dev-tools"
                type="button"
                className={classes.menuOption}
                onClick={() => setShowDevDrawer(!showDevDrawer)}
              >
                Dev-tools
              </button>
            )}

            {HEADER_SETTINGS?.SHOW_SUPPORT_LINK && (
              <Link
                data-testid="c-header-menu-support"
                to={HEADER_SETTINGS?.SUPPORT_URL}
                target="_blank"
                className={classes.menuOption}
              >
                Support
              </Link>
            )}

            <IconButton
              data-testid="c-header-menu-button"
              aria-label="Open drawer"
              onClick={toggleSidebar}
            >
              <Avatar className={classes.avatarPrimary} sx={{ width: 36, height: 36 }}>
                {avatarInitials}
              </Avatar>
            </IconButton>
          </nav>
        </header>
      </HideOnScroll>

      <UserMenu
        avatarInitials={avatarInitials}
        username={stateUserLevel.username}
        open={open}
        toggleSidebar={toggleSidebar}
        classes={classes}
      />

      {!isProductionEnvironment() && (
        <DeveloperDrawer visible={showDevDrawer} close={() => setShowDevDrawer(false)} />
      )}
    </>
  ) : null;
});
