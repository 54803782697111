import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
  wrapper: {
    position: 'relative',
  },
  iconWrapper: {
    transition: 'opacity 220ms',

    svg: {
      display: 'block',
    },
  },
  loading: {
    opacity: 0.6,
  },
  loadingGraphic: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
}));
