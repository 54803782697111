import { useFacilityMap } from 'shared/map-3d/aisle-view/api/useFacilityMap';
import { NodeST } from 'shared/map-container/MapContainer.model';
import { vectorLike2Vector } from 'shared/map-3d/vectorLike2Vector.util';
import { useLocationData } from 'udb/inventory/features/warehouse-status/hooks/useLocationData';
import { getBinStatus } from 'shared/map-3d/aisle-view/utils/getBinStatus.util';
import { Bin3DPropsNoIcon, BinStatus } from 'shared/map-3d/bin3D/model/bin3DProps.model';
import { useParams } from 'react-router-dom';

import { useMemo } from 'react';
import { findNodesByType, flattenNodes } from 'shared/map-3d/map-node.util';
import { ILocationData1ST } from 'codegen/report';

const BIN_SCALE_FACTOR = 0.95;
const RACK_TOP_OFFSET = 0.001;

const statusPriorityMap = {
  ISSUE: 0,
  POTENTIAL_ISSUE: 1,
  MATCH: 2,
  EMPTY: 3,
  EXCLUDED: 4,
  NOT_SCANNED: 5,
};

const getStatus = (statuses: Array<BinStatus>) => {
  if (statuses.every((status) => status === 'EXCLUDED' || status === 'NOT_SCANNED')) {
    return statuses.every((status) => status === 'NOT_SCANNED') ? 'NOT_SCANNED' : 'EXCLUDED';
  }

  return statuses.reduce(
    (highest, current) =>
      statusPriorityMap[current] < statusPriorityMap[highest] ? current : highest,
    'NOT_SCANNED',
  );
};

function getRackMaxHeight(racks: NodeST[]) {
  const rackSizes = racks.map((rack) => vectorLike2Vector(rack.scale));
  return Math.max(...rackSizes.map(({ z }) => z));
}

interface GetRackTopsResult {
  rackTops: Bin3DPropsNoIcon[];
  maxHeightZ: number;
}

function getRackTops(
  map: NodeST,
  locationDataMap: Map<string, ILocationData1ST>,
): GetRackTopsResult {
  const racks = findNodesByType(map, 'RACK');
  const maxHeightZ = getRackMaxHeight(racks);

  return {
    rackTops: racks.map(({ nodes, normal, scale, position, ...rack }) => {
      const bins = flattenNodes({ nodes } as NodeST).filter((node) => node.type === 'BIN');

      const binStatuses = bins.map((bin) => getBinStatus(locationDataMap.get(bin.name)));
      return {
        ...rack,
        position: vectorLike2Vector(position).clone().setZ(maxHeightZ),
        scale: vectorLike2Vector(scale)
          .clone()
          .setZ(RACK_TOP_OFFSET)
          .multiplyScalar(BIN_SCALE_FACTOR),
        status: getStatus(binStatuses),
        hideIcon: true,
      };
    }),
    maxHeightZ,
  };
}

interface UseRackTopsResult {
  rackTops: Bin3DPropsNoIcon[];
  isLoading: boolean;
  maxHeightZ: number;
}

export function useRackTops(selectedFlightDomain: string): UseRackTopsResult {
  const { systemId = '' } = useParams();
  const { data: facilityMap, isLoading: isLoadingMap } = useFacilityMap(systemId);
  const { locationDataMap, isLoadingLocations } = useLocationData(systemId);

  const { rackTops, maxHeightZ } = useMemo(() => {
    if (isLoadingMap || !facilityMap || isLoadingLocations) return { rackTops: [], maxHeightZ: 0 };
    const flightDomain = facilityMap.nodes?.find((node) => node.name === selectedFlightDomain);

    if (!flightDomain) {
      return { rackTops: [], maxHeightZ: 0 };
    }

    return getRackTops(flightDomain, locationDataMap);
  }, [facilityMap, isLoadingMap, locationDataMap, isLoadingLocations, selectedFlightDomain]);

  return { rackTops, maxHeightZ, isLoading: isLoadingMap || isLoadingLocations };
}
