import { makeStyles } from 'tss-react/mui';

export const useWmsDataSimulatorStyles = makeStyles()(() => ({
  wrapper: {
    minWidth: '900px',
  },
  applyEditsButton: {
    marginBottom: 6,
  },
  cardsContainer: {
    paddingTop: 8,
  },
  card: {
    border: '1px solid #ccc',
    position: 'relative',
  },
  cardCloseIcon: {
    position: 'absolute',
    top: 8,
    right: 8,
    zIndex: 1,
  },
  addBtn: {
    marginTop: 8,
  },
}));
