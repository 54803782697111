import { lazy } from 'react';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { FetchingErrorPlaceholder } from '../fetching-error-placeholder/loadingPlaceholder';

export const RemotePickingLocations = lazy(() =>
  // eslint-disable-next-line import/no-unresolved
  import('remote-picking-locations/RemotePickingLocations').catch(() => ({
    default: () => (
      <PageLayout>
        <PageLayout.Section>
          <FetchingErrorPlaceholder />
        </PageLayout.Section>
      </PageLayout>
    ),
  })),
);
