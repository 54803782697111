import {
  HeadCellAlign,
  HeadCellProp,
  HeadCellType,
} from '../../../../components/EnhancedTable/types/cell';

export const usersColumnStructure: Array<HeadCellProp> = [
  {
    id: 'statusIcon',
    searchable: false,
    show: true,
    type: 'icon' as HeadCellType,
    label: '',
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'email',
    searchable: true,
    show: true,
    label: 'Email',
    type: 'string' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'firstName',
    searchable: true,
    show: true,
    label: 'First Name',
    type: 'string' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'lastName',
    searchable: true,
    show: true,
    label: 'Last Name',
    type: 'string' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'phone',
    searchable: true,
    show: true,
    label: 'Phone',
    type: 'string' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'facilities',
    searchable: true,
    show: true,
    label: 'Facilities',
    type: 'string' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'groupDisplayNames',
    searchable: true,
    show: true,
    label: 'User groups',
    type: 'string' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
  {
    id: 'actions',
    searchable: false,
    show: true,
    label: 'Actions',
    type: 'actionButton' as HeadCellType,
    align: 'center' as HeadCellAlign,
  },
];
