import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GROUND_CONTROL_STATUS_COLORS } from 'common/colors';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    textDecoration: 'none',
    alignItems: 'center',
    paddingInline: theme.spacing(2, 2),
    paddingBlock: theme.spacing(1),
    minHeight: '50px',
    color: 'inherit',
    transition: 'background-color 200ms, color 200ms, padding 200ms',

    '& .MuiChip-root': {
      backgroundColor: theme.palette.background.default,
    },

    ':hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },

    svg: {
      display: 'block',
      opacity: 0.55,
    },
  },
  active: {
    svg: {
      opacity: 0.95,
    },
  },
  standard: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,

    ':hover': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  safe: {
    backgroundColor: GROUND_CONTROL_STATUS_COLORS.SAFE_COLOR,
    color: theme.palette.primary.contrastText,

    ':hover': {
      backgroundColor: GROUND_CONTROL_STATUS_COLORS.SAFE_COLOR,
    },
  },
  unsafe: {
    backgroundColor: GROUND_CONTROL_STATUS_COLORS.UNSAFE_COLOR,
    color: theme.palette.primary.contrastText,

    ':hover': {
      backgroundColor: GROUND_CONTROL_STATUS_COLORS.UNSAFE_COLOR,
    },
  },
  emergency: {
    backgroundColor: GROUND_CONTROL_STATUS_COLORS.EMERGENCY_COLOR,
    color: theme.palette.primary.contrastText,

    ':hover': {
      backgroundColor: GROUND_CONTROL_STATUS_COLORS.EMERGENCY_COLOR,
    },
  },
  nested: {
    paddingInlineStart: theme.spacing(4),
  },
  collapsed: {
    paddingInlineStart: theme.spacing(2.25),
  },
}));
