import { useTheme } from '@mui/material';
import { Plane } from '@react-three/drei';
import { FC } from 'react';
import { ActiveAisleProps } from './ActiveAisle.model';
import { OutlineLines } from '../outline-lines/OutlineLines';

export const ActiveAisle: FC<ActiveAisleProps> = ({
  position,
  scale,
  lineWidth = 0.2,
  opacity = 0.08,
  ...dashedProps
}) => {
  const theme = useTheme();
  const color = theme.palette.info.main;

  return (
    <Plane position={position} scale={scale}>
      <meshStandardMaterial color={color} transparent opacity={opacity} />
      <OutlineLines color={color} lineWidth={lineWidth} scale={scale} dashedProps={dashedProps} />
    </Plane>
  );
};
