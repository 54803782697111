import { IReportDataST } from 'codegen/report';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { LocationReportData } from 'udb/inventory/models/LocationReportData';

export const transformReportData = (reportData: IReportDataST): LocationReportData[] => {
  if (!reportData?.locations_data) {
    return [];
  }
  const fullReport: LocationReportData[] = [];

  Object.entries(reportData.locations_data).forEach(([location, locationData]) => {
    let rowData = {} as LocationReportData;
    rowData = getRowForFullReportTable(
      location,
      locationData,
      locationData.issues,
      locationData.state,
    );

    fullReport.push(rowData);
  });

  return fullReport;
};
