import { Alert } from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { LocationHistoryItem } from '../location-history/models/LocationHistoryEventTypes.model';
import { useOutdatedLocationWarningStyles } from './OutdatedLocationWarning.style';

export const OutdatedLocationWarning = ({
  systemId,
  slotLabel,
  historyDataItems,
  historyLimitTimePoint,
  closeModal,
}: {
  systemId: string;
  slotLabel: string;
  historyDataItems: LocationHistoryItem[];
  historyLimitTimePoint: string;
  closeModal: () => void;
}) => {
  const { classes } = useOutdatedLocationWarningStyles();
  const navigate = useNavigate();

  const newerWMSDataExist = historyDataItems
    .filter((item) => item.type === 'WMS_UPDATE')
    .some((item) => historyLimitTimePoint && item.timestamp > historyLimitTimePoint);

  const newerReportDataExist = historyDataItems
    .filter((item) => item.type === 'DRONE_REPORT')
    .some((item) => historyLimitTimePoint && item.timestamp > historyLimitTimePoint);

  const stateWarningMap = [
    {
      condition: newerWMSDataExist && !newerReportDataExist,
      text: 'This Location’s WMS Data has been updated since this report was created',
    },
    {
      condition: !newerWMSDataExist && newerReportDataExist,
      text: 'This Location has been rescanned since this report was created',
    },
    {
      condition: newerWMSDataExist && newerReportDataExist,
      text: 'This Location’s information has been updated since this report was created',
    },
  ];

  const warningText = stateWarningMap.find(({ condition }) => condition)?.text || '';

  const handleOnClick = () => {
    closeModal();
    navigate(
      `/${systemId}${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X}?activeTab=0&location=${slotLabel}`,
    );
  };

  return warningText ? (
    <Alert
      className={classes.alert}
      severity="warning"
      action={
        <Button onClick={handleOnClick} variant="text" color="secondary">
          View latest Location view
        </Button>
      }
    >
      {warningText}
    </Alert>
  ) : null;
};
