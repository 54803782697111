import { IIssueSTStateEnum } from 'codegen/report';
import { ILocationDataST } from 'codegen/warehouse_status';
import { ISSUE_TYPES } from 'common/issueTypesAndStates';
import { getLatestOverwriteFromSlotStatus } from 'common/functions/slot/utils/getLatestOverwriteFromSlotStatus';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { ILabelStatusST, ILocationLabelST } from 'codegen/location_action';
import { TabObject } from 'udb/inventory/features/premium-grid/models/TabObject.model';
import { initialGridState } from '../defaults/initialGridState.default';

export const getWarehouseStatusTabs = ({
  isUserAmendAllowed,
  areLabelsShown,
}: {
  isUserAmendAllowed: boolean;
  areLabelsShown: boolean;
}): TabObject[] => [
  {
    label: 'FULL REPORT',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          'wms_status.changed_at': false,
          'wms_status.qtys.0': true,
          exclusion_status: true,
        },
        orderedFields: [
          'slot_label',
          'issues.0.type',
          'wms_status.state',
          'verity_status.state',
          'wasFoundAt',
          'shouldBeAt',
          'wms_status.customers.0',
          'wms_status.qtys.0',
          'verity_status.collected_at',
          'issues.0.state',
          'exclusion_status',
          'location_label',
          'wms_status.changed_at',
          'wms_status.article_nos.0',
          'content_found_amended',
          'issues.0.first_found_on',
          'expected_barcodes',
          'found_barcodes',
          'missing_barcodes',
          'missing_barcodes_rate',
          'unexpected_barcodes',
          'unexpected_barcodes_rate',
          'matching_barcodes',
          'matching_barcodes_rate',
          'collection_delay',
        ],
      },
      sorting: {
        sortModel: [{ field: 'slot_label', sort: 'asc' }],
      },
    },
  },
  {
    label: 'ISSUES',
    show: true,
    gridState: {
      ...initialGridState,
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.WMS_BARCODE_VERITY_EMPTY,
      ISSUE_TYPES.WMS_BARCODE_NOT_EQUAL_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_BARCODE,
      ISSUE_TYPES.WMS_EMPTY_VERITY_NOTEMPTY,
      ISSUE_TYPES.WMS_BARCODE_DUPLICATE,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'POTENTIAL',
    show: true,
    gridState: {
      ...initialGridState,
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NOTEMPTY,
      ISSUE_TYPES.C001C_MISSING_BC,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'INCONCLUSIVE',
    show: true,
    gridState: {
      ...initialGridState,
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    issueTypeFilters: [
      ISSUE_TYPES.INCONCLUSIVE_WMS_BARCODE_VERITY_NORESULT,
      ISSUE_TYPES.INCONCLUSIVE_WMS_EMPTY_VERITY_NORESULT,
    ],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'SNOOZED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: true,
          content_found_amended: true,
          'issues.0.first_found_on': true,
          'wms_status.changed_at': false,
        },
        orderedFields: [
          'slot_label',
          'exclusion_status',
          'issues.0.type',
          'content_found_amended',
          'wms_status.state',
          'verity_status.state',
          'wasFoundAt',
          'shouldBeAt',
          'verity_status.collected_at',
          'issues.0.first_found_on',
          'wms_status.customers.0',
          'location_label',
          'issues.0.state',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'wms_status.changed_at',
          'expected_barcodes',
          'found_barcodes',
          'missing_barcodes',
          'missing_barcodes_rate',
          'unexpected_barcodes',
          'unexpected_barcodes_rate',
          'matching_barcodes',
          'matching_barcodes_rate',
          'collection_delay',
        ],
      },
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    issueStateFilters: [IIssueSTStateEnum.Snoozed],
  },
  {
    label: 'AMENDED',
    show: userHasPermission(PERMISSION.AMEND_REPORT) && isUserAmendAllowed,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: true,
          content_found_amended: true,
          'issues.0.first_found_on': true,
          'wms_status.changed_at': false,
        },
        orderedFields: [
          'slot_label',
          'exclusion_status',
          'issues.0.type',
          'content_found_amended',
          'wms_status.state',
          'verity_status.state',
          'wasFoundAt',
          'shouldBeAt',
          'verity_status.collected_at',
          'issues.0.first_found_on',
          'wms_status.customers.0',
          'location_label',
          'issues.0.state',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'wms_status.changed_at',
          'expected_barcodes',
          'found_barcodes',
          'missing_barcodes',
          'missing_barcodes_rate',
          'unexpected_barcodes',
          'unexpected_barcodes_rate',
          'matching_barcodes',
          'matching_barcodes_rate',
          'collection_delay',
        ],
      },
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    filter: (locationData: ILocationDataST) =>
      !!getLatestOverwriteFromSlotStatus(locationData.verity_status, 'client'),
  },
  {
    label: 'EXCLUDED',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: true,
          content_found_amended: true,
          'issues.0.first_found_on': true,
          'wms_status.changed_at': false,
          wasFoundAt: false,
          shouldBeAt: false,
          'wms_status.customers.0': false,
        },
        orderedFields: [
          'slot_label',
          'exclusion_status',
          'issues.0.type',
          'content_found_amended',
          'wms_status.state',
          'verity_status.state',
          'verity_status.collected_at',
          'issues.0.first_found_on',
          'location_label',
          'issues.0.state',
          'wasFoundAt',
          'shouldBeAt',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'wms_status.changed_at',
          'expected_barcodes',
          'found_barcodes',
          'missing_barcodes',
          'missing_barcodes_rate',
          'unexpected_barcodes',
          'unexpected_barcodes_rate',
          'matching_barcodes',
          'matching_barcodes_rate',
          'collection_delay',
        ],
        dimensions: {
          slot_label: {
            maxWidth: -1,
            minWidth: 100,
            width: 178.5,
            flex: 0,
          },
        },
      },
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    slotSettingFilter: 'EXCLUDE',
  },
  {
    label: 'INVALID',
    show: true,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
          exclusion_status: true,
          content_found_amended: true,
          'issues.0.first_found_on': true,
          'wms_status.changed_at': false,
        },
        orderedFields: [
          'slot_label',
          'exclusion_status',
          'issues.0.type',
          'content_found_amended',
          'wms_status.state',
          'verity_status.state',
          'wasFoundAt',
          'shouldBeAt',
          'verity_status.collected_at',
          'issues.0.first_found_on',
          'wms_status.customers.0',
          'location_label',
          'issues.0.state',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'wms_status.changed_at',
          'expected_barcodes',
          'found_barcodes',
          'missing_barcodes',
          'missing_barcodes_rate',
          'unexpected_barcodes',
          'unexpected_barcodes_rate',
          'matching_barcodes',
          'matching_barcodes_rate',
          'collection_delay',
        ],
      },
      sorting: {
        sortModel: [{ field: 'verity_status.collected_at', sort: 'desc' }],
      },
    },
    issueTypeFilters: [ISSUE_TYPES.INCONCLUSIVE_WMS_INVALID],
    issueStateFilters: [IIssueSTStateEnum.New, IIssueSTStateEnum.Known],
  },
  {
    label: 'LABELED',
    show: areLabelsShown,
    gridState: {
      ...initialGridState,
      columns: {
        columnVisibilityModel: {
          ...initialGridState.columns?.columnVisibilityModel,
        },
        orderedFields: [
          'slot_label',
          'location_label',
          'issues.0.type',
          'wms_status.state',
          'verity_status.state',
          'wasFoundAt',
          'shouldBeAt',
          'verity_status.collected_at',
          'wms_status.changed_at',
          'issues.0.state',
          'wms_status.customers.0',
          'exclusion_status',
          'issues.0.first_found_on',
          'wms_status.article_nos.0',
          'wms_status.qtys.0',
          'content_found_amended',
          'expected_barcodes',
          'found_barcodes',
          'missing_barcodes',
          'missing_barcodes_rate',
          'unexpected_barcodes',
          'unexpected_barcodes_rate',
          'matching_barcodes',
          'matching_barcodes_rate',
          'collection_delay',
        ],
      },
      sorting: {
        sortModel: [{ field: 'location_label', sort: 'asc' }],
      },
    },
    filter: (locationData: ILocationDataST) =>
      !!locationData.location_label &&
      (locationData.location_label as ILocationLabelST).status !== ILabelStatusST.Archived,
  },
];
