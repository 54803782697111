import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useLocationModalStyle = makeStyles()((theme: Theme) => ({
  dialog: {
    minWidth: '96%',
    margin: theme.spacing(3),
    backgroundColor: 'transparent',
    '& .MuiDialog-paper': {
      backgroundColor: theme.palette.offWhite.main,
      borderRadius: theme.spacing(1),
    },
  },
  dialogTitle: {
    padding: 0,
  },
  dialogContent: {
    padding: theme.spacing(0, 8),
  },
  container: {
    marginTop: 0,
    marginBottom: theme.spacing(2),
  },
  barcodesContainer: {
    overflowX: 'auto',
  },
  barcodesContainerItem: {
    minWidth: 1100,
  },
}));
