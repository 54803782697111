import { Typography } from '@mui/material';
import { pageHeaderStyles } from '../styles';

export const PageHeaderSectionTitle = ({
  title,
  currentFacilityName,
  isMultiFacility,
}: {
  title: string;
  currentFacilityName: string;
  isMultiFacility: boolean;
}) => {
  const { classes } = pageHeaderStyles();

  return (
    <Typography
      data-testid="c-page-header-title"
      className={classes.bold}
      color="secondary"
      variant="h1"
      lineHeight="inherit"
      fontSize="2.125rem"
    >
      {isMultiFacility && `${currentFacilityName} - `}
      {title}
    </Typography>
  );
};
