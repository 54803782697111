import { IGroupDetailST, IGroupGetAllResponseST } from 'codegen/user_admin';
import { IOption } from 'interfaces/inputs';

export const transformUserGroupsToSelectOptions: (
  userGroups: IGroupGetAllResponseST,
) => IOption[] = (userGroups) =>
  userGroups.groups.map((group: IGroupDetailST) => ({
    value: group.id,
    label: group.display_name,
  }));
