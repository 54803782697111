import { Popover, Tooltip, Typography, useTheme } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Badge } from 'components/common/Badge';
import ClearIcon from '@mui/icons-material/Clear';
import { ISSUE_BADGE_COLORS } from 'common/colors';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { DISPLAY_ISSUE_TYPES } from 'common/issueTypesAndStates';
import {
  getIssueType,
  MuiColor,
  muiColorToTheme,
} from 'common/functions/issues/issueColorFunctions';
import { Dispatch, SetStateAction, useState } from 'react';
import { Box } from 'components/common/Box';
import { IFacilitySettingsST, IRuleActionSTIssueLogicEnum } from 'codegen/facility_settings';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { ContentCopy, Done } from '@mui/icons-material';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import { NavigationButtons } from './navigation/NavigationButtons';
import { ThreeDotsMenu } from './ThreeDotsMenu';
import { LocationImagesState } from '../../hooks/useLocationImagesState';
import { LocationDiagnostics } from './diagnostics/LocationDiagnostics';
import { IconButtonSquare } from '../square-icon-button/IconButtonSquare';
import { LocationModalHelp } from '../help/LocationModalHelp';
import { locationModalHeaderStyles } from './LocationModalHeader.styles';

export const LocationModalHeader = ({
  locationData,
  filteredLocationList,
  refreshLocationData,
  setCurrentLocationIndex,
  enableSnooze,
  facilitySettings,
  locationImagesState,
  issueLogic,
  handleCloseModal,
}: {
  locationData: ILocationData;
  filteredLocationList: ILocationData[];
  refreshLocationData: (locationLabel: string) => void;
  setCurrentLocationIndex: Dispatch<SetStateAction<number>>;
  enableSnooze: boolean;
  facilitySettings: IFacilitySettingsST;
  locationImagesState: LocationImagesState;
  issueLogic?: IRuleActionSTIssueLogicEnum;
  handleCloseModal: () => void;
}) => {
  const { classes, cx } = locationModalHeaderStyles();
  const theme = useTheme();

  const [showCopySuccessTimer, setShowCopySuccessTimer] = useState<NodeJS.Timeout | null>(null);

  const showNavigation = filteredLocationList.length > 1;

  const title = locationData.location;
  const issue =
    locationData.issueType && DISPLAY_ISSUE_TYPES[locationData.issueType]
      ? DISPLAY_ISSUE_TYPES[locationData.issueType]
      : '';
  const issueType = locationData.issueType
    ? getIssueType(locationData.issueType)
    : {
        label: 'None',
        color: 'default' as MuiColor,
      };

  const [diagAnchorEl, setDiagAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [helpAnchorEl, setHelpAnchorEl] = useState<HTMLButtonElement | null>(null);

  const showHelp = (event: React.MouseEvent<HTMLButtonElement>) => {
    setHelpAnchorEl(event.currentTarget);
  };

  const canSeeDiag = userHasPermission(PERMISSION.VIEW_LOCATION_DIAGNOSTICS);

  const showDiag = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (!canSeeDiag) {
      return;
    }
    setDiagAnchorEl(event.currentTarget);
  };

  const handleOnClickCopyLocation = async () => {
    await navigator.clipboard.writeText(title);
    if (showCopySuccessTimer) {
      clearTimeout(showCopySuccessTimer);
    }
    const timer = setTimeout(() => setShowCopySuccessTimer(null), 2 * 1000);
    setShowCopySuccessTimer(timer);
  };

  return (
    <>
      <div className={classes.container}>
        <Box display="flex" alignItems="center" gap={2}>
          <Tooltip
            title="Location number"
            slotProps={{
              popper: {
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -4],
                    },
                  },
                ],
              },
            }}
          >
            <Typography
              className={classes.modalTitle}
              color="secondary"
              variant="h5"
              data-testid="title"
              minWidth="90px"
            >
              {title}
            </Typography>
          </Tooltip>

          <IconButtonSquare aria-label="Copy location" onClick={handleOnClickCopyLocation}>
            <Tooltip
              title={showCopySuccessTimer ? 'Location copied to clipboard' : 'Copy location number'}
            >
              {showCopySuccessTimer ? <Done /> : <ContentCopy />}
            </Tooltip>
          </IconButtonSquare>

          {issue && (
            <Badge
              className={cx(
                classes.diagnosticsBadge,
                canSeeDiag ? classes.canSeeDiagnosticsCursor : classes.canNotSeeDiagnosticsCursor,
              )}
              bgcolor={muiColorToTheme(issueType.color, theme).main}
              color={muiColorToTheme(issueType.color, theme).text}
              bigBadge={false}
            >
              <Tooltip
                title={canSeeDiag ? 'Click to see the diagnostics information' : 'Location issue'}
              >
                <Typography variant="body1" onClick={showDiag}>
                  {issue}
                </Typography>
              </Tooltip>
            </Badge>
          )}
          <Badge
            bgcolor={ISSUE_BADGE_COLORS[locationData?.state ?? 'NONE']?.background}
            color={ISSUE_BADGE_COLORS[locationData?.state ?? 'NONE']?.text}
            bigBadge={false}
            className={cx(
              classes.diagnosticsBadge,
              canSeeDiag ? classes.canSeeDiagnosticsCursor : classes.canNotSeeDiagnosticsCursor,
            )}
          >
            <Tooltip
              title={canSeeDiag ? 'Click to see the diagnostics information' : 'Issue status'}
            >
              <Typography variant="body1" onClick={showDiag}>
                {(locationData?.state && `${locationData.state} ISSUE`) || 'NO ISSUE'}
              </Typography>
            </Tooltip>
          </Badge>

          <ThreeDotsMenu
            locationData={locationData}
            enableSnooze={enableSnooze}
            refreshLocationData={refreshLocationData}
            locationImagesState={locationImagesState}
            facilitySettings={facilitySettings}
          />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box display="flex" paddingRight={5} alignItems="center" gap={5}>
            <IconButtonSquare
              aria-label="refresh"
              onClick={() => refreshLocationData(locationData?.location)}
            >
              <Tooltip title="Refresh">
                <RefreshIcon />
              </Tooltip>
            </IconButtonSquare>
            {showNavigation && (
              <NavigationButtons
                locationsData={filteredLocationList}
                setCurrentLocationIndex={setCurrentLocationIndex}
              />
            )}
          </Box>

          <Box display="flex" alignItems="center" gap={1}>
            <IconButtonSquare aria-label="help" onClick={showHelp}>
              <Tooltip title="Click for location view keyboard shortcuts">
                <QuestionMarkIcon />
              </Tooltip>
            </IconButtonSquare>

            <IconButtonSquare aria-label="close" onClick={handleCloseModal}>
              <Tooltip title="Click to close the dialog">
                <ClearIcon />
              </Tooltip>
            </IconButtonSquare>
          </Box>
        </Box>
      </div>

      <Popover onClose={() => setDiagAnchorEl(null)} anchorEl={diagAnchorEl} open={!!diagAnchorEl}>
        <LocationDiagnostics
          locationData={locationData}
          issueLogic={issueLogic}
          onClose={() => setDiagAnchorEl(null)}
        />
      </Popover>

      <Popover onClose={() => setHelpAnchorEl(null)} anchorEl={helpAnchorEl} open={!!helpAnchorEl}>
        <LocationModalHelp onClose={() => setHelpAnchorEl(null)} />
      </Popover>
    </>
  );
};
