import { makeStyles } from 'tss-react/mui';
import { Theme, toggleButtonClasses } from '@mui/material';

export const useWarehouseStatus3DStyle = makeStyles()((theme: Theme) => ({
  pageWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
    height: '100%',
  },
  card: {
    height: '100%',
    position: 'relative',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  cardHeader: {
    flex: '0 0 auto',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  wrapper: {
    flex: '1 1 auto',
    minHeight: 0,
    position: 'relative',
    height: '100%',
    transform: 'translateZ(0)', // create new stacking context for drawer
  },
  canvas: {
    minHeight: '30vh',
    borderRadius: 2 * theme.shape.borderRadius,
    border: `1px solid ${theme.palette.divider}`,
  },
  aisleSummary: {
    position: 'absolute',
    left: theme.spacing(3),
    bottom: theme.spacing(3),
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      flexWrap: 'wrap',
    },
  },
  viewToggle: {
    backgroundColor: theme.palette.background.paper,
    position: 'absolute',
    top: theme.spacing(2),
    left: theme.spacing(3),
    zIndex: 1,
    [`& .${toggleButtonClasses.selected}`]: {
      // Both importants neede because of the specificity of the MUI classes [TC] 14.11.2024
      color: `${theme.palette.primary.main} !important`,
      backgroundColor: `${theme.palette.primary.light} !important`,
    },
  },
  zoomControls: {
    position: 'absolute',
    right: theme.spacing(3),
    bottom: theme.spacing(3),
  },
  drawerPaper: {
    height: '100%',
  },
}));
