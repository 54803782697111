import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HEADER_HEIGHT, HEADER_USER_MENU_WIDTH } from './defaults/Header.defaults';

export const headerStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1),
    gap: theme.spacing(2),
    position: 'fixed',
    top: 0,
    width: '100%',
    height: HEADER_HEIGHT,
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    boxShadow: '0px 0px 12px -2px rgba(0, 0, 0, 0.35), 0px 0px 18px -6px rgba(0, 0, 0, .65)',
  },
  clientLogo: {
    flex: '0 0 auto',
    height: '100%',
    display: 'block',
    objectFit: 'cover',
  },
  sectionClient: {
    height: '100%',
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  sectionLinks: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  userOptionsDrawer: {
    width: HEADER_USER_MENU_WIDTH,
    flexShrink: 0,
    zIndex: 1301,
  },
  drawerPaper: {
    width: HEADER_USER_MENU_WIDTH,
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    justifyContent: 'space-between',
  },
  title: {
    lineHeight: 1,
    fontWeight: 700,
    fontSize: '1.2em',
  },
  menuOption: {
    display: 'block',
    color: 'inherit',
    font: 'inherit',
    background: 'none',
    border: 0,
    cursor: 'pointer',
    textDecoration: 'none',
    textTransform: 'uppercase',
    fontSize: '14px',
    letterSpacing: '0.1em',
    padding: '4px 8px',
    borderRadius: '6px',
    transition: 'background-color 220ms',

    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },

    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.1)',
    },
  },
  avatar: {
    backgroundColor: theme.palette.primary.main,
  },
  avatarPrimary: {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.primary.contrastText,
  },
}));
