import { Typography, TextField, Autocomplete, createFilterOptions } from '@mui/material';
import { IWmsSimulatorPostRequestParamsInnerST } from 'codegen/dev_tools';
import { useSlots } from 'hooks';
import { Dispatch, SetStateAction } from 'react';

export const WmsSimulatorLocationLabelInput = ({
  systemId,
  locationlabel,
  index,
  wmsData,
  setWmsData,
}: {
  systemId: string;
  locationlabel: string;
  index: number;
  wmsData: IWmsSimulatorPostRequestParamsInnerST[];
  setWmsData: Dispatch<SetStateAction<IWmsSimulatorPostRequestParamsInnerST[]>>;
}) => {
  const { data: slots } = useSlots(systemId);

  const handleLocationLabelChange = (index: number, value: string) => {
    const updatedData = [...wmsData];
    updatedData[index].location_label = value;
    setWmsData(updatedData);
  };

  const filterOptions = createFilterOptions({
    ignoreCase: true,
    matchFrom: 'start',
    limit: 5,
  });

  return (
    <>
      <Typography variant="subtitle2">Location Label</Typography>
      <Autocomplete
        freeSolo
        options={slots ?? ['']}
        inputValue={locationlabel}
        onInputChange={(e, newValue) => handleLocationLabelChange(index, newValue)}
        filterOptions={filterOptions}
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            size="small"
            placeholder="Enter location label"
            error={slots && !slots.includes(locationlabel)}
          />
        )}
      />
    </>
  );
};
