import { Line } from '@react-three/drei';
import { FC } from 'react';

export const OutlineLines: FC<{
  color: string;
  lineWidth: number;
  scale: { x: number; y: number };
  dashedProps: { dashed?: boolean; dashSpacing?: number };
}> = ({ color, lineWidth, scale, dashedProps }) => {
  let dashPropsX = {};
  let dashPropsY = {};

  if (dashedProps.dashed) {
    const dashSpacing = dashedProps.dashSpacing ?? 0.2;
    const normalizedSpacingX = dashSpacing / scale.x;
    const normalizedSpacingY = dashSpacing / scale.y;
    dashPropsX = {
      dashed: true,
      dashSize: normalizedSpacingX,
      gapSize: normalizedSpacingX,
    };
    dashPropsY = {
      dashed: true,
      dashSize: normalizedSpacingY,
      gapSize: normalizedSpacingY,
    };
  }

  const normalizedLineWidthX = lineWidth / scale.x;
  const normalizedLineWidthY = lineWidth / scale.y;

  return (
    <>
      <Line
        points={[
          [0.5 - normalizedLineWidthX / 2, -0.5 + normalizedLineWidthY / 2, 0],
          [0.5 - normalizedLineWidthX / 2, 0.5 - normalizedLineWidthY / 2, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsY}
      />
      <Line
        points={[
          [0.5 - normalizedLineWidthX / 2, 0.5 - normalizedLineWidthY / 2, 0],
          [-0.5 + normalizedLineWidthX / 2, 0.5 - normalizedLineWidthY / 2, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsX}
      />
      <Line
        points={[
          [-0.5 + normalizedLineWidthX / 2, 0.5 - normalizedLineWidthY / 2, 0],
          [-0.5 + normalizedLineWidthX / 2, -0.5 + normalizedLineWidthY / 2, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsY}
      />
      <Line
        points={[
          [-0.5 + normalizedLineWidthX / 2, -0.5 + normalizedLineWidthY / 2, 0],
          [0.5 - normalizedLineWidthX / 2, -0.5 + normalizedLineWidthY / 2, 0],
        ]}
        color={color}
        lineWidth={lineWidth}
        worldUnits
        {...dashPropsX}
      />
    </>
  );
};
