import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useLocationModalHelpStyles = makeStyles()((theme: Theme) => ({
  container: {
    padding: theme.spacing(2, 2, 4, 4),
    width: 448,
    maxWidth: '100%',
  },
}));
