import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  zone: {
    position: 'absolute',
    insetInlineStart: '10px',
    insetInlineEnd: '10px',
    font: 'inherit',
    textAlign: 'start',
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    justifyContent: 'space-between',
    paddingBlock: '0px',
    paddingInline: theme.spacing(1),
    fontWeight: 700,
    gap: theme.spacing(1),
    lineHeight: 1,
    cursor: 'pointer',
    outline: 0,
    maxWidth: `calc(100% - ${theme.spacing(2)})`,
  },
  zoneDetailsName: {
    display: 'block',
    lineHeight: 'inherit',
    flex: '1 1 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  zoneDetailsHeight: {
    display: 'block',
    lineHeight: 'inherit',
    flex: '0 0 auto',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
}));
