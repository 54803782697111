import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const controlledZoneListStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    position: 'relative',
    height: 'calc(100vh - 370px)', // MAGIC NUMBERS, RIP.
  },
  wrapperBody: {
    position: 'relative',
    display: 'grid',
    height: '100%',
    gridTemplateColumns: 'minmax(0, 1fr)',
    rowGap: theme.spacing(1),
  },
  zoneListWrapper: {
    overflowY: 'auto',
    alignItems: 'start',
    gridAutoRows: 'min-content',
    margin: 0,
    padding: 0,

    '&::-webkit-scrollbar': {
      WebkitAppearance: 'none',
      width: 10,
    },

    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'rgba(0, 0, 0, .5)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}));
