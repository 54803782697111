import { useStyles } from './pageLayoutFixedHeight.styles';
import { PageSectionProps, PageSectionSectionProps } from './pageLayoutFixedHeight.model';

const PageLayoutFixedHeightBase = ({ children, ...props }: PageSectionProps) => {
  const { cx, classes } = useStyles();

  return (
    <div {...props} className={cx(classes.wrapper)}>
      {children}
    </div>
  );
};

PageLayoutFixedHeightBase.displayName = 'PageLayoutFixedHeight';

const PageLayoutFixedHeightHeader = ({
  children,
  fullWidth,
  ...props
}: PageSectionSectionProps) => {
  const { cx, classes } = useStyles();

  return (
    <div
      {...props}
      className={cx(classes.section, classes.sectionHeader, {
        [classes.sectionFullWidth]: fullWidth,
      })}
    >
      {children}
    </div>
  );
};

PageLayoutFixedHeightHeader.displayName = 'PageLayoutFixedHeight.Header';

const PageLayoutFixedHeightBody = ({ children, fullWidth, ...props }: PageSectionSectionProps) => {
  const { cx, classes } = useStyles();

  return (
    <div
      {...props}
      className={cx(classes.section, {
        [classes.sectionFullWidth]: fullWidth,
      })}
    >
      {children}
    </div>
  );
};

PageLayoutFixedHeightBody.displayName = 'PageLayoutFixedHeight.Body';

export const PageLayoutFixedHeight = Object.assign(PageLayoutFixedHeightBase, {
  Header: PageLayoutFixedHeightHeader,
  Body: PageLayoutFixedHeightBody,
});
