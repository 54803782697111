import { forwardRef } from 'react';
import { NavLink } from 'react-router-dom';
import { FacilityMenuLinkProps } from './facilityMenuLink.model';
import { useStyles } from './facilityMenuLink.styles';

export const FacilityMenuLink = forwardRef<HTMLAnchorElement, FacilityMenuLinkProps>(
  ({ nested, variant = 'standard', isCollapsed, ...props }, ref) => {
    const { cx, classes } = useStyles();

    return (
      <NavLink
        {...props}
        ref={ref}
        className={({ isActive }) =>
          cx(
            classes.wrapper,
            nested && !isCollapsed && classes.nested,
            isActive && classes.active,
            isActive && classes[variant],
            isCollapsed && classes.collapsed,
          )
        }
      />
    );
  },
);
