import { useState } from 'react';
import {
  IWmsSimulatorPostRequestParamsInnerST,
  IWmsSimulatorPostRequestST,
} from 'codegen/dev_tools';
import { Button, Card, CardContent, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMutation } from '@tanstack/react-query';
import { Box } from 'components/common/Box';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { MutationNames } from 'ts-types/MutationNames';
import { sendWmsSimulatorUpdate } from '../api/sendWmsSimulatorUpdate';
import { useWmsDataSimulatorStyles } from '../styles/WmsDataSimulator.styles';
import { WmsSimulatorDateSelector } from './WmsSimulatorDateSelector';
import { WmsSimulatorBarcodeSelector } from './WmsSimulatorBarcodeSelector';
import { WmsSimulatorExpectedStateSelector } from './WmsSimulatorExpectedStateSelector';
import { WmsSimulatorLocationLabelInput } from './WmsSimulatorLocationLabelInput';

interface WmsDataSimulatorProps {
  systemId: string;
  addToOutput: (messages: string[]) => void;
}

export const WmsDataSimulator = (props: WmsDataSimulatorProps) => {
  const { systemId, addToOutput } = props;

  const { classes } = useWmsDataSimulatorStyles();

  const { facilityData } = useFacilityLevelStore().stateFacilityLevel;

  const [wmsData, setWmsData] = useState<IWmsSimulatorPostRequestParamsInnerST[]>([]);

  const { mutate: sendWmsSimulatorUpdateMutate } = useMutation({
    mutationKey: [MutationNames.SEND_WMS_SIMULATOR_UPDATE],
    mutationFn: ({
      wmsSimulatorPostRequest,
    }: {
      wmsSimulatorPostRequest: IWmsSimulatorPostRequestST;
    }) => sendWmsSimulatorUpdate(systemId, wmsSimulatorPostRequest),
    onSuccess: ({ data }) => {
      addToOutput(['::: WMS updated following locations:']);
      data.locations?.forEach((location) => {
        if (!data.skipped_locations?.includes(location.location ?? '')) {
          addToOutput([`   ${location.location}`]);
        }
      });
      addToOutput([':::']);
      if (data.skipped_locations && data.skipped_locations.length > 0) {
        addToOutput(['::: Skipped following locations:']);
        data.skipped_locations.forEach((location_label) => {
          addToOutput([`   ${location_label}`]);
        });
        addToOutput([':::']);
      }
    },
    onError: (error: Error) => {
      addToOutput(['::ERROR: WMS update failed', error.message]);
    },
  });

  const applyWmsEditsOnClick = () => {
    const wmsSimulatorPostRequest: IWmsSimulatorPostRequestST = {
      params: wmsData.filter((item) => item.location_label),
    };

    sendWmsSimulatorUpdateMutate({ wmsSimulatorPostRequest });
  };

  const addWmsLocation = () => {
    setWmsData([
      ...wmsData,
      {
        location_label: '',
        expected_state: 'BARCODE',
        number_of_barcodes: 1,
        barcodes: [],
      },
    ]);
  };

  const removeWmsLocation = (index: number) => {
    const updatedData = wmsData.filter((_, i) => i !== index);
    setWmsData(updatedData);
  };

  return (
    <div className={classes.wrapper}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <h5> Simulating data for: {facilityData?.name || `System ID: ${systemId}`} </h5>
        <Button
          size="large"
          variant="contained"
          onClick={() => applyWmsEditsOnClick()}
          className={classes.applyEditsButton}
        >
          Apply Edits
        </Button>
      </Box>
      <div className={classes.cardsContainer}>
        <Grid container spacing={1}>
          {wmsData.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Grid item xs={12} key={index}>
              <Card className={classes.card}>
                <IconButton
                  className={classes.cardCloseIcon}
                  onClick={() => removeWmsLocation(index)}
                  size="small"
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
                <CardContent>
                  <Grid item xs={12} sm={6} md={3}>
                    <WmsSimulatorLocationLabelInput
                      systemId={systemId}
                      locationlabel={item.location_label ?? ''}
                      index={index}
                      wmsData={wmsData}
                      setWmsData={setWmsData}
                    />
                  </Grid>
                  <Grid container spacing={1} alignItems="center">
                    <Grid item xs={12} sm={6} md={3}>
                      <WmsSimulatorExpectedStateSelector
                        index={index}
                        wmsData={wmsData}
                        setWmsData={setWmsData}
                        expectedState={item.expected_state ?? 'BARCODE'}
                      />
                    </Grid>

                    {item.expected_state === 'BARCODE' && (
                      <WmsSimulatorBarcodeSelector
                        index={index}
                        wmsData={wmsData}
                        setWmsData={setWmsData}
                        barcodes={item.barcodes}
                        numberOfBarcodes={item.number_of_barcodes}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={3}>
                    <WmsSimulatorDateSelector
                      item={item}
                      index={index}
                      wmsData={wmsData}
                      setWmsData={setWmsData}
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
        <Button
          variant="outlined"
          color="primary"
          onClick={addWmsLocation}
          className={classes.addBtn}
        >
          Add WMS Location
        </Button>
      </div>
    </div>
  );
};
