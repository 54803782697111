import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { HEADER_HEIGHT } from 'components/Header/defaults/Header.defaults';

const TABLE_UI_OFFSET = '80px';

export const useStyles = makeStyles()((theme: Theme) => ({
  sectionColumns: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'minmax(0px, 1fr)',
    justifyContent: 'start',

    '@container (min-inline-size: 700px)': {
      gridTemplateColumns: 'repeat(auto-fit,  minmax(300px, 1fr))',
    },

    '@container (min-inline-size: 1300px)': {
      gridTemplateColumns: 'repeat(auto-fill, repeat(3, minmax(0, 1fr))',
    },
  },
  cardBody: {
    height: `calc(100vh - ${HEADER_HEIGHT}px - ${TABLE_UI_OFFSET})`,
    minHeight: '500px',
  },
}));
