import { PageHeader } from 'components/common/page-header/PageHeader';
import { AlertComponent } from 'components/common/Alert';
import { userHasPermission } from '../../../../features/permissions/userHasPermission';
import { PERMISSION } from '../../../../features/permissions/permissions.model';
import { useGroundControlStore } from '../../../../store/GroundControl/groundControlLevelStore';
import { noFlyZoneAlertState } from './NoFlyZoneHeader/NoFlyZoneAlertState';
import { useStyles } from './droneZoneHeader.styles';

export const DroneZoneHeader = ({ systemId }: { systemId: string }) => {
  const { cx, classes } = useStyles();

  const { stateGroundControl } = useGroundControlStore();

  const isStatusChangeEnabled: boolean =
    userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) ||
    stateGroundControl.flightDomain.flight_domain_status?.locked ||
    (stateGroundControl.flightDomain.fleet_status_summary?.num_drones_flying || -1) < 0;

  return (
    <div className={cx(classes.bannerWrapper)}>
      <PageHeader
        systemId={systemId}
        title="Drone zones"
        subtitle="Create, control and preview drone zones"
      />

      {noFlyZoneAlertState(stateGroundControl, isStatusChangeEnabled).state.show && (
        <AlertComponent
          isActive
          {...noFlyZoneAlertState(stateGroundControl, isStatusChangeEnabled).state}
        />
      )}
    </div>
  );
};
