import {
  getReqParamUserFacingName,
  getReqParamUserFacingValues,
} from 'common/functions/requestParameterFunctions';
import { IInventoryRequestST } from 'codegen/inventory_request';

export const getRequestParams = (request: IInventoryRequestST | undefined) => {
  if (!request) {
    return [];
  }
  let requestParams: {
    label: string;
    value: string;
  }[] = [];

  request.params.forEach((param) => {
    const labelString = getReqParamUserFacingName(param, request) || '';
    const valueString = getReqParamUserFacingValues(param, request);

    // We ignore request params that have no values or aliases for their values
    // for example PALLET_LOCATION, on an Empty-Locations inventory request
    if (valueString !== '') {
      requestParams = [
        ...requestParams,
        {
          label: labelString,
          value: valueString,
        },
      ];
    }
  });

  return requestParams;
};
