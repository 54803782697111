import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { GridLogicOperator } from '@mui/x-data-grid-premium';
import {
  ChangeEvent,
  Dispatch,
  MutableRefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';
import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { onSaveCustomGrid } from 'udb/inventory/features/premium-grid/utils/onSaveCustomGrid.util';
import { onLoadCustomGrid } from 'udb/inventory/features/premium-grid/utils/onLoadCustomGrid.util';
import { onRemoveCustomGrid } from 'udb/inventory/features/premium-grid/utils/onRemoveCustomGrid.util';
import { SearchField } from 'udb/inventory/features/premium-grid/features/search-field/SearchField';
import { calculateTabCounts } from 'udb/inventory/features/premium-grid/utils/calculateTabCounts';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { isGridStateSavedInLocalStorage } from 'udb/inventory/features/premium-grid/utils/isGridStateSavedInLocalStorage.utils';
import { usePrevious } from 'hooks/usePrevious';
import { TabObject } from 'udb/inventory/features/premium-grid/models/TabObject.model';
import { useWarehouseStatusTableHeaderStyles } from './useWarehouseStatusTableHeader.styles';
import { searchFieldColumns } from '../../../models/searchFieldColumns.model';
import { WarehouseStatusTabs } from '../../tabs/WarehouseStatusTabs';
import { useLocationData } from '../../../hooks/useLocationData';
import { initialGridState } from '../../../defaults/initialGridState.default';

const ACTIVE_TAB_KEY = 'activeTab';

export const WarehouseStatusTableHeader = ({
  activeTabIndex,
  setActiveTabIndex,
  gridApiRef,
  gridState,
  warehouseStatusTabs,
}: {
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  gridApiRef: MutableRefObject<GridApiPremium>;
  gridState?: GridInitialStatePremium;
  warehouseStatusTabs: TabObject[];
}) => {
  const { cx, classes } = useWarehouseStatusTableHeaderStyles();

  const { systemId = '' } = useParams();
  const { locationData } = useLocationData(systemId);

  const [searchParams, setSearchParams] = useSearchParams();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilitySettings } = stateFacilityLevel;

  const [isGridStateSavedForTab, setIsGridStateSavedForTab] = useState(
    isGridStateSavedInLocalStorage({ gridName: 'warehouse-status', activeTab: activeTabIndex }),
  );

  const rowCount = locationData.length;

  const showAmended =
    userHasPermission(PERMISSION.AMEND_REPORT) && facilitySettings.allow_user_amend;

  const [tabCounts, setTabCounts] = useState<{
    [key: string]: number;
  }>({});

  useEffect(() => {
    setTabCounts(
      calculateTabCounts({
        locationDataList: locationData,
        countAmended: showAmended,
        countLabeled: !!facilitySettings.show_location_labels,
      }),
    );
  }, [facilitySettings.show_location_labels, locationData, rowCount, showAmended]);

  const handleTabChange = useCallback(
    (_event: ChangeEvent<HTMLInputElement>, newValue: number) => {
      setActiveTabIndex(newValue);

      setIsGridStateSavedForTab(
        isGridStateSavedInLocalStorage({ gridName: 'warehouse-status', activeTab: newValue }),
      );

      const { gridState } = warehouseStatusTabs.filter((tab) => tab.show)[newValue];
      gridApiRef.current.restoreState({ ...gridState });

      searchParams.set(ACTIVE_TAB_KEY, newValue.toString());
      setSearchParams(searchParams);
    },
    [gridApiRef, searchParams, setSearchParams, warehouseStatusTabs, setActiveTabIndex],
  );

  // NOTE: this is used later in a useEffect to allow additional changes in the filters, columns, etc.
  // while a gridState prop is passed (e.g. when clicking one of the header's chart)
  // eno: 2024-09-02
  const previousGridState = usePrevious(gridState) as GridInitialStatePremium | undefined;

  useEffect(() => {
    // NOTE: the latter part of the if statement avoids to reapply the grid state
    // passed as a prop when the table filtering/columns/etc. has been altered manually
    // eno: 2024-09-02
    if (gridState && gridState !== previousGridState && gridApiRef.current) {
      handleTabChange(null as unknown as ChangeEvent<HTMLInputElement>, 0);
      const { gridState: fullTableState } = warehouseStatusTabs[0];
      gridApiRef.current.restoreState({ ...fullTableState, ...gridState });
    } else if (activeTabIndex.toString() !== searchParams.get(ACTIVE_TAB_KEY)) {
      // NOTE: we trigger this handleTabChange when this gets changed in the url by
      // navigate, e.g.: when clicking the link to the labeled tab in the Location Modal
      // eno: 2024-10-18 - see also https://verity-ag.atlassian.net/browse/PI-1357
      const activeTabFromUrl = searchParams.get(ACTIVE_TAB_KEY);
      if (activeTabFromUrl) {
        const newActiveTab = parseInt(activeTabFromUrl, 10);
        handleTabChange(null as unknown as ChangeEvent<HTMLInputElement>, newActiveTab);
      }
    }
  }, [
    activeTabIndex,
    gridApiRef,
    gridState,
    handleTabChange,
    previousGridState,
    searchParams,
    warehouseStatusTabs,
  ]);

  return (
    <div className={classes.cardHeader}>
      <WarehouseStatusTabs
        tabs={warehouseStatusTabs}
        activeTabIndex={activeTabIndex}
        handleTabChange={handleTabChange}
        tabCounts={tabCounts}
      />

      <div className={cx(classes.cardHeaderSection, classes.cardHeaderActions)}>
        <GridPremiumToolbarButton
          name="SAVE VIEW"
          tooltip="Save View"
          onClick={() => {
            onSaveCustomGrid({
              gridName: 'warehouse-status',
              activeTab: activeTabIndex,
              gridApiRef,
            });
            setIsGridStateSavedForTab(true);
          }}
          icon={<BookmarkAddIcon />}
        />

        <GridPremiumToolbarButton
          name="LOAD VIEW"
          tooltip={
            isGridStateSavedForTab
              ? 'Click to load a view for this tab'
              : 'There are no saved views for this tab'
          }
          disabled={!isGridStateSavedForTab}
          onClick={() =>
            onLoadCustomGrid({
              gridName: 'warehouse-status',
              activeTab: activeTabIndex,
              initialGridState,
              gridApiRef,
            })
          }
          icon={<BookmarkIcon />}
        />

        {isGridStateSavedForTab && (
          <GridPremiumToolbarButton
            name="REMOVE VIEW"
            tooltip="Remove view (revert back to default)"
            onClick={() => {
              onRemoveCustomGrid({ gridName: 'warehouse-status', activeTab: activeTabIndex });
              setIsGridStateSavedForTab(false);
            }}
            icon={<BookmarkRemoveIcon />}
          />
        )}
      </div>

      <div className={classes.cardHeaderSection}>
        <SearchField
          columns={searchFieldColumns}
          isDisabled={!gridApiRef.current}
          onChange={(items) =>
            gridApiRef.current.setFilterModel({
              items,
              logicOperator: GridLogicOperator.Or,
            })
          }
        />
      </div>
    </div>
  );
};
