import { useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { TabPanel } from 'components/common/Tabs/TabPanel';
import { Dashboard } from './Dashboard';
import { fetchGuestToken } from './functions/FetchGuestToken';
import { ISupersetSettings } from './functions/GetSupersetSettings';

interface MultiDashboardAnalyticsProps {
  supersetSettings: ISupersetSettings;
  fetchSystemSpecificSuperSetGuestToken: () => Promise<string>;
}

/**
 * Multi dashboard analytics
 * @param props MultiDashboardAnalyticsProps
 * @returns component
 */
export const MultiDashboardAnalytics = ({
  supersetSettings,
  fetchSystemSpecificSuperSetGuestToken,
}: MultiDashboardAnalyticsProps) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event: React.SyntheticEvent, activeTab: number) => setActiveTab(activeTab);

  return (
    <>
      <Tabs value={activeTab} onChange={handleChange} aria-label="basic tabs example">
        <Tab label="Current Facility" />
        <Tab label="Facility Comparison" />
      </Tabs>

      {supersetSettings && (
        <>
          <TabPanel value={activeTab} index={0}>
            <Dashboard
              dashboardId={supersetSettings.facility_dashboard_id}
              supersetDomain={supersetSettings.base_url}
              fetchGuestToken={fetchSystemSpecificSuperSetGuestToken}
            />
          </TabPanel>

          <TabPanel value={activeTab} index={1}>
            <Dashboard
              dashboardId={supersetSettings.client_dashboard_id}
              supersetDomain={supersetSettings.base_url}
              fetchGuestToken={fetchGuestToken}
            />
          </TabPanel>
        </>
      )}
    </>
  );
};
