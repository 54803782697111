import { Suspense } from 'react';
import { Navigate } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { RemotePickingLocations } from 'picking-locations/features/remote-picking-locations/RemotePickingLocations';
import { ErrorPlaceholder } from 'picking-locations/features/error-placeholder/ErrorPlaceholder';
import { LoadingPlaceholder } from 'picking-locations/features/loading-placeholder/LoadingPlaceholder';

export const RemotePickingLocationsRoute = () => {
  const { stateFacilityLevel } = useFacilityLevelStore();

  const isPickingLocationsEnabled = stateFacilityLevel?.facilitySettings?.enable_counting;

  return isPickingLocationsEnabled ? (
    <ErrorBoundary
      fallback={
        <PageLayout>
          <PageLayout.Section>
            <ErrorPlaceholder />
          </PageLayout.Section>
        </PageLayout>
      }
    >
      <Suspense
        fallback={
          <PageLayout>
            <PageLayout.Section>
              <LoadingPlaceholder />
            </PageLayout.Section>
          </PageLayout>
        }
      >
        <RemotePickingLocations />
      </Suspense>
    </ErrorBoundary>
  ) : (
    <Navigate to="/not-found" />
  );
};
