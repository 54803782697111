import Typography from '@mui/material/Typography';
import ListItemButton from '@mui/material/ListItemButton';
import { Box } from 'components/common/Box';
import { Facilities, Facility } from '../model/facility.model';
import { useStyles } from './facilitiesList.style';

export const FacilitiesList = ({
  facilities,
  onSelect,
  isListItemDisabled,
}: {
  facilities: Facilities;
  onSelect: (facility: Facility) => void;
  isListItemDisabled?: boolean;
}) => {
  const { classes } = useStyles();

  return (
    <Box
      sx={{
        maxHeight: '35rem',
        overflowY: facilities.length > 5 ? 'scroll' : 'auto',
      }}
    >
      {facilities.map((facility) => {
        const css = classes;

        return (
          <Box key={facility.id}>
            <ListItemButton
              data-testid={`c-facility-${facility.id}`}
              className={css.listItem}
              disabled={isListItemDisabled}
              divider
              onClick={() => onSelect(facility)}
            >
              <Box width="100%">
                <Typography data-testid="c-facility-title" className={css.title} variant="h6">
                  {facility.name ?? `Facility Id: ${facility.id}`}
                </Typography>
                <Typography
                  data-testid="c-facility-subtitle"
                  className={css.subHeader}
                  variant="subtitle1"
                  color="textSecondary"
                >
                  {facility.name ? '' : 'Not yet configured'}
                </Typography>
              </Box>
            </ListItemButton>
          </Box>
        );
      })}
    </Box>
  );
};
