import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import IconButton from '@mui/material/IconButton';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import ChevronLeftOutlinedIcon from '@mui/icons-material/ChevronLeftOutlined';
import ChevronRightOutlinedIcon from '@mui/icons-material/ChevronRightOutlined';
import MonitorIcon from '@mui/icons-material/Monitor';
import DvrIcon from '@mui/icons-material/Dvr';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import UploadIcon from '@mui/icons-material/Upload';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import WysiwygigIcon from '@mui/icons-material/Wysiwyg';
import PersonIcon from '@mui/icons-material/Person';
import CloudOffIcon from '@mui/icons-material/CloudOff';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import MapIcon from '@mui/icons-material/Map';
import {
  DELIVERY_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  GROUND_CONTROL_PAGES_URLS,
  INVENTORY_PAGES_URLS,
  WARNING_PAGES_URLS,
} from 'common/pages';
import { useGroundControlStore } from 'store/GroundControl/groundControlLevelStore';
import { useHideOnScroll } from 'hooks/useHideOnScroll';
import { Chip } from '@mui/material';
import { GroundControlSideIcon } from './ground-control-side-icon/GroundControlSideIcon';
import { FacilityDetailsIcon } from './Icons/FacilityDetailsIcon';
import { FacilitySettingsIcon } from './Icons/FacilitySettingsIcon';
import { DroneIcon } from './Icons/DroneIcon';
import { NoFlyZoneIcon } from './Icons/NoFlyZoneIcon';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { useClientLevelStore } from '../../../store/ClientLevelStore/clientLevelStore';
import { ClientModalsActionTypes } from '../../../store/Modals/types';
import { useClientModalsStore } from '../../../store/Modals';
import { useFacilityMenuStore } from '../../../store/FacilityMenuStore/facilityMenuStore';
import { FacilityMenuActionNames } from '../../../store/FacilityMenuStore/facilityMenuActions';
import { FacilityMenuItem } from './FacilityMenuItem';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { Drawer } from './StylizedComponents/Drawer';
import { DrawerHeader } from './StylizedComponents/DrawerHeader';
import { DrawerList } from './StylizedComponents/DrawerList';
import { ToggleWrapper } from './StylizedComponents/ToggleWrapper';
import type { MenuLink, MenuSection } from './facilityMenu.model';
import {
  userHasPermission,
  userHasSomePermissions,
} from '../../../features/permissions/userHasPermission';
import {
  overviewPagePermissions,
  PERMISSION,
  reportsPermissions,
  safetyPermissions,
} from '../../../features/permissions/permissions.model';
import { getFlightStatusMode } from './utils/get-flight-status-mode/getFlightStatusMode.util';

export const FacilityMenu = ({ systemId }: { systemId: string }) => {
  const location = useLocation();

  const theme = useTheme();

  const { stateGroundControl } = useGroundControlStore();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { stateClientLevel } = useClientLevelStore();
  const { stateFacilityMenu, dispatchFacilityMenu } = useFacilityMenuStore();
  const { dispatchClientModals } = useClientModalsStore();
  const { expandedItems } = stateFacilityMenu;
  const { facilityData, flightDomains } = stateFacilityLevel;

  const isScrollBeyondThreshold = useHideOnScroll({ threshold: 50 });

  const multipleFacilitiesAccess = stateClientLevel.isMultiFacility ?? false;
  const open = stateFacilityMenu.menuOpened;

  /**
   * True when the facility is configured and the data is available in the store
   */
  const isFacilityDataAvailable = useMemo(() => !isEmpty(facilityData), [facilityData]);

  const isAnalyticsMenuItemVisible =
    isFacilityDataAvailable &&
    stateFacilityLevel.facilitySettings?.enable_superset_integration &&
    userHasPermission(PERMISSION.VIEW_ANALYTICS);

  const inventoryLinks: MenuLink[] = useMemo(
    () => [
      {
        path: INVENTORY_PAGES_URLS.OVERVIEW,
        label: 'Overview',
        visible: isFacilityDataAvailable && userHasSomePermissions(overviewPagePermissions),
        testId: 'c-nav-link-home',
        end: true,
        icon: <MonitorIcon />,
      },
      {
        id: 'inventoryWarehouseStatus',
        path: INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X,
        label: 'Warehouse status',
        visible: isFacilityDataAvailable && userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS),
        testId: 'c-nav-link-warehouse-status',
        icon: <AssignmentTurnedInIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.WAREHOUSE_STATUS,
        label: 'Warehouse status',
        visible:
          stateFacilityLevel.facilitySettings?.show_warehouse_status_legacy &&
          isFacilityDataAvailable &&
          userHasPermission(PERMISSION.VIEW_WAREHOUSE_STATUS),
        testId: 'c-nav-link-warehouse-status-legacy',
        icon: <AssignmentTurnedInIcon />,
        statusLabel: <Chip size="small" label="Legacy" />,
      },
      {
        path: INVENTORY_PAGES_URLS.REPORTS,
        label: 'Reports',
        visible: isFacilityDataAvailable && userHasSomePermissions(reportsPermissions),
        testId: 'c-nav-link-reports',
        icon: <ListAltIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.ANALYTICS,
        label: 'Analytics',
        visible: isAnalyticsMenuItemVisible,
        testId: 'c-nav-link-analytics',
        icon: <AnalyticsIcon />,
      },
      {
        path: INVENTORY_PAGES_URLS.LOCATIONS_MANAGEMENT,
        label: 'Locations management',
        visible: isFacilityDataAvailable && userHasPermission(PERMISSION.VIEW_LOCATION_STATUS),
        testId: 'c-nav-link-locations-management',
        icon: <ViewCompactIcon />,
      },
    ],
    [
      isFacilityDataAvailable,
      stateFacilityLevel.facilitySettings?.show_warehouse_status_legacy,
      isAnalyticsMenuItemVisible,
    ],
  );

  const adminLinks: MenuLink[] = useMemo(
    () => [
      {
        label: 'System details',
        path: FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS,
        icon: <WysiwygigIcon />,
        visible: userHasPermission(PERMISSION.FACILITY_MANAGEMENT),
        testId: 'c-admin-link-system-details',
      },
      {
        label: 'Facility details',
        path: FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS,
        icon: <FacilityDetailsIcon />,
        visible: userHasPermission(PERMISSION.FACILITY_MANAGEMENT),
        testId: 'c-admin-link-facility-details',
      },
      {
        label: 'Facility settings',
        path: FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS,
        icon: <FacilitySettingsIcon />,
        visible: isFacilityDataAvailable && userHasPermission(PERMISSION.FACILITY_MANAGEMENT),
        testId: 'c-admin-link-facility-settings',
      },
      {
        label: 'Uploads',
        path: FACILITY_SETTINGS_PAGES_URLS.UPLOADS,
        icon: <UploadIcon />,
        visible:
          stateFacilityLevel.facilitySettings &&
          stateFacilityLevel.facilitySettings.allow_manual_wms_file_upload &&
          userHasPermission(PERMISSION.FACILITY_MANAGEMENT),
        testId: 'c-admin-link-uploads',
      },
      {
        label: 'Users',
        path: FACILITY_SETTINGS_PAGES_URLS.USERS,
        icon: <PersonIcon />,
        visible: userHasPermission(PERMISSION.USER_MANAGEMENT),
        testId: 'c-admin-link-users',
      },
    ],
    [isFacilityDataAvailable, stateFacilityLevel.facilitySettings],
  );

  const deliveryFlightDomainsMenuItems: MenuSection[] = useMemo(
    () =>
      flightDomains.map((flightDomain) => ({
        id: `delivery_flight_domain_${flightDomain.flight_domain_id}`,
        label: flightDomain.flight_domain_name,
        visible: true,
        testId: `delivery_flight_domain_${flightDomain.flight_domain_id}`,
        opened: expandedItems?.includes(`delivery_flight_domain_${flightDomain.flight_domain_id}`),
        items: [
          {
            id: `commissioning_task_${flightDomain.flight_domain_id}`,
            label: 'Commissioning',
            path: DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).COMMISSIONING,
            icon: <DvrIcon />,
            visible: true,
            testId: 'c-delivery-commissioning',
          },
          {
            id: `fleet_${flightDomain.flight_domain_id}`,
            label: 'Fleet',
            path: DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).FLEET,
            icon: <DroneIcon color="currentColor" />,
            visible: true,
            testId: 'c-delivery-fleet',
          },
          {
            id: `drone_zones_${flightDomain.flight_domain_id}`,
            label: 'Drone zones',
            path: DELIVERY_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES,
            icon: <MapIcon />,
            visible:
              userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT) &&
              stateFacilityLevel.facilitySettings?.show_coexistence_map,
            testId: 'c-delivery-drone-zones',
          },
        ],
      })),
    [expandedItems, flightDomains, stateFacilityLevel.facilitySettings?.show_coexistence_map],
  );

  const flightDomainsMenuItems: MenuSection[] = useMemo(
    () =>
      flightDomains &&
      flightDomains
        .sort((a, b) => (a.flight_domain_name <= b.flight_domain_name ? -1 : 1))
        .map((flightDomain) => {
          const flightStatusMode = getFlightStatusMode({
            locked: flightDomain.flight_domain_status?.locked || false,
            isExecutingEmergencyLanding: stateGroundControl.isExecutingEmergencyLanding,
            isExecutingReturnHome: stateGroundControl.isExecutingReturnHome,
          });

          const droneIconColor = 'currentColor';

          return {
            id: `flight_domain_${flightDomain.flight_domain_id}`,
            label: flightDomain.flight_domain_name,
            visible: true,
            testId: `flight_domain_${flightDomain.flight_domain_id}`,
            opened: expandedItems?.includes(`flight_domain_${flightDomain.flight_domain_id}`),
            path: `/${systemId}${
              GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).OVERVIEW
            }`,
            icon: stateFacilityLevel.webSocketError ? (
              <CloudOffIcon />
            ) : (
              <GroundControlSideIcon
                mode={flightStatusMode}
                isLocked={flightDomain.flight_domain_status?.locked}
                isInCoexistence={stateFacilityLevel.facilitySettings?.show_coexistence_map}
                isDroneOnly={
                  flightDomain.flight_domain_status?.allow_normal_flight_in_controlled_zone
                }
              />
            ),
            items: [
              {
                id: 'overview',
                label: 'Overview',
                path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).OVERVIEW,
                icon: <MonitorIcon />,
                visible: true,
                testId: 'c-ground-control-overview',
                end: true,
                variant: flightStatusMode,
              },
              {
                id: 'fleet',
                label: 'Fleet',
                path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).FLEET,
                icon: <DroneIcon color={droneIconColor} />,
                visible: userHasSomePermissions([
                  PERMISSION.GENERATE_QR_CODE,
                  PERMISSION.VIEW_FLEET_INFO,
                ]),
                testId: 'c-ground-control-fleet',
                variant: flightStatusMode,
              },

              {
                id: 'droneZones',
                label: 'Drone zones',
                path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).DRONE_ZONES,
                icon: <MapIcon />,
                visible:
                  userHasPermission(PERMISSION.DRONE_ZONES_MANAGEMENT) &&
                  stateFacilityLevel.facilitySettings?.show_coexistence_map,
                testId: 'c-ground-control-drone-zones',
                variant: flightStatusMode,
              },
              {
                id: 'noFlyZones',
                label: 'No-fly zones',
                path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).NO_FLY_ZONES,
                icon: <NoFlyZoneIcon />,
                visible:
                  userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) &&
                  !stateFacilityLevel.facilitySettings?.show_coexistence_map,
                testId: 'c-ground-control-no-fly-zones',
                variant: flightStatusMode,
              },
              {
                id: 'flightDomainSettings',
                label: 'Schedule',
                path: GROUND_CONTROL_PAGES_URLS(flightDomain.flight_domain_id).SCHEDULE,
                icon: <AccessTimeIcon />,
                visible: true,
                testId: 'c-ground-control-building-settings',
                variant: flightStatusMode,
              },
            ],
          };
        }),
    [
      expandedItems,
      flightDomains,
      stateFacilityLevel.facilitySettings?.show_coexistence_map,
      stateFacilityLevel.webSocketError,
      stateGroundControl.isExecutingEmergencyLanding,
      stateGroundControl.isExecutingReturnHome,
      systemId,
    ],
  );

  const handleAccordions = (key: string) => {
    let list = expandedItems || [];
    if (expandedItems?.includes(key)) {
      list = expandedItems.filter((acc) => acc !== key);
      dispatchFacilityMenu({ type: FacilityMenuActionNames.SET_EXPANDED_ITEMS, payload: list });
    } else {
      list = [...list, key];
      dispatchFacilityMenu({ type: FacilityMenuActionNames.SET_EXPANDED_ITEMS, payload: list });
    }
  };

  const linksList: MenuSection[] = [
    {
      id: 'delivery',
      label: 'Delivery',
      visible:
        stateFacilityLevel.facilitySettings?.enable_csfa_task_request_result &&
        stateFacilityLevel.facilitySettings?.show_ground_control_app &&
        userHasPermission(PERMISSION.DELIVERY_MANAGEMENT) &&
        deliveryFlightDomainsMenuItems.some((link) => link.visible),
      items: deliveryFlightDomainsMenuItems,
    },
    {
      id: 'inventory',
      label: 'Inventory',
      visible: isFacilityDataAvailable && inventoryLinks.some((link) => link.visible),
      items: inventoryLinks,
    },
    {
      id: 'groundControl',
      label: 'Ground control',
      visible: Boolean(
        stateFacilityLevel.flightDomains.length &&
          userHasSomePermissions(safetyPermissions) &&
          stateFacilityLevel.facilitySettings?.show_ground_control_app &&
          flightDomainsMenuItems.some((link) => link.visible),
      ),
      items: flightDomainsMenuItems,
    },
    {
      id: 'admin',
      label: 'Admin',
      visible:
        userHasSomePermissions([PERMISSION.FACILITY_MANAGEMENT, PERMISSION.USER_MANAGEMENT]) &&
        adminLinks.some((link) => link.visible),
      items: adminLinks,
    },
  ];

  /**
   * This function will be fired on mount and it covers case
   * with para-shooting on the dashboard and handling of active
   * accordion items based on flightDomainId from URL param
   */
  const onMountSetSelectedMenuItems = () => {
    if (location.pathname.includes('/delivery')) {
      const flightDomainId = location.pathname.split('/')[3];
      const list = [...expandedItems, 'delivery', `delivery_flight_domain_${flightDomainId}`];
      !!dispatchFacilityMenu &&
        dispatchFacilityMenu({
          type: FacilityMenuActionNames.SET_EXPANDED_ITEMS,
          payload: list,
        });
    }

    if (location.pathname.includes('/ground-control/')) {
      const flightDomainId = location.pathname.split('/')[3];
      const list = [...expandedItems, `flight_domain_${flightDomainId}`];
      !!dispatchFacilityMenu &&
        dispatchFacilityMenu({
          type: FacilityMenuActionNames.SET_EXPANDED_ITEMS,
          payload: list ?? [],
        });
    }
  };

  useComponentDidMount(() => {
    onMountSetSelectedMenuItems();
  });

  if (
    location.pathname.includes('not-found') ||
    location.pathname.includes(INVENTORY_PAGES_URLS.SET_MFA_PREFERENCE) ||
    location.pathname.includes(WARNING_PAGES_URLS.NOT_FOUND)
  ) {
    return <> </>;
  }

  return (
    <Drawer anchor="left" variant="permanent" open={open}>
      {Boolean(multipleFacilitiesAccess) && (
        <DrawerHeader enableOffset={isScrollBeyondThreshold ?? false}>
          <ListItemButton
            data-testid="c-select-facility-modal-button"
            sx={{
              height: '60px',
            }}
            disabled={!multipleFacilitiesAccess}
            onClick={() => {
              dispatchClientModals({ type: ClientModalsActionTypes.SELECT_FACILITY_MODAL });
            }}
          >
            <ListItemIcon sx={{ minWidth: 30 }}>
              <LocationOnIcon />
            </ListItemIcon>

            <ListItemText
              primary={
                <Typography
                  sx={{
                    color: theme.palette.grey[600],
                    fontWeight: 500,
                    visibility: open ? 'visible' : 'hidden',
                    textTransform: 'uppercase',
                    textOverflow: 'ellipsis',
                    width: '190px',
                  }}
                  variant="subtitle1"
                  noWrap
                  component="div"
                >
                  {facilityData?.name || `System ID: ${systemId}`}
                </Typography>
              }
            />

            <Typography
              sx={{
                color: theme.palette.grey[500],
                fontWeight: 500,
                visibility: open ? 'visible' : 'hidden',
                '&:hover': {
                  textDecoration: 'underline',
                },
              }}
              variant="caption"
              component="p"
            >
              Change
            </Typography>
          </ListItemButton>
        </DrawerHeader>
      )}

      <DrawerList
        enableOffset={isScrollBeyondThreshold ?? false}
        hasMultipleFacilities={multipleFacilitiesAccess}
      >
        {linksList
          .filter((l) => l.visible)
          .map((link, index) => {
            const isSectionOpen = expandedItems?.includes(link.id);
            const lastItem = index === linksList.length - 1;
            return (
              <div key={link.id}>
                <FacilityMenuItem
                  id={link.id}
                  systemId={systemId}
                  opened={isSectionOpen}
                  label={link.label}
                  handleAccordions={(accordionId: string) => {
                    handleAccordions(accordionId);
                  }}
                  items={link.items}
                  divider={!lastItem}
                  isDrawerOpened={open}
                />
              </div>
            );
          })}
      </DrawerList>

      <ToggleWrapper>
        <IconButton
          onClick={() => dispatchFacilityMenu({ type: FacilityMenuActionNames.TOGGLE_MENU_OPENED })}
          sx={{
            borderRadius: 1,
            color: theme.palette.grey[600],
            background: theme.palette.grey[300],
          }}
          aria-label="fingerprint"
        >
          {!open ? <ChevronRightOutlinedIcon /> : <ChevronLeftOutlinedIcon />}
        </IconButton>
      </ToggleWrapper>
    </Drawer>
  );
};
