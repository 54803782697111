import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const locationModalHeaderStyles = makeStyles()((theme: Theme) => ({
  container: { display: 'flex', justifyContent: 'space-between', padding: theme.spacing(2, 7.5) },
  modalTitle: {
    fontWeight: 700,
    marginLeft: '16px',
  },
  diagnosticsBadge: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  canSeeDiagnosticsCursor: {
    cursor: 'pointer',
  },
  canNotSeeDiagnosticsCursor: {
    cursor: 'default',
  },
}));
