import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    containerType: 'inline-size',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    rowGap: theme.spacing(2),
    paddingBlock: theme.spacing(3),
    paddingInline: '40px 64px',

    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: `0px minmax(100px, ${theme.breakpoints.values.xl}px) minmax(0, 1fr)`,
    },
  },
  section: {
    [theme.breakpoints.up('xl')]: {
      gridColumn: '2 / -2',
    },
  },
  sectionFullWidth: {
    gridColumn: '1 / -1',
  },
  sectionHeader: {
    paddingBottom: theme.spacing(2),
  },
}));
