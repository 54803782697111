import { useTheme } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { filterOutNoMatch, matchBarcodes } from 'common/functions/barcodes/matchBarcode.util';
import { getIssueAndBarcodeMatchingLogic } from 'common/functions/issueLogic/issueLogicFunctions';
import { getBarcodeColor } from 'common/functions/barcodes/getBarcodeColor.util';
import { Box } from 'components/common/Box';
import { BarcodeStatusEnum } from '../barcodes/models/BarcodeRow.model';
import { BarcodesCount } from './BarcodesCount';
import { LastWmsUpdate } from './LastWmsUpdate';
import { LastScan } from './LastScan';
import { getIsVerityDataStale } from '../../utils/getIsVerityDataStale';

export const LocationModalSubHeader = ({
  locationData,
  facilitySettings,
  parentPage,
}: {
  locationData: ILocationData;
  facilitySettings: IFacilitySettingsST;
  parentPage: 'WarehouseStatus' | 'Report';
}) => {
  const { rowData } = locationData;
  const theme = useTheme();

  const isVerityDataStale = getIsVerityDataStale(rowData);

  const barcodes = matchBarcodes({
    expected: filterOutNoMatch(locationData.rowData?.contentExpected),
    found: filterOutNoMatch(locationData.rowData?.contentFound),
  });

  const { barcodeMatchLogic, issueLogic } = getIssueAndBarcodeMatchingLogic(
    locationData,
    facilitySettings,
  );
  const missingColor = getBarcodeColor(
    BarcodeStatusEnum.Missing,
    barcodeMatchLogic,
    issueLogic,
    theme,
  );

  const unexpectedColor = getBarcodeColor(
    BarcodeStatusEnum.Unexpected,
    barcodeMatchLogic,
    issueLogic,
    theme,
  );
  const matchColor = getBarcodeColor(BarcodeStatusEnum.Match, barcodeMatchLogic, issueLogic, theme);
  const staleColor = theme.palette.grey[500];

  const colors = {
    missing: isVerityDataStale ? staleColor : missingColor,
    unexpected: isVerityDataStale ? staleColor : unexpectedColor,
    match: isVerityDataStale ? staleColor : matchColor,
  };

  return (
    <Box
      display="flex"
      flexDirection="row"
      padding={theme.spacing(0, 1)}
      textAlign="left"
      gap={7}
      sx={{
        cursor: 'default',
      }}
      alignItems="center"
    >
      <LastWmsUpdate wmsDate={rowData.wmsDate} isLatest={parentPage === 'WarehouseStatus'} />
      <LastScan
        contentFoundDate={rowData.contentFoundDate}
        isLatest={parentPage === 'WarehouseStatus'}
      />
      <BarcodesCount
        wmsState={locationData.wmsState}
        verityState={locationData.verityState}
        barcodes={{
          missing: barcodes.missing.length,
          unexpected: barcodes.unexpected.length,
          match: barcodes.match.length,
        }}
        colors={colors}
      />
    </Box>
  );
};
