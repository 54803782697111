import { makeStyles } from 'tss-react/mui';

export const navigationButtonStyles = makeStyles()((theme) => ({
  navigationButtons: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(2),
  },
  navigationCounter: {
    cursor: 'default',
    minWidth: 50,
  },
}));
