import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { GROUND_CONTROL_STATUS_COLORS } from 'common/colors';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    marginInlineEnd: theme.spacing(1),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '30px',
    height: '30px',
    fontSize: '1.2em',
    padding: '4px',
    borderRadius: '50%',
    transition: 'background-color 200ms, color 200ms',

    svg: {
      display: 'block',
    },
  },
  standard: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  safe: {
    backgroundColor: GROUND_CONTROL_STATUS_COLORS.SAFE_COLOR,
    color: theme.palette.primary.contrastText,
  },
  unsafe: {
    backgroundColor: GROUND_CONTROL_STATUS_COLORS.UNSAFE_COLOR,
    color: theme.palette.primary.contrastText,
  },
  emergency: {
    backgroundColor: GROUND_CONTROL_STATUS_COLORS.EMERGENCY_COLOR,
    color: theme.palette.primary.contrastText,
  },
}));
