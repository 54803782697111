import React from 'react';

import { useClientModalsStore } from '../../store/Modals';
import { ClientModalsActionTypes } from '../../store/Modals/types';

import { ModalConfirm } from './ModalConfirm';
import { SigninModal } from './SigninModal';
import { ChangePasswordModal } from './ChangePassword/ChangePassword';
import { ModalSelectFacility } from './ModalSelectFacility/ModalSelectFacility';

export const ModalsAndPopupsClientLevel = () => {
  const { clientModalsState, dispatchClientModals } = useClientModalsStore();

  const handleConfirm = () => {
    dispatchClientModals({
      type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
    });

    if (clientModalsState?.modalConfirmData?.onConfirm) {
      clientModalsState.modalConfirmData.onConfirm();
    }
  };

  return (
    <>
      {clientModalsState.modalConfirmData && clientModalsState.confirmModalOpened && (
        <ModalConfirm
          handleClose={() =>
            dispatchClientModals({
              type: ClientModalsActionTypes.TOGGLE_CONFIRM_MODAL,
            })
          }
          opened={clientModalsState.confirmModalOpened}
          title={clientModalsState.modalConfirmData?.title}
          message={clientModalsState.modalConfirmData?.message}
          onConfirm={handleConfirm}
        />
      )}

      {clientModalsState.signinModalOpened && (
        <SigninModal opened={clientModalsState.signinModalOpened} />
      )}

      {clientModalsState.changePasswordModalOpened && (
        <ChangePasswordModal
          opened={clientModalsState.changePasswordModalOpened}
          closeModal={() =>
            dispatchClientModals({ type: ClientModalsActionTypes.CHANGE_PASSWORD_MODAL })
          }
        />
      )}

      {clientModalsState.selectFacilityModalOpened && (
        <ModalSelectFacility
          closeModal={() =>
            dispatchClientModals({ type: ClientModalsActionTypes.SELECT_FACILITY_MODAL })
          }
        />
      )}
    </>
  );
};
