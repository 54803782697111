import { useEffect, useRef, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  EMPTY_HISTORY_DATA,
  TransformedHistoryData,
} from '../models/LocationHistoryEventTypes.model';
import { QueryNames } from '../../../../../../ts-types/QueryNames';
import { getLocationHistory } from '../api/getLocationHistory';
import { transformLocationHistory } from '../utils/transformLocationHistory';

export const useLocationHistory = (systemId: string, slotLabel: string) => {
  const [historyData, setHistoryData] = useState<TransformedHistoryData>(EMPTY_HISTORY_DATA);
  const nextTimepointRef = useRef<string | null>(null);

  const {
    isLoading,
    data: newHistoryData = EMPTY_HISTORY_DATA,
    refetch,
  } = useQuery({
    queryKey: [QueryNames.LOCATION_MODAL_GET_LOCATION_HISTORY, slotLabel],
    queryFn: () => getLocationHistory(systemId, slotLabel, nextTimepointRef.current),
    select: ({ data }) => transformLocationHistory(data),
  });

  useEffect(() => {
    if (newHistoryData.items.length > 0) {
      setHistoryData((prevHistoryData) => ({
        items: [...prevHistoryData.items, ...newHistoryData.items],
        next_timepoint: newHistoryData.next_timepoint,
      }));
    }
  }, [newHistoryData]);

  useEffect(() => {
    nextTimepointRef.current = null;
    setHistoryData(EMPTY_HISTORY_DATA);
    refetch();
  }, [refetch, slotLabel]);

  const handleClick = () => {
    nextTimepointRef.current = newHistoryData.next_timepoint;
    refetch();
  };

  return { isLoading, historyData, handleClick };
};
