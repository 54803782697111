export const getFlightStatusMode = ({
  locked,
  isExecutingEmergencyLanding,
  isExecutingReturnHome,
}: {
  locked: boolean;
  isExecutingEmergencyLanding: boolean;
  isExecutingReturnHome: boolean;
}) => {
  if (isExecutingEmergencyLanding) {
    return 'emergency';
  }

  if (isExecutingReturnHome || !locked) {
    return 'unsafe';
  }

  return 'safe';
};
