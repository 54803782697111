import { IIssueST, ILocationDataST } from 'codegen/report';
import { getRowForFullReportTable } from 'common/functions/locationRows/locationRowsFunctions';
import { useRequestController } from 'hooks';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { warehouseStore } from 'store/WarehouseStore';
import { reportStore } from 'udb/inventory/features/reports/reducer/report-store/ReportStore';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { useMutation } from '@tanstack/react-query';
import { MutationNames } from 'ts-types/MutationNames';
import { useSearchParams } from 'react-router-dom';
import { FeedbackReasonType } from '../features/share-feedback/feedbackReason.model';
import { initialLocationData } from '../initialLocationData';
import { sendLocationInfoEmail } from '../features/location-history/api/sendLocationInfoEmail';

export const useLocationModal = (
  systemId: string | null,
  locationsList: ILocationData[],
  parentPage: 'WarehouseStatus' | 'Report',
  reportId: string | null | undefined,
) => {
  const [spinnerLoading, setSpinnerLoading] = useState(false);

  const [searchParams] = useSearchParams();
  const locationFromUrl = searchParams.get('location') ?? '';
  const selectedLocationData: ILocationData = useMemo(
    () =>
      locationsList.find((location) => location.location === locationFromUrl) ??
      initialLocationData,
    [locationsList, locationFromUrl],
  );
  const [currentLocationData, setCurrentLocationData] =
    useState<ILocationData>(selectedLocationData);

  const [currentLocationIndex, setCurrentLocationIndex] = useState(
    locationsList.findIndex((location) => location.selected ?? false),
  );

  useEffect(() => {
    if (currentLocationIndex >= 0) {
      setCurrentLocationData(locationsList[currentLocationIndex]);
    }
  }, [currentLocationIndex, locationsList]);

  useEffect(() => {
    setCurrentLocationData(selectedLocationData);
  }, [selectedLocationData]);

  const { requestController } = useRequestController(`${parentPage} - LocationModal`);

  const updateCurrentLocationAndLocationSet = useCallback(
    (loc: ILocationDataST, issues: IIssueST[]) => {
      const rowDataAux = getRowForFullReportTable(loc.slot_label ?? '', loc, issues);
      const locData = rowDataAux.actions.data;

      setCurrentLocationData(locData as unknown as ILocationData);
    },
    [],
  );

  const refreshLocationData = useCallback(
    (locationLabel: string) => {
      switch (parentPage) {
        case 'WarehouseStatus':
          requestController.doRequest({
            request: warehouseStore.getLocationsData,
            requestParams: [systemId, locationLabel, 1],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.locationsData[locationLabel],
                r.locationsData[locationLabel].issues,
              ),
            messageErrorFallback: 'Location Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        case 'Report':
          requestController.doRequest({
            request: reportStore.getReportData,
            requestParams: [
              systemId,
              reportId,
              locationLabel,
              locationLabel,
              requestController.signal,
            ],
            callbackBeforeSend: () => setSpinnerLoading(true),
            callbackSuccess: (r) =>
              updateCurrentLocationAndLocationSet(
                r.reportData.locations_data[locationLabel],
                r.reportData.issues[locationLabel],
              ),
            messageErrorFallback: 'Report Data could not be fetched.',
            callbackFinally: () => setSpinnerLoading(false),
          });
          break;

        default:
          break;
      }
    },
    [parentPage, reportId, requestController, systemId, updateCurrentLocationAndLocationSet],
  );

  const { mutate: sendLocationInfoMutate, isPending: isSendingLocationInfo } = useMutation({
    mutationKey: [MutationNames.SEND_LOCATION_INFO_EMAIL],
    mutationFn: ({
      feedbackCategories,
      message,
    }: {
      feedbackCategories: FeedbackReasonType[];
      message: string;
    }) => {
      const linkToTheCurrentLocation = window.location.href;

      return sendLocationInfoEmail(
        systemId ?? '',
        currentLocationData.location,
        currentLocationData.slotStatusVersion as number,
        currentLocationData.rowData.wmsSlotStatusVersion ?? 0,
        parentPage,
        reportId ?? '',
        feedbackCategories,
        message,
        linkToTheCurrentLocation,
      );
    },
    onSuccess: () => {
      toast(`Feedback on location ${currentLocationData.location} was submitted to Verity.`, {
        type: 'success',
      });
    },
    onError: (error: Error) => {
      toast(error.message, {
        type: 'error',
      });
    },
  });

  const sendLocationInfo = (feedbackCategories: FeedbackReasonType[], message: string) => {
    sendLocationInfoMutate({
      feedbackCategories,
      message,
    });
  };

  return {
    sendLocationInfo,
    refreshLocationData,
    spinnerLoading: spinnerLoading || isSendingLocationInfo,
    currentLocationData,
    setCurrentLocationIndex,
  };
};
