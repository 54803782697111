/**
 * Report tabs values
 */

export const REPORTS = {
  ONGOING: {
    TABLE_FOR: 'ongoing reports',
    TABLE_TITLE: 'Ongoing',
    TABLE_SUBTITLE: 'The system is still processing or collecting data.',
  },
  FINISHED: {
    TABLE_FOR: 'finished reports',
    TABLE_TITLE: 'Finished',
    TABLE_SUBTITLE: 'The final reports are available.',
  },
  SCHEDULED: {
    TABLE_FOR: 'scheduled reports',
    TABLE_TITLE: 'Scheduled',
    TABLE_SUBTITLE: 'The upcoming schedule for the reports.',
  },
  REVIEW: {
    TABLE_FOR: 'reports to review',
    TABLE_TITLE: 'To review',
    TABLE_SUBTITLE: 'Reports to review.',
  },
  ARCHIVED: {
    TABLE_FOR: 'archived reports',
    TABLE_TITLE: 'Archived',
    TABLE_SUBTITLE: 'Archived reports.',
  },
};
/**
 * Page header subtitle
 */

export const pageHeaderSubtitle = {
  [REPORTS.FINISHED.TABLE_FOR]: 'Showing finished reports.',
  [REPORTS.ONGOING.TABLE_FOR]: 'Showing all ongoing reports',
  [REPORTS.SCHEDULED.TABLE_FOR]: 'Showing all scheduled reports',
  [REPORTS.REVIEW.TABLE_FOR]: 'Showing all reports to review',
  [REPORTS.ARCHIVED.TABLE_FOR]: 'Showing archived reports.',
};
/**
 * Default value for currentTab
 */

export const currentTabDefault = {
  tableFor: '',
  tableTitle: '',
  tableSubtitle: '',
  headCells: [],
  rows: [],
  redirectTo: '',
  toolbarAction: null,
};
