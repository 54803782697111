import some from 'lodash/some';

import { IChangePasswordPostRequestST } from 'codegen/authentication';
import { NavigateFunction } from 'react-router-dom';
import { toast, type ToastContent } from 'react-toastify';
import { CHALLENGE_NAME } from 'common/authChallengeNames';
import { getUserDataFromAccessToken } from './tokenFunctions';

import { authStore } from '../../store/AuthStore';
import { UserLevelAction, UserLevelActionNames } from '../../store/UserLevelStore/userLevelActions';
import { ClientModalsActionTypes } from '../../store/Modals/types';
import { IClientModalsAction } from '../../store/Modals/clientModals/IClientModalsActions';

import { USER_GROUPS } from '../userGroups';
import { IRequestController } from '../../hooks';
import { AUTH_PAGES_URLS } from '../pages';

export const checkIfUserHasRolesDefined = (accessToken?: string) => {
  const { userGroups } = getUserDataFromAccessToken(accessToken);

  return some(userGroups, Boolean);
};

/**
 * Sign in function.
 *
 * Note: The network request does not get cancelled on unmounting, but the callbacks won't be executed.
 * @param param0 ISignIn parameters
 */
export const signIn = ({
  username,
  password,
  setSpinner,
  dispatchUserLevelStore,
  navigate,
  requestController,
}: {
  username: string;
  password: string;
  setSpinner: React.Dispatch<React.SetStateAction<number>>;
  dispatchUserLevelStore: (action: UserLevelAction) => void;
  navigate: NavigateFunction;
  requestController: IRequestController;
}) => {
  const sanitizedUsername = username.replace(/\s+/g, '');

  requestController.doRequest({
    request: authStore.signIn,
    requestParams: [sanitizedUsername, password],
    callbackBeforeSend: () => setSpinner((repliesPending: number) => repliesPending + 1),
    hideNotifications: true,
    callbackSuccess: (r: {
      username: string;
      authData: { challenge_name?: CHALLENGE_NAME; access_token?: string };
      redirectRoute: string;
      isNewUser: boolean;
      isMfaAuth: boolean;
    }) => {
      const { access_token: userAccessToken } = r.authData;

      const userHasRolesDefined = checkIfUserHasRolesDefined(userAccessToken);

      // Do not allow redirection for the user without user roles defined,
      // (except in the case of a new user) because all the routes depend
      // on user having at least one role
      if (userHasRolesDefined || r.isNewUser || r.isMfaAuth) {
        dispatchUserLevelStore({ type: UserLevelActionNames.AUTH_PROCESS, payload: true });
        dispatchUserLevelStore({
          type: UserLevelActionNames.AUTH_DETAILS,
          payload: {
            username,
            challenge_name: r.authData.challenge_name,
          },
        });
        navigate(r.redirectRoute, { state: r.authData });
      } else {
        toast("User doesn't have a role defined", { type: 'error' });
        navigate(AUTH_PAGES_URLS.SIGNOUT);
      }
    },
    callbackError: (e: {
      response: { status: number; data: { message: ToastContent } };
      code: string;
    }) => {
      if (e.response.status === 400 && e.code === 'ERR_BAD_REQUEST') {
        toast('E-mail and/or password are incorrect.', { type: 'error' });
      }
      if (e.response.status === 403) {
        toast(e.response.data.message, { type: 'error' });
      } else {
        toast('Failed to sign in', { type: 'error' });
      }
    },
    callbackFinally: () => setSpinner((repliesPending: number) => repliesPending - 1),
  });
};

export const belongsToGroup = (userGroup: USER_GROUPS) => {
  const accessToken = localStorage.getItem('accessToken');
  const { userGroups } = getUserDataFromAccessToken(accessToken);
  return userGroups[userGroup];
};

export const isVerityUser = () => belongsToGroup(USER_GROUPS.VERITY_USER);

/**
 * Interface for the change password function
 */
export interface IChangePassword {
  /**
   * Change password data
   */
  data: IChangePasswordPostRequestST;
  /**
   * Dispatcher
   */
  dispatchClientModals: (params: IClientModalsAction) => void;
  /**
   * Spinner function
   */
  setSpinner: (isLoading: boolean) => void;
  /**
   * Request Controller
   */
  requestController: IRequestController;
}
/**
 * Change password function
 * @param param0 IChangePassword parameters
 */
export const handleChangePassword = ({
  data,
  requestController,
  setSpinner,
  dispatchClientModals,
}: IChangePassword) => {
  requestController.doRequest({
    request: authStore.changePassword,
    requestParams: [data],
    callbackBeforeSend: () => setSpinner(true),
    messageSuccess: 'Password updated successfully.',
    messageErrorFallback: 'Password could not be updated.',
    callbackSuccess: () =>
      dispatchClientModals({ type: ClientModalsActionTypes.CHANGE_PASSWORD_MODAL }),
    callbackFinally: () => setSpinner(false),
  });
};
