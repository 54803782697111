import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  root: {
    maxWidth: '600px',
    overflowY: 'hidden',
    width: '100%',
    margin: '0 auto',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3),
    height: 'calc(100vh - 64px)',
  },

  title: {
    fontWeight: 'bold',
    color: theme.palette.textSecondary.main,
  },
}));
