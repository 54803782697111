import { useCallback, useState } from 'react';
import isNil from 'lodash/isNil';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Add from '@mui/icons-material/Add';
import { Typography } from '@mui/material';
import { Box } from 'components/common/Box';
import { BaseCard } from 'components/BaseCard/BaseCard';
import { FlightHoursItem } from './features/flightHoursItem/FlightHoursItem';
import { EditFlightHoursItem } from './features/EditFlightHoursItem';
import { RemoveFlightHoursModal } from './features/removeFlightHoursModal/RemoveFlightHoursModal';
import { OperatingHoursEventST, OperatingHoursRange } from './model/operatingHours.model';
import {
  transformOperatingHoursRangeFromDTO,
  transformOperatingHoursRangeToDTO,
} from './model/transformOperatingHours';
import { validateHours } from './utils/validateHours';
import { itemKeyFromOperatingHoursRange } from './utils/itemKeyFromOperatingHoursRange';
import { transformOperatingHoursToString } from './model/transformOperatingHoursToString';

export const DroneFlightHours = (props: {
  schedule: OperatingHoursEventST[];
  setFlightHours: (schedule: OperatingHoursEventST[]) => void;
  isReadOnlyMode?: boolean;
}) => {
  const { schedule, setFlightHours, isReadOnlyMode } = props;

  const [addNewItem, setAddNewItem] = useState(false);
  const [removeIndex, setRemoveIndex] = useState<false | number>(false);
  const [editIndex, setEditIndex] = useState<false | number>(false);

  const flightHours = isNil(schedule) ? [] : schedule.map(transformOperatingHoursRangeFromDTO);

  const addOperatingHours = useCallback(
    (newHours: OperatingHoursRange) => {
      const newHoursST = transformOperatingHoursRangeToDTO(newHours);
      const newSchedule = [newHoursST, ...schedule];
      setFlightHours(newSchedule);
      setAddNewItem(false);
    },
    [schedule, setFlightHours],
  );

  const editOperatingHours = useCallback(
    (index: number, newHours: OperatingHoursRange) => {
      const newHoursST = transformOperatingHoursRangeToDTO(newHours);
      const newSchedule = [...schedule];
      newSchedule[index] = newHoursST;
      setFlightHours(newSchedule);
      setEditIndex(false);
    },
    [schedule, setFlightHours],
  );

  const removeOperatingHours = useCallback(
    (index: number) => {
      const newSchedule = [...schedule].slice(0, index).concat(schedule.slice(index + 1));
      setFlightHours(newSchedule);
      setRemoveIndex(false);
    },
    [schedule, setFlightHours],
  );

  return (
    <>
      <BaseCard
        cardFor="schedule-flight-hours-card"
        data-testid="c-schedule-flight-hours-card"
        title="Drone flight hours"
        subtitle="Weekly schedule of drone flying hours"
      >
        <div
          data-testid="c-flight-hours-card-content"
          style={{
            display: 'grid',
            gap: '8px',
            gridTemplateColumns: 'minmax(0, 1fr)',
            gridAutoRows: 'min-content',
          }}
        >
          {flightHours.length > 0 && (
            <>
              {flightHours.map((flightHour, index) =>
                !isReadOnlyMode && index === editIndex ? (
                  <EditFlightHoursItem
                    key={itemKeyFromOperatingHoursRange(flightHour)}
                    cancel={() => setEditIndex(false)}
                    save={(newHours) => editOperatingHours(index, newHours)}
                    validate={validateHours(schedule)}
                    initialValue={flightHour}
                  />
                ) : (
                  <FlightHoursItem
                    key={itemKeyFromOperatingHoursRange(flightHour)}
                    hours={flightHour}
                    remove={() => setRemoveIndex(index)}
                    edit={() => setEditIndex(index)}
                    isReadOnlyMode={isReadOnlyMode}
                  />
                ),
              )}
            </>
          )}

          {flightHours.length === 0 && !addNewItem && (
            <Box
              width="100%"
              minHeight="120px"
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography variant="subtitle1" color="disabled">
                No Drone Flight Hours data available
              </Typography>
            </Box>
          )}
        </div>

        {addNewItem && (
          <Grid mt={1} item xs={12}>
            <EditFlightHoursItem
              cancel={() => setAddNewItem(false)}
              save={addOperatingHours}
              validate={validateHours(schedule)}
            />
          </Grid>
        )}

        {!isReadOnlyMode && (
          <div style={{ marginTop: '24px' }}>
            <Button
              startIcon={<Add />}
              disabled={addNewItem}
              data-testid="c-add-flight-hours-button"
              variant="outlined"
              color="primary"
              size="medium"
              fullWidth
              onClick={() => setAddNewItem(true)}
            >
              ADD DRONE FLIGHT HOURS
            </Button>
          </div>
        )}
      </BaseCard>
      {removeIndex !== false && (
        <RemoveFlightHoursModal
          remove={() => removeOperatingHours(removeIndex)}
          cancel={() => setRemoveIndex(false)}
          hoursToRemove={transformOperatingHoursToString(flightHours[removeIndex])}
        />
      )}
    </>
  );
};
