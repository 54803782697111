import { Tooltip, Typography } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { isTextAreaOrInputActive } from 'common/functions/domFunctions';
import { ILocationData } from 'store/Modals/facilityModals/IFacilityModalsStore';
import { IconButtonSquare } from '../../square-icon-button/IconButtonSquare';
import { navigationButtonStyles } from './NavigationButton.styles';

type LabeledLocation = Pick<ILocationData, 'location'>;

export const NavigationButtons = ({
  locationsData,
  setCurrentLocationIndex,
}: {
  locationsData: LabeledLocation[];
  setCurrentLocationIndex: Dispatch<SetStateAction<number>>;
}) => {
  const { classes } = navigationButtonStyles();

  const data = Array.isArray(locationsData) ? locationsData : [locationsData];
  const [searchParams, setSearchParams] = useSearchParams();
  const locationFromUrl = searchParams.get('location') ?? '';

  const currentLocationIndex = useMemo(
    () => locationsData.findIndex((location) => location.location === locationFromUrl),
    [locationFromUrl, locationsData],
  );

  const goToPreviousLocation = useCallback(
    (locationsData: LabeledLocation[], currentLocationIndex: number) => {
      // prevent navigation to previous location
      // if user is at first location
      if (currentLocationIndex !== 0) {
        setCurrentLocationIndex(currentLocationIndex - 1);

        searchParams.set('location', locationsData[currentLocationIndex - 1].location);
        setSearchParams(searchParams);
      }
    },
    [searchParams, setCurrentLocationIndex, setSearchParams],
  );

  const goToNextLocation = useCallback(
    (locationsData: LabeledLocation[], currentLocationIndex: number) => {
      if (currentLocationIndex !== locationsData.length - 1) {
        setCurrentLocationIndex(currentLocationIndex + 1);

        searchParams.set('location', locationsData[currentLocationIndex + 1].location);
        setSearchParams(searchParams);
      }
    },
    [searchParams, setCurrentLocationIndex, setSearchParams],
  );

  const navigateToLocation = useCallback(
    (event: KeyboardEvent) => {
      if (isTextAreaOrInputActive()) return;

      const data = Array.isArray(locationsData) ? locationsData : [locationsData];
      if (event.key === 'ArrowLeft') {
        goToPreviousLocation(data, currentLocationIndex);
      } else if (event.key === 'ArrowRight') {
        goToNextLocation(data, currentLocationIndex);
      }
    },
    [currentLocationIndex, locationsData, goToNextLocation, goToPreviousLocation],
  );

  useEffect(() => {
    document.addEventListener('keydown', navigateToLocation);
    return () => {
      document.removeEventListener('keydown', navigateToLocation);
    };
  }, [navigateToLocation]);

  return (
    <Typography color="textSecondary" variant="h6" className={classes.navigationButtons}>
      <IconButtonSquare
        disabled={currentLocationIndex === 0}
        onClick={() => goToPreviousLocation(data, currentLocationIndex)}
        aria-label="previous location"
      >
        <Tooltip title="Previous location">
          <ArrowBackIcon />
        </Tooltip>
      </IconButtonSquare>

      <Typography className={classes.navigationCounter} data-testid="curr-loc">
        {`${currentLocationIndex + 1} of ${data.length}`}
      </Typography>

      <IconButtonSquare
        disabled={currentLocationIndex === data.length - 1}
        onClick={() => goToNextLocation(data, currentLocationIndex)}
        aria-label="next location"
      >
        <Tooltip title="Next location">
          <ArrowForwardIcon />
        </Tooltip>
      </IconButtonSquare>
    </Typography>
  );
};
