import { Box } from '@mui/material';
import { toast } from 'react-toastify';

export const BatteryStateMetric = ({
  state,
  detailedState,
}: {
  state: string;
  detailedState: string;
}) => (
  <Box
    component="div"
    display="flex"
    flexDirection="row"
    onClick={() => {
      navigator.clipboard.writeText(`${state} -> ${detailedState}`);
      toast('Copied to clipboard', { type: 'success' });
    }}
  >
    {state}
    {' -> '}
    {detailedState}
  </Box>
);
