import {
  ILocationDataST,
  IIssueST,
  IReportST,
  IIssue1ST,
  IReportData1ST,
  ILocationDataForReview1ST,
  ILocationData1ST,
} from 'codegen/report';
import { REPORT_STATES } from 'common/reportStates';
import { getReportData } from './getReportData';
import { getIssuesSummary } from '../getIssueSummary';
import { getLocationToReview } from './getLocationToReview';
import { getFullReportAndAmended } from './getFullReportAndAmended';
import { getIssuesPotentialInconclusiveInvalid } from './getIssuesPotentialInconclusiveInvalid';

// ###########################################################################
// GET REPORT - get data to be displayed on single report page
//
// Returns:
//   fullResponse - route raw response
//   pageTitle - title of the page, equal to report spec name
//   pageSubtitle - holds the date this report is scheduled for
//   issues - holds data for Issues tab on single report page
//   amended - holds data for Amended tab on single report page
//   snoozed - holds data for Snoozed tab on single report page
//   potential - holds data for Potential tab on single report page
//   inconclusive - holds data for Inconclusive tab on single report page
//   fullReport - holds data for Full report tab on single report page
//   locationsToReview - holds data for Slots to review tab on single report page
//   issuesSummary - holds data for Issues card on single report page
//   reportNeedsReview - if the report needs to be reviewed by Verity user
// ###########################################################################

export const getReport = (
  systemId: string,
  id: string,
  fullReportSummary: IReportST,
  fromSlot: string,
  untilSlot: string,
  signal: AbortSignal,
) => {
  const reportData: IReportData1ST[] = [];

  let reportDataMixedWithReportSummary: IReportST & {
    locations_data: {
      [key: string]: ILocationData1ST;
    };
    locations: string[];
    locations_data_for_review: { [key: string]: ILocationDataForReview1ST };
    issues: { [key: string]: Array<IIssue1ST> };
  } = {
    ...fullReportSummary,
    locations_data: {},
    locations: [],
    locations_data_for_review: {},
    issues: {},
  };

  // Compile data full report and amended tabs
  return getReportData(systemId, id, fromSlot, untilSlot, signal).then((r) => {
    reportData.push(r.reportData as IReportData1ST);
    // Join all locations
    reportData.forEach((locationSet) => {
      reportDataMixedWithReportSummary = {
        ...reportDataMixedWithReportSummary,
        locations_data: {
          ...reportDataMixedWithReportSummary.locations_data,
          ...locationSet.locations_data,
        },
        locations: [...reportDataMixedWithReportSummary.locations, ...locationSet.locations],
        locations_data_for_review: {
          ...reportDataMixedWithReportSummary.locations_data_for_review,
          ...locationSet.locations_data_for_review,
        },
        issues: {
          ...reportDataMixedWithReportSummary.issues,
          ...locationSet.issues,
        },
      };
    });

    const locationsData: Record<string, ILocationDataST> =
      reportDataMixedWithReportSummary.locations_data;
    const issuesData: Record<string, IIssueST[]> = reportDataMixedWithReportSummary.issues;
    const reportState = reportDataMixedWithReportSummary.state as REPORT_STATES;
    const reportName: string = reportDataMixedWithReportSummary.request.report_name;
    const reportNeedsReview = !!reportDataMixedWithReportSummary.num_locations_to_review;
    let locationsToReviewData: Record<string, ILocationDataForReview1ST> =
      reportDataMixedWithReportSummary.locations_data_for_review || {};

    // The call to the 'approve' route should only consider slots
    // that have a verity status, so we filter those that don't out
    // this is also done in the backend, but this way we have another
    // layer assuring integrity of the data presented to the user
    locationsToReviewData = Object.assign(
      {},
      ...Object.entries(locationsToReviewData)
        // lint rule is disabled because looping through an object is not possible without [k, v]
        // eslint-disable-next-line no-unused-vars
        .filter(([_, v]) => v.verity_status)
        .map(([k, v]) => ({ [k]: v })),
    );

    const { fullReport, amended } = getFullReportAndAmended(locationsData);
    const { issues, snoozed, potential, inconclusive, invalid } =
      getIssuesPotentialInconclusiveInvalid(issuesData);

    const pageTitle = reportDataMixedWithReportSummary.request.report_spec.report_spec_name;
    const pageSubtitle = '';

    const issuesSummary = getIssuesSummary(
      reportDataMixedWithReportSummary.issue_count.totals,
      reportDataMixedWithReportSummary.request.report_spec.report_spec_name,
    );

    const locationsToReview = getLocationToReview(
      locationsToReviewData,
      reportDataMixedWithReportSummary.issues,
    );

    return {
      fullResponse: reportDataMixedWithReportSummary,
      pageTitle,
      pageSubtitle,
      issues,
      amended,
      snoozed,
      potential,
      inconclusive,
      invalid,
      fullReport,
      issuesSummary,
      locationsToReview,
      reportNeedsReview,
      reportState,
      reportName,
    };
  });
};
