import { GridInitialStatePremium } from '@mui/x-data-grid-premium/models/gridStatePremium';

export const initialGridState: GridInitialStatePremium = {
  columns: {
    columnVisibilityModel: {
      slot_label: true,
      'issues.0.type': true,
      'wms_status.state': true,
      'verity_status.state': true,
      wasFoundAt: true,
      shouldBeAt: true,
      'wms_status.customers.0': true,
      'verity_status.collected_at': true,
      'wms_status.changed_at': true,
      'issues.0.state': true,
      location_label: true,
      exclusion_status: false,
      'wms_status.qtys.0': false,
      'issues.0.first_found_on': false,
      'wms_status.article_nos.0': false,
      expected_barcodes: false,
      found_barcodes: false,
      missing_barcodes: false,
      missing_barcodes_rate: false,
      unexpected_barcodes: false,
      unexpected_barcodes_rate: false,
      matching_barcodes: false,
      matching_barcodes_rate: false,
      collection_delay: false,
      content_found_amended: false,
    },
    orderedFields: [
      'slot_label',
      'issues.0.type',
      'wms_status.state',
      'verity_status.state',
      'wasFoundAt',
      'shouldBeAt',
      'wms_status.customers.0',
      'verity_status.collected_at',
      'wms_status.changed_at',
      'issues.0.state',
      'location_label',
      'exclusion_status',
      'wms_status.article_nos.0',
      'content_found_amended',
      'issues.0.first_found_on',
      'wms_status.qtys.0',
      'expected_barcodes',
      'found_barcodes',
      'missing_barcodes',
      'missing_barcodes_rate',
      'unexpected_barcodes',
      'unexpected_barcodes_rate',
      'matching_barcodes',
      'matching_barcodes_rate',
      'collection_delay',
    ],
    dimensions: {
      slot_label: {
        maxWidth: -1,
        minWidth: 100,
        width: 100,
        flex: 1,
      },
    },
  },
  rowGrouping: {
    model: [],
  },
  filter: {
    filterModel: {
      items: [],
    },
  },
};
