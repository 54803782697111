import { useNavigate, useParams } from 'react-router-dom';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';

import { INVENTORY_PAGES_URLS, WARNING_PAGES_URLS } from 'common/pages';
import { Box } from 'components/common/Box';
import { Copyright } from 'components/common/Copyright';
import { isSSOUser } from 'features/permissions/isSSOUser';
import { useQuery } from '@tanstack/react-query';
import { useCallback, useEffect } from 'react';
import { FacilitiesList } from './features/FacilitiesList';
import { useUserLevelStore } from '../../store/UserLevelStore/userLevelStore';
import { getHomeRouteBasedOnPermissions } from './utils/getHomeRouteBasedOnPermissions';
import { useStyles } from './styles/selectFacility.styles';
import { QueryNames } from '../../ts-types/QueryNames';
import { getUserFacilities } from './api/getUserFacilities.api';
import { transformUserFacilitiesFromDTO } from './api/transformUserFacilitiesFromDTO';
import { Spinner } from '../../components/common/Spinner';
import { Facility } from './model/facility.model';

export const SelectFacility = () => {
  const navigate = useNavigate();
  const { classes } = useStyles();
  const { stateUserLevel } = useUserLevelStore();
  const { systemId = '' } = useParams();

  const {
    isLoading,
    isFetched,
    data: facilities,
  } = useQuery({
    queryKey: [QueryNames.FETCH_USER_FACILITIES, systemId],
    queryFn: getUserFacilities,
    select: transformUserFacilitiesFromDTO,
  });
  localStorage.removeItem('systemId');

  const handleSelect = useCallback(
    async (facility: Facility) => {
      if (!(stateUserLevel.isMfaAuth || isSSOUser())) {
        navigate(`/${facility.id}${INVENTORY_PAGES_URLS.SET_MFA_PREFERENCE}`);
        return;
      }
      if (!facilities || facilities.length === 0 || facility.name === undefined) {
        navigate(`/${facility.id}${WARNING_PAGES_URLS.NO_FACILITY}`);
        return;
      }

      navigate(await getHomeRouteBasedOnPermissions(facility.id));
    },
    [facilities, navigate, stateUserLevel.isMfaAuth],
  );

  useEffect(() => {
    if (isFetched && !isLoading && facilities && facilities?.length === 1) {
      handleSelect(facilities[0]);
    }
  }, [facilities, handleSelect, isFetched, isLoading]);

  if (isLoading) return <Spinner />;

  return (
    facilities &&
    facilities.length > 1 && (
      <Box className={classes.wrapper}>
        <Box>
          <Box mb={5} textAlign="center">
            <Typography
              data-testid="c-choose-facility-title"
              className={classes.title}
              variant="h4"
            >
              Choose the facility you want to load
            </Typography>
          </Box>
          <Card className={classes.root} elevation={4}>
            <FacilitiesList facilities={facilities} onSelect={handleSelect} />
          </Card>
        </Box>
        <Box mt={8}>
          <Copyright />
        </Box>
      </Box>
    )
  );
};
