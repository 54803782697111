import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useCallback, useEffect, useState } from 'react';
import { getEstimatedObstacles } from 'shared/map-container/utils/getEstimatedObstacles';
import { getSpaceReservations } from 'shared/map-container/utils/getSpaceReservations';
import { webSocket } from 'shared/web-socket/web-socket';
import { EnhancedTable } from 'components/EnhancedTable';
import { DroneErrorModal } from 'delivery/features/fleet/features/drone-error-modal/DroneErrorModal';
import {
  IGetEstimatedObstaclesResponseST,
  IGetSpaceReservationsResponseST,
} from 'codegen/nav_simulation';
import { GroundControlActionNames } from 'store/GroundControl/groundControlLevelStore.model';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { useRequestController } from '../../../hooks';
import { useComponentDidMount } from '../../../hooks/useComponentDidMount';
import { useGroundControlStore } from '../../../store/GroundControl/groundControlLevelStore';
import { transformToFleetTableRows } from './model/transformToFleetTableRows';
import { useFacilityLevelStore } from '../../../store/FacilityLevelStore/facilityLevelStore';
import { subscribeToEstimatedObstacles } from './web-socket/subscribe-to-estimated-obstacles';
import {
  FleetPageSubscribersOptions,
  fleetPageSubscribers,
} from './web-socket/fleet-page.subscribers';
import { subscribeToSpaceReservations } from './web-socket/subscribe-to-space-reservations';
import { FLEET_COLUMNS } from './defaults/fleetColumns.default';
import { extractVersionNumber } from './utils/extractVersionNumber';
import { getCurrentOCVersion } from './utils/getCurrentOCVersion';
import { getCurrentFlightDomain } from './utils/getCurrentFlightDomain';
import { getDroneSwVersions } from './utils/getDroneSwVersions';
import { IFleetTableRows } from './model/IFleetTableRows';

const WARNING_ALL_TOAST_ID = 'allDronesFromFleetWarning';
const WARNING_SOME_TOAST_ID = 'someDronesFromFleetWarning';

export const Fleet = () => {
  const { systemId = '', flightDomainId = '' } = useParams();

  const {
    stateGroundControl,
    dispatchGroundControlLevel,
    asyncGetFleetOverview,
    asyncGetFleetVersion,
  } = useGroundControlStore();

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { requestController } = useRequestController('Delivery - Fleet');

  const [ocVersion, setOcVersion] = useState('');

  const [estimatedObstacles, setEstimatedObstacles] = useState<
    IGetEstimatedObstaclesResponseST['estimated_obstacles']
  >({});
  const [spaceReservations, setSpaceReservations] = useState<
    IGetSpaceReservationsResponseST['space_reservations']
  >({});
  const [selectedRow, setSelectedRow] = useState<IFleetTableRows>();

  const socket = webSocket();

  const [rows, setRows] = useState(
    transformToFleetTableRows(
      stateGroundControl.fleetOverview.fleet_status,
      stateGroundControl.fleetVersions.drone_versions,
      ocVersion,
    ),
  );

  useEffect(() => {
    setRows(
      transformToFleetTableRows(
        stateGroundControl.fleetOverview.fleet_status,
        stateGroundControl.fleetVersions.drone_versions,
        ocVersion,
      ),
    );
  }, [
    stateGroundControl.fleetOverview.fleet_status,
    stateGroundControl.fleetVersions.drone_versions,
    ocVersion,
  ]);

  const verifyFirmwareMatchesFdVersion = useCallback(
    async (flight_domain_name: string, droneSwVersions: string[]) => {
      const ocVersion = await getCurrentOCVersion(requestController, systemId, flight_domain_name);
      setOcVersion(ocVersion);

      if (
        droneSwVersions.some(
          (droneVersion) => extractVersionNumber(droneVersion) !== extractVersionNumber(ocVersion),
        )
      ) {
        toast(
          `Some drones do not have the SW Version of the Operations Coordinator of "${flight_domain_name}" (${
            extractVersionNumber(ocVersion) || ocVersion
          })`,
          {
            type: 'warning',
            toastId: WARNING_SOME_TOAST_ID,
          },
        );
      }
    },
    [requestController, systemId],
  );

  /**
   * Verify whether the whole fleet displayed in the page has the same FW version
   */
  const verifyFirmwareHomogeneous = useCallback(() => {
    const droneSwVersions = getDroneSwVersions(stateGroundControl.fleetVersions.drone_versions);

    if (droneSwVersions.some((droneVersion, index, arr) => droneVersion !== arr[0])) {
      toast('Not all the drones have the same SW Version.', {
        type: 'warning',
        toastId: WARNING_ALL_TOAST_ID,
      });
    }
  }, [stateGroundControl.fleetVersions.drone_versions]);

  const loadFleet = useCallback(async () => {
    const flightDomainData = getCurrentFlightDomain(
      stateFacilityLevel.flightDomains,
      flightDomainId,
    );

    dispatchGroundControlLevel({
      type: GroundControlActionNames.SET_FLIGHT_DOMAIN,
      payload: flightDomainData,
    });

    await asyncGetFleetOverview(requestController, flightDomainId);

    const fleetVersions = await asyncGetFleetVersion(requestController, flightDomainId);

    await getEstimatedObstacles({
      systemId,
      flightDomainId,
      requestController,
      onSuccess: (r) => setEstimatedObstacles(r.estimated_obstacles),
    });

    await getSpaceReservations({
      systemId,
      flightDomainId,
      requestController,
      onSuccess: (r) => setSpaceReservations(r.space_reservations),
    });

    await verifyFirmwareMatchesFdVersion(
      flightDomainData.flight_domain_name,
      getDroneSwVersions(fleetVersions.drone_versions),
    );
    verifyFirmwareHomogeneous();
  }, [
    stateFacilityLevel.flightDomains,
    flightDomainId,
    requestController,
    dispatchGroundControlLevel,
    systemId,
    asyncGetFleetOverview,
    asyncGetFleetVersion,
    verifyFirmwareHomogeneous,
    verifyFirmwareMatchesFdVersion,
  ]);

  useComponentDidMount(() => {
    loadFleet();
  });

  useEffect(() => {
    subscribeToEstimatedObstacles({ socket, flightDomainId, onSubscribe: setEstimatedObstacles });
    subscribeToSpaceReservations({ socket, flightDomainId, onSubscribe: setSpaceReservations });

    return () => socket.unsubscribeAll<FleetPageSubscribersOptions>(fleetPageSubscribers);
  }, [socket, flightDomainId]);

  const onRowClick = (e: KeyboardEvent, row: IFleetTableRows) => setSelectedRow(row);

  return (
    <>
      <PageLayout>
        <PageLayout.Header>
          <PageHeader
            systemId={systemId}
            title="Delivery - Fleet"
            subtitle="Fleet overview for delivery"
          />
        </PageLayout.Header>

        <PageLayout.Section>
          <EnhancedTable
            tableTitle="Fleet"
            tableSubtitle="Fleet overview for drone errors"
            tableFor="Fleet Overview"
            headCells={FLEET_COLUMNS}
            rows={rows}
            showHeader={true}
            currentActiveSpinner={{
              'Fleet Overview': stateGroundControl.flightDomain.isLoadingFleetData,
            }}
            isLoading={stateGroundControl.flightDomain.isLoadingFleetData}
            enableHoverOnRows={true}
            onRowClick={onRowClick}
          />
        </PageLayout.Section>
      </PageLayout>

      {!!selectedRow && (
        <DroneErrorModal
          systemId={systemId}
          flightDomainId={flightDomainId}
          droneErrorPayload={selectedRow}
          estimatedObstacles={estimatedObstacles}
          spaceReservations={spaceReservations}
          closeModal={() => setSelectedRow(undefined)}
        />
      )}
    </>
  );
};
