/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useRef, useState } from 'react';
import { Vector3 } from 'three';
import { ControlledZone, ControlledZones } from 'shared/map/model/controlled-zone.model';
import { OpenFlyZones } from 'shared/map/model/open-fly-zone.model';
import { DroneZoneGeometry, DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { useMapStore } from 'shared/map-container/reducer/3DmapStore';
import {
  CameraState,
  initialCameraState,
  MutableState,
} from 'shared/map-container/reducer/MutableState';
import { OrthoCamera } from 'shared/map-container/features/map-orthographic-camera/OrthoCamera';
import { isCursorInFacility } from 'shared/map-container/utils/3DmapFunctions';
import { Floor } from 'shared/map-container/features/mesh-floor/MeshFloor';
import { MapControls } from 'shared/map-container/features/map-controls/MapControls';
import { DroneZoneDrawer } from 'shared/map/features/drone-zone-drawer';
import { Zoomer } from 'shared/map-container/utils/Zoomer';
import { MapCanvas } from 'shared/map-container/features/map-canvas/MapCanvas';
import { ORTHO_CAMERA_Z } from 'shared/map-container/defaults/orthographicCameraZ.default';
import { Vec6 } from 'shared/map/model/geometry.model';
import { mapFacilityVectors } from 'shared/map-container/utils/mapFacilityVectors.util';
import { DeliveryDroneZoneType } from '../../models/deliveryDroneZones.model';
import { buildDroneZone } from './buildDroneZone';
import { buildDroneZonesMeshes } from '../meshes-from-zones/buildDroneZonesMeshes';

export const wrapperId = 'DroneZonesMapWrapper';

export const DroneZonesMap = ({
  controlledZones,
  openFlyZones,
  isDrawingNewDroneZone,
  visibleZones,
  onEndDroneZoneDraw,
  onDroneZoneClick,
  onDroneZoneGroupClick,
  onDroneZoneDragEnd,
}: {
  controlledZones: ControlledZones;
  openFlyZones: OpenFlyZones;
  visibleZones: Record<DeliveryDroneZoneType, boolean>;
  isDrawingNewDroneZone: boolean;
  onEndDroneZoneDraw: (zone: ControlledZone) => void;
  onDroneZoneClick: (zone: ControlledZone | null) => void;
  onDroneZoneGroupClick: (zones: ControlledZone[]) => void;
  onDroneZoneDragEnd: (zone: ControlledZone) => void;
}) => {
  const { mapState, dispatchMapStore: mapDispatch } = useMapStore();
  const { options } = mapState;
  const worldBox: Vec6 = (mapState?.map?.box as Vec6) || [0, 0, 0, 0, 0, 0];
  const { minX, minY, minZ, maxX, maxY, maxZ } = mapFacilityVectors(worldBox);
  const facilityHeight = maxZ - minZ;

  const mapPositionRef = useRef<CameraState['position']>(new Vector3(0, 0, 0));

  const [droneZoneDrawPositionStart, setDroneZoneDrawPositionStart] = useState<Vector3 | undefined>(
    undefined,
  );

  const mutableState = useMemo(() => {
    MutableState.destroyInstance();

    return MutableState.constructState(
      'DroneZonesMap',
      {
        ...initialCameraState,
        position: new Vector3((maxX + minX) / 2, (maxY + minY) / 2, ORTHO_CAMERA_Z),
        orthographicFOV: options.camera.orthographic.maxFOV,
        currentOrthographicFOV: options.camera.orthographic.maxFOV,
      },
      {
        worldBox,
        guidePosition: new Vector3(0, 0, 0),
        isFloorHeld: false,
      },
      {},
    );
  }, [mapState.map]);

  const zoomer = useMemo(
    () => new Zoomer(options.camera, mapState.canvasSize),
    [options.camera, mapState.map, mapState.canvasSize],
  );

  const camera = useMemo(
    () => (
      <OrthoCamera
        worldBounds={[minX, maxX, minY, maxY]}
        options={options.camera}
        fovAxisRatio={mapState.canvasProportion}
        zoomer={zoomer}
      />
    ),
    [options.camera, mapState.canvasProportion, mapState.map],
  );

  const hasCameraPositionChanged = () => {
    const { x: prevX, y: prevY } = mapPositionRef.current;
    const { x: currX, y: currY } = new Vector3().copy(mutableState.interaction.guidePosition);

    const camerFOV = mutableState.cameraState.currentOrthographicFOV;
    const movementThreshold = camerFOV * 0.0008;
    const hasXMove = Math.abs(currX - prevX) > movementThreshold;
    const hasYMove = Math.abs(currY - prevY) > movementThreshold;

    return hasXMove || hasYMove;
  };

  const handleDroneZoneDragStart = () => {
    const mutState = MutableState.getState();

    mutState.interaction.isFloorHeld = false;
  };

  const handleFloorDown = () => {
    const mutState = MutableState.getState();

    mapPositionRef.current = new Vector3().copy(mutableState.interaction.guidePosition);

    if (isDrawingNewDroneZone) {
      const isInsideFacility = isCursorInFacility(worldBox, mutState.interaction.guidePosition);
      const canStartDrawing = !droneZoneDrawPositionStart && isInsideFacility;

      if (canStartDrawing) {
        setDroneZoneDrawPositionStart(new Vector3().copy(mutableState.interaction.guidePosition));
      }

      return;
    }

    mutState.interaction.isFloorHeld = true;
  };

  const handleFloorUp = () => {
    const mutState = MutableState.getState();
    const isInsideFacility = isCursorInFacility(worldBox, mutState.interaction.guidePosition);
    const canCreateDroneZone = droneZoneDrawPositionStart && isInsideFacility;

    if (canCreateDroneZone) {
      const geometry: DroneZoneGeometry = {
        start: droneZoneDrawPositionStart,
        end: new Vector3(
          mutState.interaction.guidePosition.x,
          mutState.interaction.guidePosition.y,
          maxZ,
        ),
        height: facilityHeight,
      };

      const droneZone = buildDroneZone(
        'New controlled zone',
        geometry,
        DroneZoneTypes.controlledZone,
      );

      setDroneZoneDrawPositionStart(undefined);
      onEndDroneZoneDraw(droneZone);
    }

    mutState.interaction.isFloorHeld = false;
  };

  const handleDroneZoneClick = (zone: ControlledZone | null) => {
    const cameraIsStatic = !hasCameraPositionChanged();

    if (cameraIsStatic) {
      onDroneZoneClick(zone);
    }
  };

  const handleDroneZoneGroupClick = (zones: ControlledZone[]) => {
    const cameraIsStatic = !hasCameraPositionChanged();

    if (cameraIsStatic) {
      onDroneZoneGroupClick(zones);
    }
  };

  const handleDroneZoneDeselect = () => {
    handleDroneZoneClick(null);
  };

  const droneZones: JSX.Element[] = useMemo(
    () =>
      buildDroneZonesMeshes({
        visibleZones,
        controlledZones,
        openFlyZones,
        mapOptions: options,
        facilityHeight,
        onDroneZoneClick: handleDroneZoneClick,
        onDroneZoneGroupClick: handleDroneZoneGroupClick,
        onDroneZoneDragStart: handleDroneZoneDragStart,
        onDroneZoneDragEnd,
      }),
    [visibleZones, controlledZones, openFlyZones],
  );

  const droneZonesAndDrawer: JSX.Element[] = useMemo(
    () =>
      droneZoneDrawPositionStart
        ? droneZones.concat([
            <DroneZoneDrawer
              key="drone-zone-creator"
              drawPositionStart={droneZoneDrawPositionStart}
              worldBox={worldBox}
              facilityHeight={maxZ}
            />,
          ])
        : droneZones,
    [droneZoneDrawPositionStart, droneZones],
  );

  return (
    <MapCanvas
      mapState={mapState}
      mapDispatch={mapDispatch}
      mutableState={mutableState}
      canvasSize={{ width: 0, height: '100%' }}
      floor={
        <Floor
          mapState={mapState}
          zoomer={zoomer}
          onClick={handleDroneZoneDeselect}
          onFloorDown={handleFloorDown}
          onFloorUp={handleFloorUp}
        />
      }
      zoomControls={<MapControls zoomer={zoomer} zoomSteps={options.camera.numberOfZoomSteps} />}
      mapElements={droneZonesAndDrawer}
      camera={camera}
      wrapperId={wrapperId}
      onPointerMissed={handleDroneZoneDeselect}
    />
  );
};
