import { Link, useParams } from 'react-router-dom';
import { CardGrid } from 'components/common/CardGrid/CardGrid';
import { INVENTORY_PAGES_URLS } from 'common/pages';
import { Button } from '@mui/material';
import { Box } from 'components/common/Box';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { HomeReportsGrid } from './HomeReportsGrid';
import { HomeWarehouseStatGrid } from './HomeWarehouseStatGrid';
import { userHasSomePermissions } from '../../features/permissions/userHasPermission';
import { PERMISSION } from '../../features/permissions/permissions.model';
import warehouseStatusBG from './warehouse-status-bg.webp';
import { useLocationData } from '../../udb/inventory/features/warehouse-status/hooks/useLocationData';

export const Home = () => {
  const facilityName = localStorage.getItem('facilityName');
  const { systemId } = useParams();
  useLocationData(systemId as string);

  const isHomeReportsGridVisible: boolean = userHasSomePermissions([
    PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT,
    PERMISSION.SCHEDULED_REPORTS_MANAGEMENT,
    PERMISSION.VIEW_ONGOING_REPORTS,
    PERMISSION.VIEW_FINISHED_REPORTS,
  ]);

  const isHomeWarehouseStatGridVisible: boolean = userHasSomePermissions([
    PERMISSION.WMS_MANAGEMENT,
    PERMISSION.VIEW_WAREHOUSE_STATUS,
  ]);

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageHeader
          systemId={systemId ?? ''}
          title="Inventory overview"
          subtitle={`Here is your inventory overview for the ${facilityName} facility`}
          actionArea={
            <Box
              sx={{
                paddingTop: 3.5,
                paddingBottom: 3.5,
                paddingLeft: 8,
                paddingRight: 8,
                backgroundImage: `url(${warehouseStatusBG})`,
                backgroundPosition: 'center',
                backgroundSize: 'contain',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Button
                variant="contained"
                component={Link}
                to={`..${INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_MAP}`}
              >
                View map
              </Button>
            </Box>
          }
        />
      </PageLayout.Header>

      <PageLayout.Section>
        <CardGrid columnCount={2} className="c-page-content">
          {isHomeWarehouseStatGridVisible && (
            <HomeWarehouseStatGrid systemId={systemId as string} />
          )}

          {isHomeReportsGridVisible && <HomeReportsGrid />}
        </CardGrid>
      </PageLayout.Section>
    </PageLayout>
  );
};
