import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const pageHeaderStyles = makeStyles()((theme: Theme) => ({
  header: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(3),
    position: 'relative',
    backgroundColor: 'inherit',
    paddingBlock: theme.spacing(2),
  },
  subtitle: {
    lineHeight: 1.4,
  },
  bold: {
    fontWeight: 'bold',
  },
  topHeader: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(4),
  },
  details: {
    flex: '1 1 clamp(500px, 60%, 100%)',
  },
  searchAndMenu: {
    flex: '0 0 auto',
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
  },
  formControl: {
    marginLeft: '0.5rem',
    flexDirection: 'column',
  },
  datepicker: {
    marginLeft: '10px',
    width: '140px',
    '& .MuiInputBase-formControl': {
      borderRadius: '0 !important',
    },
    '& .MuiInputBase-formControl input': {
      padding: '0 0 7px',
    },
    '& .MuiOutlinedInput-root fieldset': {
      border: 'none',
      borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
    },
    '& .MuiOutlinedInput-root > .MuiInputAdornment-root > button': {
      marginTop: '-8px',
    },
  },
  selectInput: {
    marginTop: '0px !important',
  },
  intervalSubmitButton: {
    padding: '2px !important',
    marginLeft: theme.spacing(2),
  },
  breadcrumb: {
    fontSize: '14px',
  },
  breadcrumbLink: {
    textDecoration: 'none',
    color: theme.palette.textSecondary.main,
    transition: theme.transitions.create('color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
    },
  },
  disabledLink: {
    color: theme.palette.disabled,
  },
}));
