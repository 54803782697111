import Breadcrumbs from '@mui/material/Breadcrumbs';
import { Link, useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import {
  INVENTORY_PAGES_URLS,
  CLIENT_PAGES_URLS,
  FACILITY_SETTINGS_PAGES_URLS,
  GROUND_CONTROL_PAGES_URLS,
  DELIVERY_PAGES_URLS,
} from 'common/pages';
import { useGroundControlStore } from '../../store/GroundControl/groundControlLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { pageHeaderStyles } from './styles';

export const PageHeaderBreadcrumbs = ({
  title,
  systemId,
  facilityName,
  multipleFacilitiesAccess,
  multipleFlightDomains,
}: {
  title: string;
  systemId: string;
  facilityName: string;
  multipleFacilitiesAccess: boolean;
  multipleFlightDomains: boolean;
}) => {
  const { cx, classes } = pageHeaderStyles();
  const location = useLocation();

  const { stateGroundControl } = useGroundControlStore();

  const pathnames = location.pathname.split('/').filter((x) => x);
  pathnames.shift();

  const flight_domain_name = useFacilityLevelStore().stateFacilityLevel.flightDomains.find(
    (fd) => fd.flight_domain_id === pathnames[1],
  )?.flight_domain_name;

  const breadcrumbLabels = (flightDomainId: string) => ({
    [DELIVERY_PAGES_URLS(flightDomainId).INDEX]: multipleFacilitiesAccess ? facilityName : 'Home',
    [DELIVERY_PAGES_URLS(flightDomainId).FD]: flight_domain_name || 'Flight Domain',
    [DELIVERY_PAGES_URLS(flightDomainId).COMMISSIONING]: 'Commissioning',
    [DELIVERY_PAGES_URLS(flightDomainId).FLEET]: 'Fleet',
    [DELIVERY_PAGES_URLS(flightDomainId).DRONE_ZONES]: 'Drone zones',

    [INVENTORY_PAGES_URLS.OVERVIEW]: multipleFacilitiesAccess ? facilityName : 'Home',
    [INVENTORY_PAGES_URLS.LOCATIONS_MANAGEMENT]: 'Locations management',
    [INVENTORY_PAGES_URLS.WAREHOUSE_STATUS]: 'Warehouse status',
    [INVENTORY_PAGES_URLS.WAREHOUSE_STATUS_X]: 'Warehouse status',
    [INVENTORY_PAGES_URLS.REPORTS]: 'Reports',
    [INVENTORY_PAGES_URLS.ANALYTICS]: 'Analytics',
    [INVENTORY_PAGES_URLS.SCHEDULING]: 'Scheduling',
    [INVENTORY_PAGES_URLS.WAREHOUSE_DATA_UPLOADS]: 'Warehouse Data Uploads',
    [FACILITY_SETTINGS_PAGES_URLS.ROOT]: 'Admin',
    [FACILITY_SETTINGS_PAGES_URLS.SYSTEM_DETAILS]: 'System Details',
    [FACILITY_SETTINGS_PAGES_URLS.FACILITY_DETAILS]: 'Facility Details',
    [FACILITY_SETTINGS_PAGES_URLS.FACILITY_SETTINGS]: 'Facility Settings',
    [FACILITY_SETTINGS_PAGES_URLS.UPLOADS]: 'Uploads',
    [FACILITY_SETTINGS_PAGES_URLS.USERS]: 'Users',

    [GROUND_CONTROL_PAGES_URLS(flightDomainId).INDEX]: multipleFacilitiesAccess
      ? facilityName
      : 'Home',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).OVERVIEW]: multipleFlightDomains
      ? stateGroundControl.flightDomain.flight_domain_name
      : 'Ground Control',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).FLEET]: 'Fleet',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).NO_FLY_ZONES]: 'No-fly zones',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).DRONE_ZONES]: 'Drone zones',
    [GROUND_CONTROL_PAGES_URLS(flightDomainId).SCHEDULE]: 'Schedule',
  });

  return (
    <Breadcrumbs
      data-testid="c-breadcrumb"
      aria-label="breadcrumb"
      sx={{
        display: !facilityName ? 'none' : 'block',
      }}
    >
      {multipleFacilitiesAccess && (
        <Link
          to={CLIENT_PAGES_URLS.SELECT_FACILITY}
          className={cx(classes.breadcrumb, classes.breadcrumbLink)}
        >
          Home
        </Link>
      )}

      {pathnames.map((_value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;
        let label = breadcrumbLabels(stateGroundControl.flightDomain.flight_domain_id)[to];

        if (pathnames[0] === 'commissioning') {
          label = breadcrumbLabels(pathnames[1])[to];
        }

        const getUrl = () => {
          if (to === '/inventory/reports') {
            if (location.state?.loadSince) {
              return '../';
            }

            return `/${systemId}${INVENTORY_PAGES_URLS.REPORTS}`;
          }

          return `/${systemId}${to}`;
        };

        return last ? (
          <Typography
            className={cx(classes.breadcrumb, classes.disabledLink)}
            color="text.primary"
            key={to}
          >
            {label || title}
          </Typography>
        ) : (
          <Typography
            component={Link}
            className={cx(classes.breadcrumb, classes.breadcrumbLink)}
            to={getUrl()}
            key={to}
          >
            {label}
          </Typography>
        );
      })}
    </Breadcrumbs>
  );
};
