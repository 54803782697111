import { Grid, Box } from '@mui/material';
import { isEmpty } from 'lodash';
import { toast } from 'react-toastify';
import { useState } from 'react';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { loadFileFromDisk } from 'common/functions/fileFunctions';
import { BaseCard, IBaseCardActionButton } from 'components/BaseCard/BaseCard';
import { ExpansibleDragAndDropFile } from 'components/common/ExpansibleDragAndDropFile/ExpansibleDragAndDropFile';

export const LoadSettinsFromFile = ({
  populateUI,
}: {
  populateUI: (settings: IFacilitySettingsST) => void;
}) => {
  const [fileDetails, setFileDetails] = useState<File>({} as File);

  const actionButtons: IBaseCardActionButton[] = [
    {
      variant: 'contained',
      color: 'primary',
      fullWidth: true,
      disabled: isEmpty(fileDetails),
      label: 'Load settings',
      clickHandler: () => {
        loadFileFromDisk(fileDetails, populateUI);
        const msg = `Settings from ${fileDetails.name} are now loaded, don't forget to save them.`;
        toast(msg, { type: 'success' });
        setFileDetails({} as File);
      },
    },
  ];

  const loadSettingsFromFileData = {
    title: 'Load settings from file',
    subtitle: '',
    actionButtons,
  };
  return (
    <Grid md={12} sm={12} xs={12} item>
      <BaseCard
        cardFor="Settings"
        showHeader={true}
        showContent={true}
        showActionButtons={true}
        showHeaderDivider={true}
        title="Load settings from file"
        subtitle=""
        actionButtons={loadSettingsFromFileData.actionButtons}
      >
        <Box component="div" key="1" px={2} display="flex" flexDirection="column">
          <Box component="div" my={2}>
            <ExpansibleDragAndDropFile
              title="Load settings from file"
              testId="c-load-settings-from-file"
              dropzoneText="Drop settings file here..."
              fileDetails={fileDetails}
              isUploadDisabled={false}
              onDrop={(e) => setFileDetails(e[0])}
            />
          </Box>
        </Box>
      </BaseCard>
    </Grid>
  );
};
