import { rolesFromUserGroups } from 'features/permissions/roleFromUserGroups';
import { Role, ROLE } from 'features/permissions/role.model';
import { USER_GROUPS } from './userGroups';
import { TokenManager } from './tokenManager';

interface PendoUserBase {
  id: string;
  is_internal: boolean;
}

type PendoUserRoles = {
  [key in ROLE]: boolean;
};

type PendoUser = PendoUserBase & PendoUserRoles;

interface PendoAccount {
  id: string;
}

interface PendoParentAccount {
  id: string;
}

declare const pendo: {
  initialize: (config: {
    visitor: PendoUser;
    account: PendoAccount;
    parentAccount: PendoParentAccount;
  }) => void;
};

const INTERNAL_ROLES: Role[] = [ROLE.SUPER_ADMIN, ROLE.VERITY_USER];

function setPendoUserAttributes(client?: string, systemId?: number) {
  const pendoAppKey = import.meta.env.VITE_PENDO_API_KEY;
  const tokenManager = TokenManager.getInstance();
  const userId = tokenManager.getUserAttributes()?.sub;
  if (!userId || !pendoAppKey) {
    return;
  }

  const userGroups: USER_GROUPS[] | undefined = tokenManager.userGroupsFromAccessToken();
  const roles: Role[] = rolesFromUserGroups(userGroups ?? []);

  const pendoAccount: PendoAccount = {
    id: systemId?.toString() ?? 'not-set',
  };

  const pendoParentAccount: PendoParentAccount = {
    id: client ?? 'not-set',
  };

  const roleAttributes: PendoUserRoles = Object.values(ROLE).reduce(
    (acc, role) => {
      acc[role] = roles.includes(role);
      return acc;
    },
    {} as Record<ROLE, boolean>, // Define the type of the accumulator
  );

  const pendoUser: PendoUser = {
    id: userId,
    is_internal: roles.some((role) => INTERNAL_ROLES.includes(role)),
    ...roleAttributes,
  };

  console.debug('Setting Pendo Attributes', pendoUser, pendoAccount, pendoParentAccount);

  if (userId) {
    pendo.initialize({
      visitor: pendoUser,
      account: pendoAccount,
      parentAccount: pendoParentAccount,
    });
  }
}

export function setPendoClient(client: string, systemId: number) {
  setPendoUserAttributes(client, systemId);
}
