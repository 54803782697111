/* eslint-disable import/no-unused-modules */
import { IIssueST, ILocationDataST, IVeritySlotStatusST, IWMSSlotStatusST } from 'codegen/report';
import { ItemData } from 'udb/inventory/models/ItemView.model';

export const getLatestIssue = (issues: IIssueST[]) => (issues && issues.length ? issues[0] : null);

export const getItemsFromWmsStatus = (
  wmsStatus: IWMSSlotStatusST,
  issue: IIssueST | null,
  items: {
    [item: string]: ItemData;
  } = {},
) => {
  if (wmsStatus.barcodes && wmsStatus.barcodes.length) {
    wmsStatus.barcodes.forEach((b, index) => {
      const id = `${wmsStatus.slot_label}-${b}`;
      if (b !== null) {
        items[id] = {
          ...items[id],
          id,
          barcode: b,
          expected_at: wmsStatus.slot_label,
          wms_date: new Date(wmsStatus.changed_at),
          query_date: new Date(wmsStatus.query_time),
          customer: wmsStatus.customers[index],
          article_nos: wmsStatus.article_nos[index],
          article_qty: wmsStatus.qtys[index],
          issue_type: issue?.type || null,
          issue_state: issue?.state || null,
        };
      }
    });
  }
  return items;
};

export const getItemsFromVerityStatus = (
  verityStatus: IVeritySlotStatusST,
  items: {
    [item: string]: ItemData;
  } = {},
) => {
  if (verityStatus.barcodes && verityStatus.barcodes.length) {
    verityStatus.barcodes.forEach((b) => {
      const id = `${verityStatus.slot_label}-${b}`;
      if (b !== null) {
        items[id] = {
          ...items[id],
          id,
          barcode: b,
          found_at: verityStatus.slot_label,
          collected_on: new Date(verityStatus.collected_at),
        };
      }
    });
  }

  return items;
};

export const getItemsFromLocation = (location: ILocationDataST) => {
  let items: {
    [item: string]: ItemData;
  } = {};

  const issue = getLatestIssue(location.issues);

  if (location.wms_status && typeof location.wms_status !== 'string') {
    const wmsStatus = location.wms_status as IWMSSlotStatusST;
    items = getItemsFromWmsStatus(wmsStatus, issue, items);
  }

  if (location.verity_status && typeof location.verity_status !== 'string') {
    const verityStatus = location.verity_status as IVeritySlotStatusST;
    items = getItemsFromVerityStatus(verityStatus, items);
  }

  return items;
};
