import { Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { RemoteIdentityManager } from './features/remote-identity-manager/RemoteIdentityManager';

export const IdentityManagerShell = () => (
  <ErrorBoundary fallback={<div>Error loading</div>}>
    <Suspense fallback={<div>Loading</div>}>
      <RemoteIdentityManager />
    </Suspense>
  </ErrorBoundary>
);
