import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  listWrapper: {
    minHeight: '220px',
    overflow: 'none',
  },
  title: {
    wordBreak: 'break-all',
  },
  subHeader: {
    fontSize: '1rem',
    wordBreak: 'break-all',
    marginRight: theme.spacing(4),
  },
  listItem: {
    width: '100%',
    height: '7rem',
    padding: theme.spacing(3),
    color: theme.palette.textSecondary.main,
    alignItems: 'center',
  },
}));
