import { makeStyles } from 'tss-react/mui';
import { Theme } from '@mui/material';

export const useStyles = makeStyles()((theme: Theme) => ({
  mapWrapper: {
    height: '100%',
    display: 'grid',
    gridTemplateAreas: `
    'controlBarMap'
    'map'
    'mapSideMenu'
  `,
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2, 3),

    [theme.breakpoints.up('lg')]: {
      gridAutoRows: 'unset',
      gridTemplateRows: 'min-content minmax(0, 1fr)',
      gridTemplateColumns: 'minmax(0, 1fr) 360px',
      gridTemplateAreas: `
      'controlBarMap controlBarSideMenu'
      'map mapSideMenu'
      `,
    },
  },
  controlBarMap: {
    gridArea: 'controlBarMap',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end',
    gap: theme.spacing(2),
  },
  map: {
    gridArea: 'map',
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
    minHeight: '600px',

    [theme.breakpoints.up('lg')]: {
      minHeight: 'auto',
    },
  },
  legend: {
    position: 'absolute',
    insetInlineStart: theme.spacing(1),
    insetBlockStart: theme.spacing(1),
  },
  details: {
    position: 'absolute',
    insetInlineEnd: theme.spacing(1),
    insetBlockStart: theme.spacing(1),
    insetBlockEnd: theme.spacing(1),
    width: 270,
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
  },
  sidemenu: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'minmax(0, 1fr)',
    gridArea: 'mapSideMenu',
    height: '100%',
  },
}));
