import { useState } from 'react';
import tail from 'lodash/tail';
import { GenerateQrCode } from './GenerateQrCode';
import { FollowingInstructions } from './FollowingInstructions';
import { InstructionsSteps } from './InstructionsSteps';
import { connectionInstructions } from './helpers';

const DIRECTION = {
  next: 'next',
  back: 'back',
};

/**
 * Connect drone to wifi card
 * @returns component
 */
export function ConnectDroneToWiFiContainer() {
  const [activeStep, setActiveStep] = useState<number>(0);
  const [isInstructionsStarted, setIsInstructionsStarted] = useState(false);
  const [qrCode, setQrCode] = useState<string>('');

  let steps = [...connectionInstructions];
  steps = tail(connectionInstructions);

  /**
   * Handle direction
   * @param direction direction to go
   * @returns set state of active step
   */
  const handleDirection = (direction: string) => {
    if (activeStep === 0 && direction === DIRECTION.back) {
      setIsInstructionsStarted(false);
      return;
    }

    if (activeStep === 6 && direction === DIRECTION.next) {
      setActiveStep(0);
      setIsInstructionsStarted(false);
    } else {
      setActiveStep((prevState) => (direction === DIRECTION.next ? prevState + 1 : prevState - 1));
    }
  };

  return (
    <>
      {!isInstructionsStarted ? (
        <FollowingInstructions handleStartInstructions={setIsInstructionsStarted} qrCode={qrCode} />
      ) : (
        <InstructionsSteps
          handleDirection={handleDirection}
          isInstructionsStarted={isInstructionsStarted}
          activeStep={steps[activeStep]}
          direction={DIRECTION}
        />
      )}

      <GenerateQrCode qrCode={qrCode} setQrCode={setQrCode} />
    </>
  );
}
