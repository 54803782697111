import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import GetAppIcon from '@mui/icons-material/GetApp';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { useStyles } from './downloadButton.styles';

export const DownloadButton = ({
  'aria-label': ariaLabel,
  tooltip = 'Download',
  isBusy,
  onClick,
}: {
  'aria-label': string;
  tooltip?: string;
  isBusy: boolean;
  onClick: () => void;
}) => {
  const { cx, classes } = useStyles();

  return (
    <TooltipedIcon
      tooltip={tooltip}
      icon={
        <div className={cx(classes.wrapper)}>
          <IconButton disabled={isBusy} onClick={onClick} aria-label={ariaLabel} size="large">
            <div className={cx(classes.iconWrapper, { [classes.loading]: isBusy })}>
              <GetAppIcon color="secondary" />
            </div>

            {isBusy && (
              <div className={cx(classes.loadingGraphic)}>
                <CircularProgress color="secondary" size={25} />
              </div>
            )}
          </IconButton>
        </div>
      }
    />
  );
};
