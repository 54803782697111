import { createContext, useReducer, useContext, useCallback } from 'react';
import { LocalStore } from 'common/functions/storageFunctions';
import { WARNING_PAGES_URLS } from 'common/pages';
import { getLogPrefixForType } from 'common/functions/logFunctions';
import { IFacilityInfoGetResponseST, IFacilityInfoST, IFacilityMapInfoST } from 'codegen/facility';
import { IReportSpecificationST } from 'codegen/report_specification';
import { setSentryClient } from 'common/sentry';
import { IFlightDomainData } from 'store/GroundControl/groundControlLevelStore.model';
import {
  IFacilitySettingsGetResponseSettingsST,
  IFacilitySettingsGetResponseST,
} from 'codegen/facility_settings';
import { setPendoClient } from 'common/pendo';
import { FacilityLevelReducer } from './facilityLevelReducer';
import { FacilityActionNames, FacilityLevelAction } from './facilityLevelActions';

import { warehouseServices } from '../../services/WarehouseServices';
import { facilityServices } from '../../services/FacilityServices';
import { IFacilityLevelState, IFacilityLevelContext } from './facilityLevelStore.model';
import { IRequestController } from '../../hooks';
import { useClientLevelStore } from '../ClientLevelStore/clientLevelStore';
import { initialFacilityLevelState } from './facilityLevelInitialState';
import { userHasSomePermissions } from '../../features/permissions/userHasPermission';
import { safetyPermissions } from '../../features/permissions/permissions.model';

export const FacilityLevelStore = createContext<IFacilityLevelContext | undefined>(undefined);

export const useFacilityLevelStore = () => {
  const facilityLevelContext = useContext(FacilityLevelStore);

  if (facilityLevelContext === undefined) {
    throw new Error('useFacilityLevelStore has to be used within <FacilityLevelStore.Provider>');
  }

  return facilityLevelContext;
};

const logPrefix = getLogPrefixForType('STORE', 'FacilityLevelStoreProvider');

export const FacilityLevelStoreProvider = ({ children }: { children: React.ReactNode }) => {
  const [stateFacilityLevel, dispatchFacilityLevel]: [
    IFacilityLevelState,
    React.Dispatch<FacilityLevelAction>,
  ] = useReducer(FacilityLevelReducer, initialFacilityLevelState);

  const { stateClientLevel } = useClientLevelStore();

  const isDataLoading = useCallback(() => {
    const loading = !stateFacilityLevel.facilityInfoLoaded;
    console.debug(logPrefix, `is data loading: ${loading}`);
    return loading;
  }, [stateFacilityLevel.facilityInfoLoaded]);

  /**
   * Verify whether the currently loaded facility is configured
   */
  const isFacilityConfigured = useCallback(
    (systemId: string) => {
      const isConfigured = stateClientLevel.facilityList.find(
        (facility) => facility.id === systemId,
      )?.isConfigured;
      console.debug(
        logPrefix,
        `is facility with System ID: ${systemId} configured: ${isConfigured}`,
      );
      return !!isConfigured;
    },
    [stateClientLevel.facilityList],
  );

  const isDataReady = useCallback(
    (systemId: string): boolean => {
      const lp = getLogPrefixForType('FUNCTION', 'isDataReady', logPrefix);

      if (!isFacilityConfigured(systemId)) {
        console.debug(lp, 'facility not configured => data is ready');
        return true;
      }

      const ready =
        !!stateFacilityLevel.currentSystemId &&
        stateFacilityLevel.facilityInfoLoaded &&
        stateFacilityLevel.facilityMapInfoLoaded &&
        stateFacilityLevel.facilitySettingsLoaded;

      console.debug(lp, `is data ready: ${ready}`);

      return ready;
    },
    [
      isFacilityConfigured,
      stateFacilityLevel.currentSystemId,
      stateFacilityLevel.facilityInfoLoaded,
      stateFacilityLevel.facilityMapInfoLoaded,
      stateFacilityLevel.facilitySettingsLoaded,
    ],
  );

  /**
   * Retrieve the flight domains for the current facility.
   * @param requestController request controller
   * @param systemId systemId of the facility the FD is in
   * @param force force request to get called
   */
  const asyncGetFlightDomains = useCallback(
    async (requestController: IRequestController, systemId: string, force?: boolean) => {
      console.debug(logPrefix, `asyncGetFlightDomains ${requestController.componentName || ''}`);
      const reservation = requestController.reserveSlotForRequest();

      if (!force && !isFacilityConfigured(systemId)) {
        return null;
      }

      return requestController.doRequest({
        request: warehouseServices.getFlightDomains,
        requestParams: [systemId, reservation.signal],
        messageErrorFallback: 'The flight domains could not be fetched.',
        callbackSuccess: (response: { data: { flight_domains: IFlightDomainData[] } }) => {
          const flightDomains = response.data.flight_domains;

          dispatchFacilityLevel({
            type: FacilityActionNames.SET_FLIGHT_DOMAINS,
            payload: flightDomains,
          });

          dispatchFacilityLevel({
            type: FacilityActionNames.SET_FLIGHT_DOMAINS_LOADED,
            payload: true,
          });

          return flightDomains;
        },
      });
    },
    [isFacilityConfigured],
  );

  /**
   * Get the settings for the currently selected facility.
   * @param requestController request controller
   * @returns an object containing the request ID of the underlying request (e.g. for checking
   * whether the request had been cancelled) and a Promise which will resolve
   * in the request's response, if the operation is successful.
   */
  const asyncGetFacilitySettings = useCallback(
    (requestController: IRequestController, systemId: string) => {
      console.debug(logPrefix, `asyncGetFacilitySettings ${requestController.componentName || ''}`);
      // Note: facility settings are about to be reloaded and, hence, any settings which are currently in the
      // storage is (potentially/most probably) no longer up-to-date => let's invalidate the
      // is loaded flag.
      dispatchFacilityLevel({
        type: FacilityActionNames.SET_FACILITY_SETTINGS_LOADED,
        payload: false,
      });
      const reservation = requestController.reserveSlotForRequest();
      return {
        requestId: reservation.requestId,
        promise: requestController.doRequest({
          request: warehouseServices.getFacilitySettings,
          requestParams: [systemId],
          callbackSuccess: (response: { data: IFacilitySettingsGetResponseST }) => {
            LocalStore.dataToLocalStorage({ settingsData: response.data.settings });
            dispatchFacilityLevel({
              type: FacilityActionNames.SET_FACILITY_SETTINGS,
              payload: response.data.settings as IFacilitySettingsGetResponseSettingsST,
            });
          },
          messageErrorFallback: 'The Facility Settings could not be fetched.',
        }),
      };
    },
    [],
  );

  /**
   * Get the data of the currently selected facility.
   * @param requestController request controller
   * @param systemId ID of the facility the info is fetched for
   * @returns
   */
  const asyncGetFacilityInfo = useCallback(
    (requestController: IRequestController, systemId: string) => {
      console.debug(logPrefix, `asyncGetFacility ${requestController.componentName || ''}`);
      // Note: facility data is about to be reloaded and, hence, any data which is currently in the
      // storage is (potentially/most probably) no longer up-to-date => let's invalidate the
      // is loaded flag.
      dispatchFacilityLevel({ type: FacilityActionNames.SET_FACILITY_INFO_LOADED, payload: false });

      const reservation = requestController.reserveSlotForRequest();
      return requestController.doRequest({
        request: facilityServices.getFacilityInformation,
        requestParams: [systemId, reservation.signal],
        messageErrorFallback: 'The Facility could not be fetched.',
        callbackBeforeSend: () => {
          dispatchFacilityLevel({
            type: FacilityActionNames.SET_IS_FACILITY_INFO_LOADING,
            payload: true,
          });
        },
        callbackSuccess: (response: { data: IFacilityInfoGetResponseST }) => {
          // SS-2021-05-19-TODO::
          // Prevent saving facility data in localStorage we should use only global context store
          // Because we have settings and dataProcessing files that need facility data,
          // from where we can't access global store
          LocalStore.dataToLocalStorage({
            facilityData: response.data as IFacilityInfoST,
          });

          dispatchFacilityLevel({
            type: FacilityActionNames.SET_FACILITY_INFO,
            payload: response.data as IFacilityInfoST,
          });

          dispatchFacilityLevel({
            type: FacilityActionNames.SET_CURRENT_SYSTEM_ID,
            payload: systemId,
          });

          dispatchFacilityLevel({
            type: FacilityActionNames.SET_IS_FACILITY_INFO_LOADING,
            payload: false,
          });
          if ('client' in response.data) {
            setPendoClient(response.data?.client, response.data?.system_id);
            setSentryClient(response.data?.client);
          }
        },
        callbackError: (error: { response: { status: number } }) => {
          // Note: in case the request fails on a Bad Request (400) we do assume this being
          // caused by an invalid ID and, hence we do redirect the user to the "not found" page.
          if (error?.response?.status === 400) {
            window.location.href = WARNING_PAGES_URLS.NOT_FOUND;
          }
        },
      });
    },
    [],
  );

  /**
   * Get the Map info for the given facility
   * @param requestController IRequestController instance
   * @param systemId ID of the facilty
   */
  const asyncGetFacilityMapInfo = useCallback(
    (requestController: IRequestController, systemId: string) => {
      console.debug(logPrefix, `asyncGetFacilityMapInfo ${requestController.componentName || ''}`);
      // Note: facility data is about to be reloaded and, hence, any data which is currently in the
      // storage is (potentially/most probably) no longer up-to-date => let's invalidate the
      // is loaded flag.
      dispatchFacilityLevel({
        type: FacilityActionNames.SET_FACILITY_MAP_INFO_LOADED,
        payload: false,
      });

      const reservation = requestController.reserveSlotForRequest();
      return requestController.doRequest({
        request: facilityServices.getFacilityMapInfo,
        requestParams: [systemId, reservation.signal],
        messageErrorFallback: 'The Facility could not be fetched.',
        callbackBeforeSend: () => {
          dispatchFacilityLevel({
            type: FacilityActionNames.SET_IS_FACILITY_INFO_LOADING,
            payload: true,
          });
        },
        callbackSuccess: (response: { data: IFacilityMapInfoST }) => {
          dispatchFacilityLevel({
            type: FacilityActionNames.SET_FACILITY_MAP_INFO,
            payload: response.data,
          });
          dispatchFacilityLevel({
            type: FacilityActionNames.SET_IS_FACILITY_INFO_LOADING,
            payload: false,
          });
        },
        callbackError: (error: { response: { status: number } }) => {
          // Note: in case the request fails on a Bad Request (400) we do assume this being
          // caused by an invalid ID and, hence we do redirect the user to the "not found" page.
          if (error?.response?.status === 400) {
            window.location.href = WARNING_PAGES_URLS.NOT_FOUND;
          }
        },
      });
    },
    [],
  );

  const awaitGetFacilitySpecificData = useCallback(
    /**
     * Retrieve the data specific to the currently selected facility.
     * @param systemId
     */
    async (requestController: IRequestController, systemId: string) => {
      console.debug(
        logPrefix,
        `awaitGetFacilitySpecificData ${requestController.componentName || ''}`,
      );

      if (systemId) {
        const { requestId, promise } = asyncGetFacilitySettings(requestController, systemId);
        const response = await promise;
        // If the requests get cancelled (e.g. if within an automated test case there is
        // a quick switch from skip MFA to, say. the no facility warning page) then we need
        // a way to assess tha the request got cancelled, and skip the next steps.
        // Hence the pattern here.
        if (requestController.isRequestCancelled(requestId)) {
          console.debug(logPrefix, 'We assume the request has been cancelled');
          return;
        }

        if (
          userHasSomePermissions(safetyPermissions) &&
          response?.data?.settings?.show_ground_control_app
        ) {
          await asyncGetFlightDomains(requestController, systemId, false);
        }

        if (!isFacilityConfigured(systemId)) {
          dispatchFacilityLevel({
            type: FacilityActionNames.SET_CURRENT_SYSTEM_ID,
            payload: systemId,
          });

          return;
        }

        const facilityPromise = asyncGetFacilityInfo(requestController, systemId);
        const mapInfoPromise = asyncGetFacilityMapInfo(requestController, systemId);

        Promise.all([facilityPromise, mapInfoPromise]).then(() => {
          console.debug(logPrefix, 'facility info, slots, and map have been loaded');
        });
      }
    },
    [
      asyncGetFlightDomains,
      asyncGetFacilityInfo,
      asyncGetFacilityMapInfo,
      asyncGetFacilitySettings,
      isFacilityConfigured,
    ],
  );

  /**
   * Delete the facility specific data.
   */
  const deleteFacilitySpecificData = useCallback(async (componentName?: string) => {
    const lp = getLogPrefixForType(
      'FUNCTION',
      `awaitDeleteFacilitySpecificData ${componentName || ''}`,
      logPrefix,
    );
    dispatchFacilityLevel({ type: FacilityActionNames.SET_IS_DATA_READY, payload: false });
    dispatchFacilityLevel({ type: FacilityActionNames.CLEAR_FACILITY_DATA });
    dispatchFacilityLevel({ type: FacilityActionNames.CLEAR_FACILITY_SETTINGS });
    dispatchFacilityLevel({ type: FacilityActionNames.CLEAR_FACILITY_STORE });
    dispatchFacilityLevel({ type: FacilityActionNames.SET_CURRENT_SYSTEM_ID, payload: null });
    console.debug(lp, 'deleted facility data');
  }, []);

  /**
   * Update flight domain list
   * @param data flight domain data to update
   */
  const updateFlightDomainList = (data: IFlightDomainData) => {
    const copyArray = [...stateFacilityLevel.flightDomains];
    const flightDomain = copyArray.findIndex((fd) => fd.flight_domain_id === data.flight_domain_id);
    copyArray[flightDomain] = { ...copyArray[flightDomain], ...data };

    dispatchFacilityLevel({
      type: FacilityActionNames.SET_FLIGHT_DOMAINS,
      payload: copyArray,
    });
  };

  return (
    <FacilityLevelStore.Provider
      value={{
        stateFacilityLevel,
        dispatchFacilityLevel,
        updateFlightDomainList,
        awaitGetFacilitySpecificData,
        deleteFacilitySpecificData,
        isDataReady,
        asyncGetFlightDomains,
        isDataLoading,
        isFacilityConfigured,
        asyncGetFacilityInfo,
        asyncGetFacilityMapInfo,
      }}
    >
      {children}
    </FacilityLevelStore.Provider>
  );
};

export const getReportSpecById = (state: IFacilityLevelState, id: string) =>
  state.inventory.reportSpecifications.find((spec: IReportSpecificationST) => spec.id === id);
