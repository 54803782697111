import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  bannerWrapper: {
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridAutoRows: 'min-content',
    gap: theme.spacing(2),
  },
  sectionColumns: {
    display: 'grid',
    gap: theme.spacing(2),
    gridAutoRows: 'min-content',
    gridTemplateColumns: 'minmax(0px, 1fr)',
    justifyContent: 'start',

    '@container (min-inline-size: 700px)': {
      gridTemplateColumns: 'repeat(auto-fit,  minmax(300px, 1fr))',
    },

    '@container (min-inline-size: 1300px)': {
      gridTemplateColumns: 'repeat(auto-fill, repeat(3, minmax(0, 1fr))',
    },
  },
}));
