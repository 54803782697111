import { Theme } from '@mui/material';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme: Theme) => ({
  wrapper: {
    minHeight: '100vh',
    height: 'auto',
    containerType: 'inline-size',
    display: 'grid',
    gridTemplateColumns: 'minmax(0, 1fr)',
    gridTemplateRows: 'min-content minmax(0, 1fr)',
    rowGap: theme.spacing(2),
    paddingBlock: theme.spacing(3),
    paddingInline: '40px 64px',

    [theme.breakpoints.up('lg')]: {
      minHeight: 'auto',
      height: 'calc(100vh - 96px)', // TODO: Update to inherit based off application height once new containers are implemented. 2025.01.16. GH
      gridTemplateColumns: `0px minmax(100px, ${theme.breakpoints.values.xl}px) minmax(0, 1fr)`,
    },
  },
  section: {
    [theme.breakpoints.up('lg')]: {
      gridColumn: '2 / -2',
    },
  },
  sectionFullWidth: {
    gridColumn: '1 / -1',
  },
  sectionHeader: {
    paddingBottom: theme.spacing(2),
  },
}));
