import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { AlertComponent } from 'components/common/Alert';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { noFlyZoneAlertState } from './NoFlyZoneAlertState';
import { useGroundControlStore } from '../../../../../store/GroundControl/groundControlLevelStore';
import { useStyles } from './noFlyZoneHeader.style';

export const NoFlyZoneHeader = ({
  systemId,
  isCreateEnabled,
  onCreateButtonClicked,
}: {
  systemId: string;
  isCreateEnabled: boolean;
  onCreateButtonClicked: () => void;
}) => {
  const { cx, classes } = useStyles();

  const { stateGroundControl } = useGroundControlStore();

  const isStatusChangeEnabled: boolean =
    userHasPermission(PERMISSION.NO_FLY_ZONE_MANAGEMENT) ||
    !stateGroundControl.flightDomain.flight_domain_status?.locked ||
    (stateGroundControl.flightDomain.fleet_status_summary?.num_drones_flying ?? -1) > 0;

  const isCreateNoFlyZoneEnabled =
    stateGroundControl.flightDomain.flight_domain_status?.locked && isCreateEnabled;

  return (
    <div className={cx(classes.bannerWrapper)}>
      <PageHeader
        systemId={systemId}
        title="No-fly zones"
        subtitle="Create, control and preview no-fly zones"
        actionArea={
          <Button
            variant="contained"
            onClick={onCreateButtonClicked}
            disabled={!isCreateNoFlyZoneEnabled}
            startIcon={<Add />}
            color="primary"
            fullWidth
            size="medium"
          >
            New no-fly zone
          </Button>
        }
      />

      <AlertComponent
        isActive
        {...noFlyZoneAlertState(stateGroundControl, isStatusChangeEnabled).state}
      />
    </div>
  );
};
