import { useParams } from 'react-router-dom';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { IssueSummaryCard } from './issue-summary-card/IssueSummaryCard';
import { ReportSummaryCard } from './report-summary-card/ReportSummaryCard';
import { useReportData } from '../report-table/hooks/useReportData';

export const ReportOverview = () => {
  const { reportId = '', systemId = '' } = useParams();
  const canReviewReport = userHasPermission(PERMISSION.REPORTS_FOR_REVIEW_MANAGEMENT);
  const {
    reportExecutionData,
    reportSummaryData,
    issuesSummary,
    isLoadingSummary,
    reportNeedsReview,
  } = useReportData({
    reportId,
    systemId,
  });
  return (
    <>
      <ReportSummaryCard
        cardFor="summary data"
        isLoading={isLoadingSummary}
        reportSummaryData={reportSummaryData}
      />

      <ReportSummaryCard
        cardFor="execution data"
        isLoading={isLoadingSummary}
        reportSummaryData={reportExecutionData}
      />

      {(!canReviewReport || !reportNeedsReview) && (
        <IssueSummaryCard isLoading={isLoadingSummary} issuesSummary={issuesSummary} />
      )}
    </>
  );
};
