import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useFormik } from 'formik';

import Typography from '@mui/material/Typography';
import Alert from '@mui/material/Alert';
import { makeStyles } from 'tss-react/mui';

import { singleRequestHandler } from 'common/requestHelpers';
import { AUTH_PAGES_URLS } from 'common/pages';
import { TokenManager } from 'common/tokenManager';
import { Box } from 'components/common/Box';
import { Spinner } from 'components/common/Spinner';
import { Theme } from '@mui/material';
import { authStore } from '../../store/AuthStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import { ClientModalsActionTypes } from '../../store/Modals/types';
import { useClientModalsStore } from '../../store/Modals';

import { ModalBase } from './ModalBase';
import { SignInForm } from '../../udb/sign-in/features/SignInForm/SignInForm';
import { MfaSubmitForm } from '../../pages/Auth/MfaSubmitForm';
import { useComponentDidMount } from '../../hooks/useComponentDidMount';

const tokenManager = TokenManager.getInstance();

const useStyles = makeStyles()((theme: Theme) => ({
  modalContent: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  title: {
    fontWeight: 'bold',
  },
  form: {
    width: '85%',
    margin: theme.spacing(3, 0),
  },
  textField: {
    width: '100%',
    marginBottom: theme.spacing(3),
  },
  signoutLinkHolder: {
    textAlign: 'center',
    margin: theme.spacing(2, 0, 1, 0),
  },
  signoutLink: {
    color: theme.palette.textPrimary.main,
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.primary.main,
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  button: {
    margin: theme.spacing(1, 0),
  },
}));

export const SigninModal = (props: { opened: boolean }) => {
  const { opened } = props;

  const session = localStorage.getItem('session');
  const username = localStorage.getItem('username');

  const navigate = useNavigate();
  const location = useLocation();
  const isOnGCPage = location.pathname.includes('ground-control');

  const { stateFacilityLevel } = useFacilityLevelStore();
  const { dispatchClientModals } = useClientModalsStore();

  const [spinner, setSpinner] = useState(false);
  const [mfaEnabled, setMfaEnabled] = useState(false);
  const [challengeName, setChallengeName] = useState('');

  const signin = (username: string, password: string) => {
    const sanitizedUsername = username.replace(/\s+/g, '');

    singleRequestHandler({
      request: authStore.signIn,
      requestParams: [sanitizedUsername, password],
      hideNotifications: true,
      callbackBeforeSend: () => setSpinner(true),
      callbackSuccess: (r) => {
        setSpinner(false);

        if (r.isMfaAuth) {
          setMfaEnabled(true);
          setChallengeName(r.authData.challenge_name);
        } else if (!stateFacilityLevel.appDataLoaded || isOnGCPage) {
          // The APP data is not loaded, reload the page.
          navigate(0);
        } else {
          // close signin modal
          dispatchClientModals({
            type: ClientModalsActionTypes.TOGGLE_SIGNIN_MODAL,
            payload: { open: false },
          });
        }
      },
      callbackError: (e) => {
        if (e.response.status === 400 && e.code === 'ERR_BAD_REQUEST') {
          toast('E-mail and/or password are incorrect.', { type: 'error' });
        } else {
          toast('Failed to sign in', { type: 'error' });
        }
        setSpinner(false);
      },
    });
  };
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      mfaCode: '',
    },
    onSubmit: (values) => signin(values.username, values.password),
  });

  const { classes } = useStyles();

  const { appDataLoaded } = stateFacilityLevel;

  const renderInfoMessage = (appDataLoaded: boolean) => {
    let infoMessage = '';

    if (appDataLoaded) {
      infoMessage =
        'After signing in, the data on your screen might be outdated. Refresh the page to update it.';
    } else {
      infoMessage = 'After signing in, the page will automatically refresh.';
    }

    return infoMessage;
  };

  useComponentDidMount(() => {
    const refreshToken = tokenManager.getRefreshToken();

    if (!refreshToken || refreshToken === '') return;

    singleRequestHandler({
      request: authStore.revokeToken,
      requestParams: [refreshToken],
      hideNotifications: true,
    });

    tokenManager.setAccessToken('');
  });

  const submitMfaCode = (mfaCode: string) => {
    if (mfaCode.length !== 6) {
      return;
    }

    singleRequestHandler({
      request: authStore.authChallenge,
      requestParams: [
        {
          challenge_name: challengeName,
          session,
          username,
          code: mfaCode,
        },
      ],
      callbackBeforeSend: () => setSpinner(true),
      callbackSuccess: () => {
        if (!stateFacilityLevel.appDataLoaded || isOnGCPage) {
          // The APP data is not loaded, reload the page.
          navigate(0);
        } else {
          // The APP data is already loaded, close the modal.
          dispatchClientModals({
            type: ClientModalsActionTypes.TOGGLE_SIGNIN_MODAL,
            payload: { open: false },
          });
        }
      },
      callbackError: () => {
        setSpinner(false);
      },
    });
  };

  return (
    <ModalBase
      testId="c-sign-in-modal"
      opened={opened}
      contentClass={classes.modalContent}
      closeButton={false}
      maxWidth="xs"
      disableEscapeKeyDown
      title={
        <Box>
          <Box textAlign="left" p={2} mb={1}>
            <Typography className={classes.title} color="secondary" variant="h5">
              Sign in
            </Typography>
            <Typography color="secondary" variant="subtitle1">
              Your session expired. Please sign in again.
            </Typography>
          </Box>
        </Box>
      }
    >
      {!mfaEnabled ? (
        <SignInForm
          username={formik.values.username}
          password={formik.values.password}
          handleChange={formik.handleChange}
          handleSubmit={formik.handleSubmit}
          showForgotPasswordLink={false}
          additionalInfo={<Alert severity="info">{renderInfoMessage(appDataLoaded)}</Alert>}
          signInWithDifferentAccount={
            <Box className={classes.signoutLinkHolder}>
              <Link className={classes.signoutLink} to={AUTH_PAGES_URLS.SIGNOUT}>
                Sign in with a different account
              </Link>
            </Box>
          }
        />
      ) : (
        <MfaSubmitForm
          handleSubmit={() => submitMfaCode(formik.values.mfaCode)}
          handleChange={formik.handleChange}
          value={formik.values.mfaCode}
        />
      )}
      {spinner ? <Spinner /> : null}
    </ModalBase>
  );
};
