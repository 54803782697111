import LockIcon from '@mui/icons-material/Lock';
import DirectionsWalkIcon from '@mui/icons-material/DirectionsWalk';
import { TooltipedIcon } from 'components/common/TooltipedIcon';
import { NoAccessIcon } from '../Icons/NoAccessIcon';
import { useStyles } from './groundControlSideIcon.styles';
import { FlightStatusMode } from '../facilityMenu.model';

type GroundControlSideIconProps = {
  mode: FlightStatusMode;
  isLocked?: boolean;
  isInCoexistence?: boolean;
  isDroneOnly?: boolean | null;
};

export const GroundControlSideIcon = ({
  mode,
  isLocked,
  isInCoexistence,
  isDroneOnly,
}: GroundControlSideIconProps) => {
  const { cx, classes } = useStyles();

  if (isLocked) {
    return (
      <div className={cx(classes.wrapper, classes[mode])}>
        <LockIcon sx={{ color: 'inherit', fontSize: 'inherit' }} />
      </div>
    );
  }

  if (!isInCoexistence || isDroneOnly) {
    return (
      <div className={cx(classes.wrapper, classes[mode])}>
        <TooltipedIcon
          data-testid="c-icon-drone-only"
          icon={<NoAccessIcon />}
          tooltip="Do not enter"
        />
      </div>
    );
  }

  return (
    <div className={cx(classes.wrapper, classes[mode])}>
      <TooltipedIcon
        data-testid="c-icon-coexistence"
        icon={<DirectionsWalkIcon sx={{ color: 'inherit', fontSize: 'inherit' }} />}
        tooltip="Drones are mixed with people"
      />
    </div>
  );
};
