import { GridPremiumToolbarButton } from 'common/Tables/toolbar/GridPremiumToolbarButton';
import { onSaveCustomGrid } from 'udb/inventory/features/premium-grid/utils/onSaveCustomGrid.util';
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import { isGridStateSavedInLocalStorage } from 'udb/inventory/features/premium-grid/utils/isGridStateSavedInLocalStorage.utils';
import { onLoadCustomGrid } from 'udb/inventory/features/premium-grid/utils/onLoadCustomGrid.util';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { onRemoveCustomGrid } from 'udb/inventory/features/premium-grid/utils/onRemoveCustomGrid.util';
import BookmarkRemoveIcon from '@mui/icons-material/BookmarkRemove';
import { SearchField } from 'udb/inventory/features/premium-grid/features/search-field/SearchField';
import { GridLogicOperator } from '@mui/x-data-grid-premium';
import { Dispatch, MutableRefObject, SetStateAction, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useFacilityLevelStore } from 'store/FacilityLevelStore/facilityLevelStore';
import { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium';
import { calculateTabCounts } from 'udb/inventory/features/premium-grid/utils/calculateTabCounts';
import { userHasPermission } from 'features/permissions/userHasPermission';
import { PERMISSION } from 'features/permissions/permissions.model';
import { ILocationDataST } from 'codegen/report';
import { TabObject } from 'udb/inventory/features/premium-grid/models/TabObject.model';
import { useReportTableHeaderStyles } from './useReportTableHeader.styles';
import { searchFieldColumns } from './models/searchFieldColumns.model';
import { ReportTabs } from '../report-tabs/ReportTabs';
import { initialGridState } from '../../../../defaults/initialGridState.default';

export const ReportTableHeader = ({
  activeTabIndex,
  setActiveTabIndex,
  gridApiRef,
  reportTabs,
  fullReportMappedToLocationDataWithUpdatedStatus,
}: {
  activeTabIndex: number;
  setActiveTabIndex: Dispatch<SetStateAction<number>>;
  gridApiRef: MutableRefObject<GridApiPremium>;
  reportTabs: TabObject[];
  fullReportMappedToLocationDataWithUpdatedStatus: ILocationDataST[];
}) => {
  const { cx, classes } = useReportTableHeaderStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const { stateFacilityLevel } = useFacilityLevelStore();
  const { facilitySettings } = stateFacilityLevel;

  const [tabCounts, setTabCounts] = useState<{
    [key: string]: number;
  }>({});

  const showAmended =
    userHasPermission(PERMISSION.AMEND_REPORT) && facilitySettings.allow_user_amend;

  const rowCount = gridApiRef.current?.getRowsCount && gridApiRef.current.getRowsCount();

  useEffect(() => {
    setTabCounts(
      calculateTabCounts({
        locationDataList: fullReportMappedToLocationDataWithUpdatedStatus,
        countAmended: showAmended,
        countLabeled: !!facilitySettings.show_location_labels,
      }),
    );
  }, [
    facilitySettings.show_location_labels,
    fullReportMappedToLocationDataWithUpdatedStatus,
    rowCount,
    showAmended,
  ]);

  const handleTabChange = (_event: React.ChangeEvent<HTMLInputElement>, newValue: number) => {
    setActiveTabIndex(newValue);
    const { gridState } = reportTabs[newValue];
    gridApiRef.current.restoreState({ ...gridState });

    searchParams.set('activeTab', newValue.toString());
    setSearchParams(searchParams);
  };

  return (
    <div className={classes.cardHeader}>
      <ReportTabs
        tabs={reportTabs}
        activeTabIndex={activeTabIndex}
        handleTabChange={handleTabChange}
        tabCounts={tabCounts}
      />

      <div className={cx(classes.cardHeaderSection, classes.cardHeaderActions)}>
        <GridPremiumToolbarButton
          name="SAVE VIEW"
          tooltip="Save View"
          onClick={() =>
            onSaveCustomGrid({ gridName: 'report', activeTab: activeTabIndex, gridApiRef })
          }
          icon={<BookmarkAddIcon />}
        />

        <GridPremiumToolbarButton
          name="LOAD VIEW"
          tooltip="Load View"
          disabled={
            !isGridStateSavedInLocalStorage({ gridName: 'report', activeTab: activeTabIndex })
          }
          onClick={() =>
            onLoadCustomGrid({
              gridName: 'report',
              activeTab: activeTabIndex,
              initialGridState,
              gridApiRef,
            })
          }
          icon={<BookmarkIcon />}
        />
        {isGridStateSavedInLocalStorage({
          gridName: 'report',
          activeTab: activeTabIndex,
        }) && (
          <GridPremiumToolbarButton
            name="REMOVE VIEW"
            tooltip="Remove view (revert back to default)"
            onClick={() => onRemoveCustomGrid({ gridName: 'report', activeTab: activeTabIndex })}
            icon={<BookmarkRemoveIcon />}
          />
        )}
      </div>

      <div className={classes.cardHeaderSection}>
        <SearchField
          columns={searchFieldColumns}
          isDisabled={!gridApiRef.current}
          onChange={(items) =>
            gridApiRef.current.setFilterModel({
              items,
              logicOperator: GridLogicOperator.Or,
            })
          }
        />
      </div>
    </div>
  );
};
