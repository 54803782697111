import { Typography, TextField, Grid } from '@mui/material';
import { IWmsSimulatorPostRequestParamsInnerST } from 'codegen/dev_tools';
import { Dispatch, SetStateAction } from 'react';

export const WmsSimulatorBarcodeSelector = ({
  numberOfBarcodes,
  barcodes,
  index,
  wmsData,
  setWmsData,
}: {
  numberOfBarcodes?: number;
  barcodes?: string[];
  index: number;
  wmsData: IWmsSimulatorPostRequestParamsInnerST[];
  setWmsData: Dispatch<SetStateAction<IWmsSimulatorPostRequestParamsInnerST[]>>;
}) => {
  const handleBarcodeChange = (index: number, value: string) => {
    const updatedData = [...wmsData];
    updatedData[index].barcodes = value.split(',');
    setWmsData(updatedData);
  };

  const handleNumberOfBarcodesChange = (index: number, value: string) => {
    const numValue = parseInt(value, 10);
    if (numValue >= 1 && numValue <= 20) {
      const updatedData = [...wmsData];
      updatedData[index].number_of_barcodes = numValue;
      setWmsData(updatedData);
    }
  };

  return (
    <>
      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="subtitle2">Number of Barcodes</Typography>
        <TextField
          type="number"
          variant="outlined"
          size="small"
          value={numberOfBarcodes}
          onChange={(e) => handleNumberOfBarcodesChange(index, e.target.value)}
        />
      </Grid>

      <Grid item xs={12} sm={6} md={3}>
        <Typography variant="subtitle2">Barcodes</Typography>
        <TextField
          fullWidth
          variant="outlined"
          size="small"
          value={barcodes?.join(',')}
          onChange={(e) => handleBarcodeChange(index, e.target.value)}
          placeholder="Enter barcodes, separated by commas"
        />
      </Grid>
    </>
  );
};
