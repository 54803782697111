import { Grid } from '@mui/material';
import { IFacilitySettingsST } from 'codegen/facility_settings';
import { SettingsCard } from '../../SettingsCard';
import { getReportReviewsOptions } from '../../options/getReportReviewsOptions';
import { ErrorsList } from '../../models/ErrorsList.model';

export const ReviewSettings = ({
  isLoading,
  facilitySettings,
  handleValuesChange,
  errors,
}: {
  isLoading: boolean;
  facilitySettings: IFacilitySettingsST;
  handleValuesChange: (prop: string, value: number | boolean | string) => void;
  errors: ErrorsList;
}) => (
  <Grid md={12} sm={12} xs={12} item>
    <SettingsCard
      title="Report reviews"
      isLoading={isLoading}
      testId="c-report-reviews-settings-card"
      items={getReportReviewsOptions(facilitySettings, handleValuesChange, errors)}
    />
  </Grid>
);
