import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useParams } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import { PageLayout } from 'components/common/page-layout/PageLayout';
import { PageHeader } from 'components/common/page-header/PageHeader';
import { AlertComponent } from 'components/common/Alert';
import { BackDropLoader } from 'components/BackDropLoader';
import { useInfoBannersStorage } from 'hooks/useInfoBannersStorage';
import { useRequestController } from '../../hooks';
import { useClientLevelStore } from '../../store/ClientLevelStore/clientLevelStore';
import { useFacilityLevelStore } from '../../store/FacilityLevelStore/facilityLevelStore';
import {
  getSuperSetSettings as getSuperSetSettingsFunc,
  ISupersetSettings,
} from './functions/GetSupersetSettings';
import { fetchSystemSpecificSuperSetGuestToken } from './functions/FetchSystemSpecificSuperSetGuestToken';
import { AnalyticsDashboard } from './features/analytics-dashboard/AnalyticsDashboard';
import { useStyles } from './analytics.styles';

export const Analytics = () => {
  const { classes } = useStyles();
  const { systemId = '' } = useParams();

  const { requestController } = useRequestController('Analytics');
  const { facilityList } = useClientLevelStore().stateClientLevel;
  const { facilitySettings } = useFacilityLevelStore().stateFacilityLevel;

  const [isLoading, setIsLoading] = useState(false);
  const [supersetSettings, setSupersetSettings] = useState<ISupersetSettings | null>(null);

  const isMultipleFacility = facilityList.length > 1;
  const areAnalyticsEnabled = facilitySettings.enable_superset_integration;

  const getSuperSetSettings = useCallback(
    () => getSuperSetSettingsFunc({ setIsLoading, setSupersetSettings, requestController }),
    [requestController],
  );

  const handleFetchSystemSpecificSuperSetGuestToken = useCallback(
    () => fetchSystemSpecificSuperSetGuestToken(systemId),
    [systemId],
  );

  useEffect(() => {
    if (areAnalyticsEnabled) {
      getSuperSetSettings();
    }
  }, [getSuperSetSettings, areAnalyticsEnabled]);

  const { infoBanners, updateCountingInfoBannerStatus } = useInfoBannersStorage();

  return (
    <PageLayout>
      <PageLayout.Header>
        <PageHeader systemId={systemId} title="Analytics" />
      </PageLayout.Header>

      <PageLayout.Section>
        <div className={classes.container}>
          <AlertComponent
            isActive={
              !infoBanners?.countingIntegration.analyticsSeen && !!facilitySettings.enable_counting
            }
            iconMapping={{
              info: <InfoOutlinedIcon fontSize="inherit" />,
            }}
            status="info"
            message={
              <>
                Please note that <strong>counting</strong> report data is not available in the{' '}
                <strong>analytics</strong> view.
              </>
            }
            preventClose={false}
            handleClose={() => updateCountingInfoBannerStatus('analyticsSeen', true)}
          />

          {isLoading && (
            <div className={classes.loader}>
              <BackDropLoader isVisible={isLoading} />
            </div>
          )}

          {!areAnalyticsEnabled && (
            <AlertComponent
              title=""
              message="Analytics are not available for this facility"
              status="info"
              isActive
              preventClose
            />
          )}

          {areAnalyticsEnabled && supersetSettings && (
            <AnalyticsDashboard
              isMultipleFacility={isMultipleFacility}
              supersetSettings={supersetSettings}
              onGuestTokenFetch={handleFetchSystemSpecificSuperSetGuestToken}
            />
          )}
        </div>
      </PageLayout.Section>
    </PageLayout>
  );
};
