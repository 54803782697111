import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { DroneZoneTypes } from 'shared/map/model/drone-zones.model';
import { NEW_DRONE_ZONE_ID } from 'shared/map/defaults/new-drone-zone-id';
import { ControlledZone } from 'shared/map/model/controlled-zone.model';
import { DroneZoneForm } from 'components/DroneZoneForm/DroneZoneForm';
import { FlowState } from '../../reducer/droneZonePage.model';
import { DroneZonesList } from './features/drone-zones-list/droneZonesList';
import { DroneZoneCreator } from './features/drone-zone-creator/droneZoneCreator';
import { createDroneZoneObject } from './features/utils/createDroneZoneObject.util';
import { createControlledZone } from './features/utils/createControlledZone';
import { updateControlledZone } from './features/utils/updateControlledZone';
import { DroneZoneControlsProps } from './droneZoneControls.model';

import { droneZoneCommandFactory } from '../drone-zone-command/droneZoneCommandFactory';

export const DroneZoneControls = ({
  systemId,
  flightDomainId,
  flowState,
  controlledZones,
  currentlyEditingZone,
  worldBox,
  onDroneZoneCommand,
}: DroneZoneControlsProps) => {
  const editedDroneZone =
    currentlyEditingZone ?? createDroneZoneObject({ type: DroneZoneTypes.controlledZone });

  switch (flowState) {
    case FlowState.LOADING:
    case FlowState.LIST:
    case FlowState.DELETE:
      return (
        <DroneZonesList
          controlledZones={controlledZones}
          isLoading={flowState === FlowState.LOADING}
          onDroneZoneCommand={onDroneZoneCommand}
        />
      );
    case FlowState.DRAW:
      return (
        <DroneZoneCreator
          onCancel={() => onDroneZoneCommand(droneZoneCommandFactory.createCancelCreatingCommand())}
        />
      );
    case FlowState.CREATE: {
      const createDroneZone = async (droneZone: ControlledZone) => {
        let response;

        if (droneZone.type === DroneZoneTypes.controlledZone) {
          try {
            response = await createControlledZone(droneZone, systemId, flightDomainId);
          } catch (error) {
            const err = error as AxiosError<Error, Error>;
            const message =
              err?.response?.data?.message ||
              'Something went wrong when creating drone "Controlled zone"';

            toast(message, { type: 'error' });
          }
        }

        if (response) {
          onDroneZoneCommand(droneZoneCommandFactory.createSubmitCommand(droneZone));
        }
      };

      const updateDroneZone = async (droneZone: ControlledZone) => {
        let response;

        if (droneZone.type === DroneZoneTypes.controlledZone) {
          try {
            response = await updateControlledZone(droneZone, systemId, flightDomainId);
          } catch (error) {
            const err = error as AxiosError<Error, Error>;
            const message =
              err?.response?.data?.message ||
              'Something went wrong when updating drone "Controlled zone"';

            toast(message, { type: 'error' });
          }
        }

        if (response) {
          onDroneZoneCommand(droneZoneCommandFactory.createSubmitCommand(droneZone));
        }
      };

      const onSubmit = async (droneZone: ControlledZone) => {
        const isNew = droneZone.id === NEW_DRONE_ZONE_ID;

        if (isNew) {
          await createDroneZone(droneZone);
        } else {
          await updateDroneZone(droneZone);
        }
      };

      return (
        <DroneZoneForm<ControlledZone>
          droneZone={editedDroneZone}
          worldBox={worldBox}
          onUpdate={(droneZone) =>
            onDroneZoneCommand(droneZoneCommandFactory.createUpdateCommand(droneZone))
          }
          onCancel={(droneZone) =>
            onDroneZoneCommand(droneZoneCommandFactory.createCancelEditingCommand(droneZone))
          }
          onSubmit={onSubmit}
        />
      );
    }
    default:
      return null;
  }
};
